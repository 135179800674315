import React, { useCallback, useContext, useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { Grid, RadioGroup, ThemeProvider as MUIThemeProvider, Box } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import OnBoardingPopUp from '../../../pages/OnBorad/OnBoardingPopUp';
import AntTabs from '../../StyledTabsContainer/StyledTabsContainer';
import AntTab from '../../StyledTabs/StyledTabs';
import CancellationPopup from '../CancellationPopup/CancellationPopup';
import useModal from '../../../hooks/useModal';
import baseUrl from '../../../utils/services/config';
import {
  errorChecker,
  getGalleryPopupTabValue,
  getImagesInfoFromData,
  getTagsListDropDown,
} from '../../../utils/helper/helper';
import { GlobalContext } from '../../../Stores/GlobalStateStore';
import TabPanel from '../../TabPanel/TabPanel';
import Loader from '../../Loader/Loader';
import Dropdown from '../../Dropdown/Dropdown';
import NoBorderDropDownTheme from '../../../themes/Dropdown/NoBorderDropDownTheme';
import Uploader from '../../Uploader/Uploader';
import useIconTheme from '../../../hooks/useIconTheme';
import useTextColors from '../../../hooks/useTextColors';
import useTabsTheme from '../../../hooks/useTabsTheme';
import useButtonTheme from '../../../hooks/useButtonTheme';
import PageTitle from '../../PageTitle/PageTitle';
import SMSImageUpload from '../../SMSImageUpload/SMSImageUpload';
import PrimaryButton from '../../Common/PrimaryButton';
import ContainerLoader from '../../Popover/SendInvitePopover/ContainerLoader';

function GalleryPopup(props) {
  const {
    show,
    handleClose,
    requestType,
    handleImagesContainer,
    type,
    allImageSize = false,
    customStyle = {},
  } = props;
  const [tabValue, setTabValue] = useState(getGalleryPopupTabValue(type));
  const [templateType, setTemplateType] = useState(type);
  const [imagesContainer, setImagesContainer] = useState([]);
  const [selectedImagesIndex, setSelectedImagesIndex] = useState(null);
  const [tags, setTags] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedTag, setSelectedTag] = useState('');
  const [uploadedImageInfo, setUploadedImageInfo] = useState({});
  const [deleteId, setDeleteId] = useState(null);
  const cancellationPopup = useModal();
  const { state, dispatch } = useContext(GlobalContext);
  const { selectedBusiness } = state;
  const { register, setValue } = useForm();
  const { iconsTheme } = useIconTheme();
  const { textColors } = useTextColors();
  const { tabsTheme } = useTabsTheme();
  const { buttonTheme } = useButtonTheme();

  const initDeleteProcess = (id) => {
    setDeleteId(id);
    cancellationPopup.handleShowModal();
  };

  useEffect(() => {
    register({ name: templateType });
  }, [register]);

  const fetchGalleryImages = useCallback(async () => {
    if (selectedBusiness) {
      try {
        setLoader(true);
        const url = selectedTag
          ? `/gallery/list?business=${selectedBusiness}&campaignType=${requestType}&templateType=${templateType}&tags=${encodeURIComponent(
              selectedTag,
            )}`
          : `/gallery/list?business=${selectedBusiness}&campaignType=${requestType}&templateType=${templateType}`;
        const result = await baseUrl(url);
        if (result.status === 200) {
          if (!selectedTag.length) {
            setTags(getTagsListDropDown(result.data));
          }
          setImagesContainer(getImagesInfoFromData(result.data));
          setTimeout(() => {
            setLoader(false);
          }, 250);
        }
      } catch (err) {
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        setLoader(false);
      }
    }
  }, [templateType, selectedTag, selectedBusiness]);

  useEffect(() => {
    if (show) {
      fetchGalleryImages();
    }
  }, [show, templateType, selectedTag]);

  const handleChange = (event) => {
    setSelectedImagesIndex(parseInt(event.target.value, 10));
  };

  const handleImageSelection = (e, index) => setSelectedImagesIndex(index);

  const handleImageInsertion = () => {
    const selectedType = type === 'mms' ? 'sms' : type;
    if (imagesContainer[selectedImagesIndex].templateType === selectedType) {
      handleImagesContainer(imagesContainer[selectedImagesIndex]);
      handleClose();
    } else {
      dispatch({
        type: 'SHOW_ERROR',
        payload: `Please select an ${type === 'mms' ? 'sms' : selectedType} image`,
      });
    }
  };

  const handleTabChange = (e, newValue) => {
    let newTemplateType;
    switch (newValue) {
      case 0:
        newTemplateType = 'sms';
        break;
      case 1:
        newTemplateType = 'email';
        break;
      case 2:
        newTemplateType = 'socialimage';
        break;
      default:
        newTemplateType = 'sms'; // Default value if newValue is unexpected
    }
    setTemplateType(newTemplateType);
    setTabValue(newValue);
  };

  const handleSelectTags = (e) => {
    setSelectedTag(e.target.value);
  };

  const handleGalleryImagesContainer = (name, imgId) => {
    setUploadedImageInfo({ id: imgId });
  };

  const handleTemplateGeneration = async () => {
    try {
      const postBody = {
        uploadedFile: uploadedImageInfo.id,
        industry: null,
        tags: '',
        baseImage: 0,
        business: selectedBusiness,
        campaignType: requestType,
        templateType: templateType === 'mms' ? 'sms' : templateType,
      };

      const result = await baseUrl.post(`gallery`, { ...postBody });
      if (result.status === 200) {
        const { id, uploadedFile } = result.data;
        setUploadedImageInfo({});
        setValue(templateType, '');
        setImagesContainer([
          ...imagesContainer,
          {
            url: uploadedFile.url,
            id,
            imgId: id,
            fileId: uploadedFile.id,
            templateType: uploadedFile.meta,
          },
        ]);
      }
    } catch (err) {
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  useEffect(() => {
    if (Object.keys(uploadedImageInfo).length) {
      handleTemplateGeneration();
    }
  }, [uploadedImageInfo]);

  const handleDeleteConfirmation = useCallback(async () => {
    if (deleteId) {
      cancellationPopup.handleHideShowModal();
      if (selectedImagesIndex !== null && imagesContainer[selectedImagesIndex].imgId === deleteId) {
        setSelectedImagesIndex(null);
      }
      try {
        const result = await baseUrl.delete(`gallery/${deleteId}`);
        if (result.status === 200) {
          dispatch({ type: 'SET_ALERT_MESSAGE_TYPE', payload: 'success' });
          dispatch({ type: 'SHOW_ERROR', payload: 'Image deleted Successfully' });

          const imagesContainerCopy = Array.from(imagesContainer);
          const finalArray = imagesContainerCopy.reduce((acc, curr) => {
            if (curr.imgId !== deleteId) {
              acc.push(curr);
            }
            return acc;
          }, []);

          setImagesContainer(finalArray);
        }
      } catch (err) {
        dispatch({ type: 'SET_ALERT_MESSAGE_TYPE', payload: 'error' });
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      }
    }
  }, [deleteId]);

  const imageRequirements = () => {
    switch (templateType) {
      case 'email':
        return {
          title: 'Email Image',
          smsUploadWidth: '130px',
          smsUploadHeight: '231px',
          uploaderWidth: 1200,
          uploaderHeight: 600,
          objectFit: 'contain',
        };
      case 'sms':
        return {
          title: 'SMS Image',
          smsUploadWidth: '130px',
          smsUploadHeight: '231px',
          uploaderWidth: 1080,
          uploaderHeight: 1920,
          objectFit: 'contain',
        };
      case 'socialimage':
        return {
          title: 'Social Image',
          smsUploadWidth: '200px',
          smsUploadHeight: '106px',
          uploaderWidth: 1200,
          uploaderHeight: 600,
          objectFit: 'cover',
        };
      default:
        return {
          title: 'SMS Image',
          smsUploadWidth: '130px',
          smsUploadHeight: '231px',
          uploaderWidth: 1080,
          uploaderHeight: 1920,
          objectFit: 'contain',
        };
    }
  };

  const { title, smsUploadWidth, smsUploadHeight, uploaderWidth, uploaderHeight, objectFit } =
    imageRequirements();

  const imagesContent =
    imagesContainer && imagesContainer.length ? (
      <RadioGroup aria-label="" name="" value={selectedImagesIndex} onChange={handleChange}>
        <div
          style={{
            display: 'flex',
            flexFlow: 'wrap',
            rowGap: '1rem',
            columnGap: '.8rem',
          }}
        >
          {imagesContainer.map((item, index) => (
            <SMSImageUpload
              key={index}
              title={title}
              url={`${process.env.REACT_APP_IMAGE_ENDPOINT}${item.url}`}
              handleClick={handleImageSelection}
              handleDelete={initDeleteProcess}
              deleteId={deleteId}
              id={index}
              imgId={item.imgId}
              showRadioOption
              showDeleteOption
              width={smsUploadWidth}
              height={smsUploadHeight}
              imageCustomStyle={{ borderRadius: '15px', objectFit: objectFit }}
            />
          ))}
          <ThemeProvider theme={{ width: smsUploadWidth, height: smsUploadHeight, margin: 0 }}>
            <Uploader
              name={templateType === 'mms' ? 'sms' : templateType}
              setValue={setValue}
              value=""
              handleImagesContainer={handleGalleryImagesContainer}
              imgWidth={uploaderWidth}
              imgHeight={uploaderHeight}
              apiUrl="/uploads/images"
              imageType="gallery"
              allImageSize={allImageSize}
              customStyle={customStyle}
            />
          </ThemeProvider>
        </div>
      </RadioGroup>
    ) : (
      <Grid item xs={2} sm={2} lg={2} md={2} style={{ margin: '1em' }}>
        <ThemeProvider theme={{ width: smsUploadWidth, height: smsUploadHeight, margin: 0 }}>
          <Uploader
            name={templateType === 'mms' ? 'sms' : templateType}
            setValue={setValue}
            value=""
            handleImagesContainer={handleGalleryImagesContainer}
            imgWidth={uploaderWidth}
            imgHeight={uploaderHeight}
            apiUrl="/uploads/images"
            imageType="gallery"
            customStyle={customStyle}
          />
        </ThemeProvider>
      </Grid>
    );

  const imagesContentContainer = loader ? <ContainerLoader /> : imagesContent;

  return (
    <>
      <ThemeProvider theme={{ overflowY: 'hidden' }}>
        <OnBoardingPopUp show={show} handleClose={handleClose}>
          <Box
            flexDirection="column"
            style={{ padding: '1rem', flex: 1, rowGap: '1.5rem', display: 'flex' }}
          >
            <Box
              flexDirection={'column'}
              display="flex"
              marginTop=" .5rem"
              style={{ rowGap: '1rem' }}
            >
              <PageTitle title="Image Gallery" />
              <Box display="flex" justifyContent={'space-between'} height="3rem">
                <MUIThemeProvider theme={tabsTheme}>
                  <AntTabs value={tabValue} onChange={handleTabChange} aria-label="ant">
                    <AntTab label="SMS" />
                    <AntTab label="Email" />
                    <AntTab label="Social Post" />
                  </AntTabs>
                </MUIThemeProvider>
                {/* <ThemeProvider theme={NoBorderDropDownTheme}>
                  <Dropdown
                    value={selectedTag}
                    label="Select Tag"
                    listOptions={tags}
                    handleChange={handleSelectTags}
                    disableUnderline
                  />
                </ThemeProvider> */}
              </Box>
            </Box>
            <div style={{ flex: '1 1 auto', overflow: 'auto', height: '55vh' }}>
              <TabPanel value={tabValue} index={0}>
                {imagesContentContainer}
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                {imagesContentContainer}
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                {imagesContentContainer}
              </TabPanel>
            </div>
            <div style={{ flex: '1', display: 'flex', justifyContent: 'flex-end' }}>
              <PrimaryButton
                onClick={handleImageInsertion}
                disabled={selectedImagesIndex === null || selectedImagesIndex < 0}
              >
                Insert Image
              </PrimaryButton>
            </div>
          </Box>
        </OnBoardingPopUp>
        <CancellationPopup
          show={cancellationPopup.showModal}
          showDeletionPopUp={handleDeleteConfirmation}
          handleClose={cancellationPopup.handleHideShowModal}
          deleteLoader={false}
        />
      </ThemeProvider>
    </>
  );
}

GalleryPopup.propTypes = {
  handleClose: Proptypes.func,
  show: Proptypes.bool,
  requestType: Proptypes.string,
  handleImagesContainer: Proptypes.func,
  type: Proptypes.string,
};

GalleryPopup.defaultProps = {
  handleImagesContainer: () => {},
  type: 'sms',
};

export default GalleryPopup;
