import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Dropdown from '../../../../Dropdown/Dropdown';

const ContainerSeletector = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 55px;
  background-color: #fff;
`;

const StyleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  height: max-content;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
  overflow: hidden;
  margin-top:10px;
`;

const ContainerIcons = styled.div`
  cursor: pointer;
`;

const StyleTitle = styled.span`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
`;

const StyledTextQuestion = styled.span`
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  color: #808080;
`;


const Trigger = ({
  questionAddType,
  surveyBody,
  setSurveyBody,
  updateQuestionIndex,
  setQuestionTriggers
}) => {
  const [show, setShow] = useState(false);
  const [questionSelected, setQuestionSelected] = useState(null);
  const [triggers, settriggers] = useState([]);
  const [targetQuestionOptions, setTargetQuestionOptions] = useState([]);

  // hardcode: replace after 
  const payloadTargetUser = [



    {
      location: [
        4,
        5,
        10,
        23,
        128,
        141,
        161,
        92,
        91,
        95,
        94,
        97,
        81,
        88,
        83,
        82,
        89,
        86,
        90,
        84,
        31,
        85,
        87,
        93
      ],
      user: 1
    },
  ]
  const [triggersHardCode, setTriggersHardCode] = useState([
    {
      expectedValue: "Cell Phone",
      action: "email",
      message: "@customerName at @locationName would like a member of the management team to follow up with them via Cell Phone @customerCell",
      targetUser: payloadTargetUser

    },
    {
      expectedValue: "Email",
      action: "email",
      message: "@customerName at @locationName would like a member of the management team to follow up with them via Email @customerEmail",
      targetUser: payloadTargetUser
    },
    {
      expectedValue: "Text Message",
      action: "email",
      message: "@customerName at @locationName would like a member of the management team to follow up with them via Text Message @customerCell",
      targetUser: payloadTargetUser
    },
    {
      expectedValue: "Website/Property Portal",
      action: "email",
      message: "@customerName would like a member of the management team to follow up with them via the property portal @locationName",
      targetUser: payloadTargetUser
    },
  ])

  const SelectFlowComponent = ({ choice, index, triggers = [] }) => {
    const [expectedValue, setExpectedValue] = useState(null);
    const [targetQuestion, setTargetQuestion] = useState(null);
    const [exptectedOptions, setexptectedOptions] = useState([]);

    useEffect(() => {
      setexptectedOptions((prevOptions) => {
        const updatedOptions = [...prevOptions, { label: choice, value: choice }];
        return updatedOptions;
      });
    }, [choice]);




    useEffect(() => {
      if (triggers && triggers[index]) {
        setExpectedValue(triggers[index].expectedValue)
        setTargetQuestion(triggers[index].targetQuestion)
      }
    }, [triggers, index])

    useEffect(() => {
      const isValidExpectedValue = expectedValue !== undefined && expectedValue !== null;

      if (isValidExpectedValue && targetQuestion) {
        const newtriggers = [...triggers];
        const existingItem = newtriggers[index];

        if (
          existingItem &&
          (existingItem.expectedValue !== expectedValue || existingItem.targetQuestion !== targetQuestion)
        ) {


          const payload = triggersHardCode.find(trigger => trigger.expectedValue === targetQuestion)

          newtriggers[index] = { expectedValue, targetQuestion, payload };
          settriggers(newtriggers);
        } else if (!existingItem) {
          const payload = triggersHardCode.find(trigger => trigger.expectedValue === targetQuestion)

          settriggers([...triggers, { expectedValue, targetQuestion, payload }]);
        }
      }
    }, [expectedValue, targetQuestion]);


    useEffect(() => {
      if (updateQuestionIndex != null && triggers.length > 0) {
        const payload = surveyBody
        payload.questions[updateQuestionIndex].triggers = triggers
        setSurveyBody(payload)
      }
    }, [triggers])

    useEffect(() => {
      const triggersLocal = surveyBody.questions[updateQuestionIndex].triggers
      if (triggersLocal && triggersLocal.length > 0) {
        settriggers(triggersLocal)
      } else {
        settriggers(triggersLocal)
      }
    }, [updateQuestionIndex])



    return (
      <div style={{ display: 'flex' }}>
        <div style={{ width: '50%' }}>
          <StyledTextQuestion>If Selected</StyledTextQuestion>
          <ThemeProvider
            theme={{
              marginTop: '0px',
              minWidth: '100%',
            }}
          >
            <Dropdown
              value={expectedValue}
              listOptions={exptectedOptions}
              label="Expected Value"
              title={false}
              handleChange={(e) => setExpectedValue(e.target.value)}
              disableUnderline
            />
          </ThemeProvider>
        </div>

        <div style={{ width: '50%' }}>
          <StyledTextQuestion>Activate Trigger</StyledTextQuestion>
          <ThemeProvider
            theme={{
              marginTop: '0px',
              minWidth: '100%',
            }}
          >
            <Dropdown
              value={targetQuestion}
              listOptions={targetQuestionOptions}
              label="Activate Trigger"
              title={false}
              handleChange={(e) => setTargetQuestion(e.target.value)}
              disableUnderline
            />
          </ThemeProvider>
        </div>
      </div>
    );
  };


  useEffect(() => {
    if (surveyBody?.questions[updateQuestionIndex]) {
      setQuestionSelected(surveyBody.questions[updateQuestionIndex]);
    }
  }, [updateQuestionIndex]);
  useEffect(() => {
    setTargetQuestionOptions(
      triggersHardCode.map((trigger) => {
        return {
          label: trigger.expectedValue,
          value: trigger.expectedValue,
        };
      })
    );
  }, []);

  useEffect(() => {
    if (questionAddType.length === 0) {
      setQuestionSelected(null);
    }
  }, [questionAddType]);



  return (

    <StyleContainer>
      <ContainerSeletector>
        <StyleTitle>Trigger</StyleTitle>
        {show ? (
          <ContainerIcons>
            <KeyboardArrowUpIcon onClick={() => setShow(false)} />
          </ContainerIcons>
        ) : (
          <ContainerIcons>
            <KeyboardArrowDownIcon onClick={() => setShow(true)} />
          </ContainerIcons>
        )}
      </ContainerSeletector>
      {show && (
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
          <StyledTextQuestion>
            Triggers take effect after a selection has been made, and the user presses the next button
          </StyledTextQuestion>
          {questionSelected &&
            questionSelected?.choices.map((choice, index) => {
              return <SelectFlowComponent key={index} choice={choice} index={index} triggers={triggers} />;
            })}
        </div>
      )}
    </StyleContainer>

  );
};

export default Trigger;
