import React from 'react';
import {
  Grid,
  Box,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  LinearProgress,
  withStyles,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import styled, { keyframes } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { ExpandMore } from '@material-ui/icons';
import theme from '../../setup';
import useTextColors from '../../hooks/useTextColors';
import PrimaryButton from '../Common/PrimaryButton';
import StatisticItem from './StatisticItem';
import SendResultItem from './SendResultItem';
import HeaderResultItem from './HeaderResultItem';
import StatBox from './StatBox';
import { imageURLCheck } from '../../utils/helper/helper';

const StyledScreenContainer = styled(Grid)`
  flex-direction: column;
`;

const StyledNavLink = styled(NavLink)`
  color: #49d6a0 !important;
  font-size: 14px;
`;

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const StyledContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  animation: ${fadeInAnimation} 0.5s ease-in-out;
`;

const StyledBeginText = styled.div`
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 600;
`;
const StyledResultText = styled.div`
  padding-left: 1.5rem;
  font-size: 15px;
  color: grey;
`;
const StyledExtraText = styled.span`
  font-weight: 400;
`;
const StyledAccordion = styled(Accordion)`
  border: '1px solid rgba(0, 0, 0, .125)';
  box-shadow: 'none';
`;

const StyledImage = styled.img`
  height: 45px;
  width: 45px;
  border-radius: 4px;
  margin-right: 0.5rem;
  aspect-ratio: 16 / 9;
  object-fit: cover;
`;

const ColoredLinearProgress = withStyles({
  root: {
    backgroundColor: '#ececec', // Change track background color here
  },
  bar: {
    backgroundColor: '#30feaf', // Change progress bar color here
  },
})(LinearProgress);

function StatusMessage(props) {
  const { message, actionBtnText, handleClose } = props;
  // const { textColors } = useTextColors();
  const history = useHistory();

  const resultsInfo = message?.results;

  const blockedResults = resultsInfo
    .filter((item) => !item.sendFreqCanSend)
    .map((item) => ({
      id: item.id,
      name: item.name,
      phone: item.phone,
      email: item.email,
      lastSent: item.lastSent,
    }))
    .sort((a, b) => {
      return new Date(b.lastSent) - new Date(a.lastSent);
    });

  const successful = resultsInfo
    .filter((item) => item.sendFreqCanSend)
    .map((item) => ({
      id: item.id,
      name: item.name,
      phone: item.phone,
      canSendPhone: item.canSendPhone,
      phoneResult: item.phoneResult,
      email: item.email,
      canSendEmail: item.canSendEmail,
      emailResult: item.emailResult,
      lastSent: item.lastSent,
    }))
    .sort((a, b) => {
      return new Date(b.lastSent) - new Date(a.lastSent);
    });

  const handleGotoFeedback = () => {
    handleClose();
    history.push(
      `/app/feedback/${
        message.campaignType === 'review request' ? 'review-request' : message.campaignType
      }`,
    );
  };

  const capitalized = (word) => word.charAt(0).toUpperCase() + word.slice(1);

  return (
    <StyledScreenContainer container>
      <Box display="flex" flexDirection="column" style={{ backgroundColor: '#d9d9d9' }}>
        <Box
          style={{
            columnGap: '.5rem',
            display: 'flex',
            padding: '1.5rem 1.5rem 0 1.5rem',
            alignItems: 'center',
          }}
        >
          {message.images.campaignMMSImage ? (
            <StyledImage src={imageURLCheck(message.images.campaignMMSImage)} />
          ) : null}
          <StyledBeginText>{message.campaignName}</StyledBeginText>
        </Box>
        {Object.keys(message.totals).map((key) => (
          <Box>
            <StyledResultText>{capitalized(key)}</StyledResultText>
            <Box
              display="flex"
              justifyContent="space-between"
              flex="1"
              padding="1rem"
              margin="0 1.5rem 1.5rem"
              borderRadius="8px"
              style={{ backgroundColor: '#ececec' }}
              StyledResultText
            >
              <StatBox count={message.totals[key].attempted} subtitle="Total" />
              <StatBox count={message.totals[key].sent} subtitle="Successful" />
              <StatBox count={message.totals[key].blocked} subtitle="Blocked" />
            </Box>
          </Box>
        ))}
      </Box>
      <ColoredLinearProgress variant="determinate" value={message.percentage} />
      {message.percentage === 100 ? (
        <>
          <StyledContentBox>
            {successful.length ? (
              <StyledAccordion
                elevation={0}
                style={{ background: 'transparent', padding: '0 .5rem' }}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <StatisticItem message="Sent" status="success" value={successful.length} />
                </AccordionSummary>
                <AccordionDetails style={{ maxHeight: '100px', overflow: 'auto' }}>
                  <Box display="flex" flexDirection="column" flex="1" style={{ rowGap: '1rem' }}>
                    <HeaderResultItem />
                    {successful.map((item) => (
                      <SendResultItem
                        key={item.id}
                        name={item.name}
                        phone={item.phone}
                        email={item.email}
                        lastSent={item.lastSent}
                        status="success"
                        statusObj={{
                          phoneResult: item.phoneResult,
                          emailResult: item.emailResult,
                        }}
                      />
                    ))}
                  </Box>
                </AccordionDetails>
              </StyledAccordion>
            ) : null}
            {blockedResults.length ? (
              <StyledAccordion
                elevation={0}
                style={{ background: 'transparent', padding: '0 .5rem' }}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <StatisticItem
                    message={`Blocked (frequency of ${message.sendFrequency} days)`}
                    status="error"
                    value={blockedResults.length}
                  />
                </AccordionSummary>
                <AccordionDetails style={{ maxHeight: '100px', overflow: 'auto' }}>
                  <Box display="flex" flexDirection="column" flex="1" style={{ rowGap: '1rem' }}>
                    <HeaderResultItem />
                    {blockedResults.map((item) => (
                      <SendResultItem
                        key={item.id}
                        name={item.name}
                        phone={item.phone}
                        email={item.email}
                        lastSent={item.lastSent}
                        status="error"
                        statusObj={{
                          phoneResult: item.phoneResult,
                          emailResult: item.emailResult,
                        }}
                      />
                    ))}
                  </Box>
                </AccordionDetails>
              </StyledAccordion>
            ) : null}
          </StyledContentBox>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            margin="0 1rem 1rem 1rem"
          >
            <PrimaryButton outlined onClick={() => handleGotoFeedback()}>
              Go to feedback
            </PrimaryButton>
            <PrimaryButton onClick={handleClose}>{actionBtnText}</PrimaryButton>
          </Box>
        </>
      ) : null}
    </StyledScreenContainer>
  );
}

export default StatusMessage;
