import React, { lazy } from 'react';
import { CampaignStatusList, employeeTypeList } from '../utils/api/filters';
import CampaignContainer from '../pages/Campaign/CampaignContainer';
import CustomerView from '../pages/Customers/CustomerView';
import ImportCustomers from '../pages/Customers/ImportCustomers';
import Employee from '../pages/Account/Employee';
import SurveyInformation from '../pages/Report/SurveyInformation';

const dashboard = lazy(() => import('../pages/Dashboard/Dashboard'));
const setup = lazy(() => import('../pages/Setup'));
const listing = lazy(() => import('../pages/Listing'));
const pageNotFound = lazy(() => import('../pages/PageNotFound'));
const Account = lazy(() => import('../pages/Account/Account'));
const ReviewsView = lazy(() => import('../pages/Reviews/ReviewsView'));
const EditUser = lazy(() => import('../pages/Account/EditUser'));
const AddClient = lazy(() => import('../pages/Customers/AddClient'));
const Customers = lazy(() => import('../pages/Customers'));
const conversations = lazy(() => import('../pages/Conversation/Conversation'));
const Survey = lazy(() => import('../pages/Survey/Survey'));
const editSurvey = lazy(() => import('../pages/Survey/EditSurvey'));
const campaignType = lazy(() => import('../pages/Campaign/CampaignType'));
const Reports = lazy(() => import('../pages/Report/Reports'));

const empRoutes = [
  {
    path: '/app/dashboard',
    exact: true,
    component: dashboard,
  },
  // {
  //   path: '/app/feedback',
  //   exact: true,
  //   component: Customers,
  // },
  {
    path: '/app/feedback/review-request',
    exact: true,
    component: Customers,
  },
  {
    path: '/app/feedback/survey',
    exact: true,
    component: Customers,
  },
  {
    path: '/app/feedback/message',
    exact: true,
    component: Customers,
  },
  {
    path: '/app/setup',
    exact: true,
    component: setup,
  },

  // {
  //   path: '/app/setup/campaigns',
  //   exact: true,
  //   component: setup,
  // },
  {
    path: '/app/setup/campaign-type',
    exact: true,
    component: campaignType,
  },

  {
    path: '/app/list',
    exact: true,
    component: listing,
  },
  {
    path: '/app/account/contacts',
    exact: true,
    component: Account,
  },
  {
    path: '/app/setup/users',
    exact: true,
    component: setup,
  },
  {
    path: '/app/setup/users/:id',
    exact: true,
    component: EditUser,
  },

  { path: '/app/account/contacts/:id', exact: true, component: AddClient },
  {
    path: '/app/conversations',
    exact: true,
    component: conversations,
  },
  {
    path: '/app/Reviews',
    exact: true,
    component: ReviewsView,
  },
  {
    path: '/app/survey',
    exact: true,
    component: Survey,
  },
  {
    path: '/app/survey/active-survey',
    exact: true,
    component: Survey,
  },
  {
    path: '/app/survey/completed-survey',
    exact: true,
    component: Survey,
  },
  {
    path: '/app/survey/paused-survey',
    exact: true,
    component: Survey,
  },
  { path: '/app/survey/edit-survey/:id/:type/:path', exact: true, component: editSurvey },
  // {
  //   path: '/app/reports',
  //   exact: true,
  //   component: Reports,
  // },
  {
    path: '/app/reports',
    exact: true,
    component: Reports,
  },
  {
    path: '/app/reports/survey-information/:id/:business',
    exact: true,
    component: SurveyInformation,
  },
  // {
  //   path: '/app/Reviews',
  //   exact: true,
  //   component: ReviewsView,
  // },
  {
    path: '*',
    exact: false,
    component: pageNotFound,
  },
];

export default empRoutes;

export const empRoutesRedirection = [
  {
    exact: true,
    path: '/app',
    redirectTo: '/app/reports',
  },
  {
    exact: true,
    path: '/app/account',
    redirectTo: '/app/account/contacts',
  },
  {
    exact: true,
    path: '/app/feedback',
    redirectTo: '/app/feedback/review-request',
  },
  {
    exact: true,
    path: '/app/survey',
    redirectTo: '/app/survey/active-survey',
  },
  {
    exact: true,
    path: '/app/reports',
    redirectTo: '/app/reports',
  },
  // {
  //   exact: true,
  //   path: '/app/setup',
  //   redirectTo: '/app/setup/campaigns',
  // },
];

export const empSetupTabs = ({
  campaignStatusFilter,
  handleCapmpaignStatusFilter,
  handleCampaignTypeRedirection,
  openCampaignPopup,
  handleCampaignPopupClose,
  handleOpenCampaignPopup,
  selectedEmployeeType,
  handleEmployeeList,
  handleEmployeeRedirection,
}) => [
  // {
  //   tabName: 'Campaigns',
  //   actionBtnTitle: 'Add Campaigns',
  //   tabComponent: (
  //     <CampaignContainer
  //       open={openCampaignPopup}
  //       handleClose={handleCampaignPopupClose}
  //       handleOpenPopup={handleOpenCampaignPopup}
  //       campaignStatusFilter={campaignStatusFilter}
  //     />
  //   ),
  //   list: CampaignStatusList,
  //   value: campaignStatusFilter,
  //   handleChange: handleCapmpaignStatusFilter,
  //   handleClick: handleCampaignTypeRedirection,
  //   showActionBtn: false,
  //   showFilter: true,
  //   showSwitchBtn: false,
  //   tabValue: 0,
  //   tabPath: '/app/setup/campaigns',
  // },
  {
    tabName: 'Users',
    actionBtnTitle: 'Add Users',
    tabComponent: <Employee selectedEmployeeType={selectedEmployeeType} />,
    list: employeeTypeList,
    value: selectedEmployeeType,
    handleChange: handleEmployeeList,
    handleClick: handleEmployeeRedirection,
    showActionBtn: false,
    showFilter: true,
    showSwitchBtn: false,
    tabValue: 1,
    tabPath: '/app/setup/users',
  },
];

export const empAccountTabs = ({
  options,
  selectedIndex,
  handleGetImportCustomersFile,
  handleContactBtnClick,
  disabledBtn,
  contactPopover,
  showLoader,
}) => [
  {
    tabName: 'Contacts',
    actionBtnTitle: '',
    switchBtnTitle: options[selectedIndex],
    tabComponent:
      options[selectedIndex] === 'Add' ? (
        <CustomerView />
      ) : (
        <ImportCustomers handleGetImportCustomersFile={handleGetImportCustomersFile} />
      ),
    list: [],
    value: '',
    handleChange: () => {},
    handleClick: handleContactBtnClick,
    showActionBtn: false,
    showFilter: false,
    showSwitchBtn: true,
    disabledBtn,
    handleSwitchBtnClick: contactPopover.handleClick,
    switchBtnId: contactPopover.id,
    showLoader,
    tabValue: 1,
    tabPath: '/app/account/contacts',
  },
];
