import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Dropdown from '../../../../Dropdown/Dropdown';

const ContainerSeletector = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 55px;
  background-color: #fff;
`;

const StyleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  height: max-content;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
  overflow: hidden;
`;

const ContainerIcons = styled.div`
  cursor: pointer;
`;

const StyleTitle = styled.span`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
`;

const StyledTextQuestion = styled.span`
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  color: #808080;
`;


const FlowController = ({
  questionAddType,
  surveyBody,
  setSurveyBody,
  updateQuestionIndex,
  setQuestionFlowControl
}) => {
  const [show, setShow] = useState(false);
  const [questionSelected, setQuestionSelected] = useState(null);
  const [flowControl, setflowControl] = useState([]);
  const [targetQuestionOptions, setTargetQuestionOptions] = useState([]);

  const SelectFlowComponent = ({ choice, index, flowControl }) => {
    const [expectedValue, setExpectedValue] = useState(null);
    const [targetQuestion, setTargetQuestion] = useState(null);
    const [exptectedOptions, setexptectedOptions] = useState([
      {
        label: 'next question',
        value: '',
      },
      {
        label: 'any response',
        value: '*',
      },
    ]);

    useEffect(() => {
      setexptectedOptions((prevOptions) => {
        const updatedOptions = [...prevOptions, { label: choice, value: choice }];
        return updatedOptions;
      });
    }, [choice]);

    useEffect(() => {
      if (flowControl && flowControl[index]) {
        setExpectedValue(flowControl[index].expectedValue)
        setTargetQuestion(flowControl[index].targetQuestion)
      }
    }, [flowControl, index])


    useEffect(() => {
      const isValidExpectedValue = expectedValue !== undefined && expectedValue !== null;

      if (isValidExpectedValue && targetQuestion) {
        const newFlowControl = [...flowControl];
        const existingItem = newFlowControl[index];

        if (
          existingItem &&
          (existingItem.expectedValue !== expectedValue || existingItem.targetQuestion !== targetQuestion)
        ) {
          newFlowControl[index] = { expectedValue, targetQuestion };
          setflowControl(newFlowControl);
        } else if (!existingItem) {
          setflowControl([...flowControl, { expectedValue, targetQuestion }]);
        }
      }
    }, [expectedValue, targetQuestion]);


    useEffect(() => {
      if (updateQuestionIndex != null && flowControl.length > 0) {
        const payload = surveyBody
        payload.questions[updateQuestionIndex].flowControl = flowControl
        setSurveyBody(payload)
      } else {
        setQuestionFlowControl(flowControl)
      }
    }, [flowControl])

    useEffect(() => {
      const flowControlLocal = surveyBody.questions[updateQuestionIndex].flowControl
      if (flowControlLocal && flowControlLocal.length > 0) {
        setflowControl(flowControlLocal)
      } else {
        setflowControl(flowControlLocal)
      }
    }, [updateQuestionIndex])




    return (
      <div style={{ display: 'flex' }}>
        <div style={{ width: '50%' }}>
          <StyledTextQuestion>If Selected</StyledTextQuestion>
          <ThemeProvider
            theme={{
              marginTop: '0px',
              minWidth: '100%',
            }}
          >
            <Dropdown
              value={expectedValue}
              listOptions={exptectedOptions}
              label="Expected Value"
              title={false}
              handleChange={(e) => setExpectedValue(e.target.value)}
              disableUnderline
            />
          </ThemeProvider>
        </div>

        <div style={{ width: '50%' }}>
          <StyledTextQuestion>Go To</StyledTextQuestion>
          <ThemeProvider
            theme={{
              marginTop: '0px',
              minWidth: '100%',
            }}
          >
            <Dropdown
              value={targetQuestion}
              listOptions={targetQuestionOptions}
              label="Target Question"
              title={false}
              handleChange={(e) => setTargetQuestion(e.target.value)}
              disableUnderline
            />
          </ThemeProvider>
        </div>
      </div>
    );
  };


  useEffect(() => {
    if (surveyBody?.questions[updateQuestionIndex]) {
      setQuestionSelected(surveyBody.questions[updateQuestionIndex]);
    }
  }, [updateQuestionIndex]);
  useEffect(() => {
    setTargetQuestionOptions(
      surveyBody?.questions.map((question) => {
        return {
          label: question.text,
          value: question.code,
        };
      })
    );
  }, [surveyBody?.questions]);

  useEffect(() => {
    if (questionAddType.length === 0) {
      setQuestionSelected(null);
    }
  }, [questionAddType]);




  return (
    <>
      <StyleContainer>
        <ContainerSeletector>
          <StyleTitle>Flow Controller</StyleTitle>
          {show ? (
            <ContainerIcons>
              <KeyboardArrowUpIcon onClick={() => setShow(false)} />
            </ContainerIcons>
          ) : (
            <ContainerIcons>
              <KeyboardArrowDownIcon onClick={() => setShow(true)} />
            </ContainerIcons>
          )}
        </ContainerSeletector>
        {show && (
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
            <StyledTextQuestion>
              Flow control settings take effect after a selection has been made, and
              the user presses the next button
              {' '}
            </StyledTextQuestion>
            {questionSelected &&
              questionSelected?.choices.map((choice, index) => {
                return <SelectFlowComponent key={index} choice={choice} index={index} flowControl={flowControl} />;
              })}
          </div>
        )}
      </StyleContainer>
    </>
  );
};

export default FlowController;
