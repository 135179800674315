import React, { useContext, useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import {
  Box,
  FormHelperText,
  Grid,
  ThemeProvider as MUIThemeProvider,
  InputLabel,
} from '@material-ui/core';
// import StyledTextField from '../../../../../../../GeneralInputField/GeneralInputField';
import defaultDropDownTheme from '../../../../../../../../themes/Dropdown/DefaultDropdownTheme';
import dayFrequency from '../../../../../../../../utils/api/DayFrequency';
import reminders from '../../../../../../../../utils/api/reminders';
import reminderFrequency from '../../../../../../../../utils/api/reminderFrequency';
import TextAreaCampaignSettigs from '../../../../CampaignSettings/TextAreaCampaignSetting';
import StyledSelect from '../../../../../../../Common/StyledSelect';
import StyledMenuItem from '../../../../../../../Common/StyledMenuItem';
import StyledMultiSelect from '../../../../../../../Common/StyledMultiSelect';
import StyledFormControl from '../../../../../../../StyledFormControl';
import { GlobalContext } from '../../../../../../../../Stores/GlobalStateStore';
import CustomizedSwitches from '../../../../../../../CustomSwitch/CustomSwitch';
import StyledTextField from '../../../../../../../GeneralInputField/GeneralInputField';

const StyledInputLabel = styled(InputLabel)`
  font-family: 'Montserrat' !important;
  color: rgba(0, 0, 0, 0.54) !important;
  font-size: '24px' !important;
`;

const StyledCardTitle = styled.span`
  font-weight: 600;
  font-size: 14px;
  color: #6c6c6c;
  margin-bottom: 0.5rem;
`;

const StyledDescriptionText = styled.span`
  color: #6c6c6c;
  font-size: 12px;
`;

const BoldText = styled.span`
  font-weight: 600;
`;

const TabSettings = ({
  bodyRequest,
  setBodyRequest,
  settingsErrors,
  campaignLocation,
  subjectValue,
}) => {
  const [userSelected, setUserSelected] = useState(bodyRequest.user || []);
  const { state } = useContext(GlobalContext);
  const { locations } = state;

  const filteredUsers = state.locationInfo.user.filter((user) => user.isDeleted === false);

  const menuUsers = filteredUsers.map((user) => ({
    label: user.contact.fullName,
    value: user.id,
    disabledMenuItem: false,
  }));

  useEffect(() => {
    setBodyRequest({
      ...bodyRequest,
      user: userSelected,
    });
  }, [userSelected]);

  useEffect(() => {
    setBodyRequest({
      ...bodyRequest,
      settings: {
        ...bodyRequest.settings,
        autoReminder: bodyRequest.settings.reminders > 0 ? true : false,
      },
    });
  }, [bodyRequest.settings.reminders]);

  const autoReviewReminder =
    bodyRequest.settings.reminders > 0 ? (
      <Grid item md={12} lg={12} sm={12} xs={12}>
        <Grid container spacing={4}>
          <Grid item md={12} lg={12} sm={12} xs={12}>
            <TextAreaCampaignSettigs
              title="Review reminder text"
              value={bodyRequest.settings.reminderText}
              setValue={(value) => {
                setBodyRequest({
                  ...bodyRequest,
                  settings: {
                    ...bodyRequest.settings,
                    reminderText: value,
                    autoReminder: true,
                  },
                });
              }}
              multiline
            />
            {settingsErrors?.reminderText && (
              <StyledFormControl error={!!settingsErrors?.reminderText}>
                <FormHelperText>{settingsErrors?.reminderText}</FormHelperText>
              </StyledFormControl>
            )}
          </Grid>
        </Grid>
      </Grid>
    ) : null;

  const getSmartSendText = () => {
    if (bodyRequest.settings.smartSend) {
      return (
        <>
          {'When enabled, Reveo will attempt to send no more than '}
          <BoldText>{Math.round(subjectValue / bodyRequest.settings.canResendAfter)}</BoldText>
          {' requests per day.'}
        </>
      );
    } else {
      return 'When enabled, Reveo will attempt to meter requests according to the customer base and send frequency setting.';
    }
  };

  return (
    <Grid container spacing={4} style={{ maxHeight: '46vh', overflowY: 'auto' }}>
      <Grid item xs={12} sm={6} md={8} lg={8} xl={8}>
        <ThemeProvider theme={defaultDropDownTheme}>
          <StyledFormControl>
            <StyledMultiSelect
              name="users"
              options={menuUsers}
              label="Campaign Users *"
              value={userSelected}
              error={!!settingsErrors?.user}
              helpertext={settingsErrors?.user}
              onChange={(e) => setUserSelected(e.target.value)}
            />
          </StyledFormControl>
        </ThemeProvider>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
        <ThemeProvider theme={defaultDropDownTheme}>
          <StyledFormControl>
            <StyledInputLabel>From Location *</StyledInputLabel>
            <StyledSelect
              options={Array.from(locations)}
              label="Location"
              value={bodyRequest.location}
              onChange={(e) =>
                setBodyRequest({
                  ...bodyRequest,
                  location: e.target.value,
                })
              }
            >
              {locations.map((location) => (
                <StyledMenuItem key={location.id} value={location.id}>
                  {location.nickName}
                </StyledMenuItem>
              ))}
            </StyledSelect>
          </StyledFormControl>
        </ThemeProvider>
      </Grid>
      {/* <Grid item md={4} lg={4} sm={6} xs={12}>
        <ThemeProvider theme={defaultDropDownTheme}>
          <StyledFormControl>
            <StyledInputLabel>Attribution Period</StyledInputLabel>
            <StyledSelect
              options={Array.from(dayFrequency)}
              label="Attribution"
              value={bodyRequest.settings.attributionPeriod}
              onChange={(e) =>
                setBodyRequest({
                  ...bodyRequest,
                  settings: {
                    ...bodyRequest.settings,
                    attributionPeriod: e.target.value,
                  },
                })
              }
            >
              {dayFrequency.map((frequency) => (
                <StyledMenuItem key={frequency.value} value={frequency.value}>
                  {frequency.label}
                </StyledMenuItem>
              ))}
            </StyledSelect>
          </StyledFormControl>
        </ThemeProvider>
      </Grid> */}

      <Grid item md={4} lg={4} sm={6} xs={6}>
        <ThemeProvider theme={defaultDropDownTheme}>
          <StyledFormControl>
            <StyledInputLabel>Send Frequency</StyledInputLabel>
            <StyledSelect
              options={Array.from(dayFrequency)}
              label="Send Frequency"
              value={bodyRequest.settings.canResendAfter}
              onChange={(e) =>
                setBodyRequest({
                  ...bodyRequest,
                  settings: {
                    ...bodyRequest.settings,
                    canResendAfter: parseInt(e.target.value),
                  },
                })
              }
            >
              {dayFrequency.map((frequency) => (
                <StyledMenuItem key={frequency.value} value={frequency.value}>
                  {frequency.label}
                </StyledMenuItem>
              ))}
            </StyledSelect>
          </StyledFormControl>
        </ThemeProvider>
      </Grid>
      <Grid item md={4} lg={4} sm={6} xs={6}>
        <ThemeProvider theme={defaultDropDownTheme}>
          <StyledFormControl>
            <StyledInputLabel>Reminders</StyledInputLabel>
            <StyledSelect
              options={Array.from(reminders)}
              label="Select Reminder *"
              value={bodyRequest.settings.reminders}
              error={!!settingsErrors?.reminders}
              helpertext={settingsErrors?.reminders}
              onChange={(e) =>
                setBodyRequest({
                  ...bodyRequest,
                  settings: {
                    ...bodyRequest.settings,
                    reminders: e.target.value,
                  },
                })
              }
            >
              {reminders.map((frequency) => (
                <StyledMenuItem key={frequency.value} value={frequency.value}>
                  {frequency.label}
                </StyledMenuItem>
              ))}
            </StyledSelect>
          </StyledFormControl>
        </ThemeProvider>
      </Grid>
      <Grid item md={4} lg={4} sm={6} xs={6}>
        <ThemeProvider theme={defaultDropDownTheme}>
          <StyledFormControl>
            <StyledInputLabel>Reminder Frequency</StyledInputLabel>
            <StyledSelect
              options={Array.from(reminderFrequency)}
              label="Select Reminder Frequency *"
              value={bodyRequest.settings.reminderFrequency}
              error={!!settingsErrors?.reminderFrequency}
              helpertext={settingsErrors?.reminderFrequency}
              onChange={(e) =>
                setBodyRequest({
                  ...bodyRequest,
                  settings: {
                    ...bodyRequest.settings,
                    reminderFrequency: e.target.value,
                  },
                })
              }
            >
              {reminderFrequency.map((frequency) => (
                <StyledMenuItem key={frequency.value} value={frequency.value}>
                  {frequency.label}
                </StyledMenuItem>
              ))}
            </StyledSelect>
          </StyledFormControl>
        </ThemeProvider>
      </Grid>
      {bodyRequest.settings.canResendAfter > 0 && (
        <>
          <Grid item md={8} lg={8} sm={8} xs={8} style={{ paddingRight: 0 }}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent={'space-between'}
              alignItems={'flex-start'}
            >
              <Box display="flex" flexDirection="column" marginTop=".5rem" marginRight="1rem">
                <StyledCardTitle>Enable Smart Send</StyledCardTitle>
                <StyledDescriptionText>{getSmartSendText()}</StyledDescriptionText>
              </Box>
              <CustomizedSwitches
                checked={bodyRequest.settings.smartSend}
                onChange={(e) =>
                  setBodyRequest({
                    ...bodyRequest,
                    settings: {
                      ...bodyRequest.settings,
                      smartSend: e.target.checked,
                    },
                  })
                }
              />
            </Box>
          </Grid>
          <Grid item md={4} lg={4} sm={4} xs={4} style={{ paddingTop: '0' }}>
            {bodyRequest.settings.smartSend && (
              <StyledTextField
                value={subjectValue}
                placeholder="500"
                error={bodyRequest.settings.recurringCustomers === '' ? true : false}
                onChange={(e) =>
                  setBodyRequest({
                    ...bodyRequest,
                    settings: {
                      ...bodyRequest.settings,
                      recurringCustomers: e.target.value,
                    },
                  })
                }
                label="Recurring Service Customers"
              />
            )}
          </Grid>
        </>
      )}

      {/* <Grid item xs={6} md={4} lg={4} sm={6}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent={'space-between'}
          marginRight={'-.75rem'}
        >
          <Box display="flex" flexDirection="column">
            <StyledCardTitle>Auto Review Reminder</StyledCardTitle>
            <StyledDescriptionText>
              Automatically send the customer a review reminder after 24 hours
            </StyledDescriptionText>
          </Box>
          <CustomizedSwitches
            checked={bodyRequest.settings.autoReminder}
            onChange={(e) =>
              setBodyRequest({
                ...bodyRequest,
                settings: {
                  ...bodyRequest.settings,
                  autoReminder: e.target.checked,
                },
              })
            }
          />
        </Box>
      </Grid> */}
      {autoReviewReminder}
      <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
        <Grid item xs={12} sm={12} md={3} lg={3} />
      </Box>
    </Grid>
  );
};

export default TabSettings;
