import styled, { ThemeProvider } from 'styled-components';
import React, { useContext, useState } from 'react';
import { FormHelperText, IconButton, Box, InputLabel } from '@material-ui/core';
import ClearIcon from 'mdi-react/CloseCircleIcon';
import PlusCircleIcon from 'mdi-react/PlusCircleIcon';
import TextAreaCampaignSettigs from '../../../../CampaignSettings/TextAreaCampaignSetting';
import { OnBoardingContext } from '../../../../../../../../Stores/OnBoarding';
import { GlobalContext } from '../../../../../../../../Stores/GlobalStateStore';
import usePopover from '../../../../../../../../hooks/usePopover';
import WebsitePopover from '../WebsitePopover';
import StyledFormControl from '../../../../../../../StyledFormControl';
import CustomizedSwitches from '../../../../../../../CustomSwitch/CustomSwitch';
import { getIcon } from '../../../../../../../../utils/helper/helper';
import CampaignPreview from '../CampaignPreview/CampaignPreview';
import MobilePreview from '../../../../../../../MobilePreview/MobilePreview';
import defaultDropDownTheme from '../../../../../../../../themes/Dropdown/DefaultDropdownTheme';
import StyledSelect from '../../../../../../../Common/StyledSelect';
import StyledMenuItem from '../../../../../../../Common/StyledMenuItem';
import baseUrl from '../../../../../../../../utils/services/config';

const StyledInputLabel = styled(InputLabel)`
  font-family: 'Montserrat' !important;
  color: rgba(0, 0, 0, 0.54) !important;
  font-size: '24px' !important;
`;

const StyledImpTextContainer = styled.div`
  margin: 0.5rem 0 0 0 !important;
  display: flex !important;
  flex-direction: column !important;
`;

const StyledCardTitle = styled.span`
  font-weight: 600;
  font-size: 14px;
  color: #6c6c6c;
  margin-bottom: 0.5rem;
`;

const StyledDescriptionText = styled.span`
  color: #6c6c6c;
  font-size: 12px;
  margin-bottom: 0.5rem;
`;

const StyledWebsiteContainer = styled.div`
  background-color: #e9e9e9;
  border-radius: 30px;
  padding: 5px 5px 5px 10px;
  margin-top: 5px;
  column-gap: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;
const StyledClearIcon = styled(ClearIcon)`
  color: #c9c9c9 !important;
  width: 18px;
  height: 18px;
`;

const StyledWebsitesText = styled.div`
  color: ${(props) => props.theme.color};
  font-family: Montserrat;
  font-size: 12px !important;
  text-transform: capitalize;
`;

const StyledSiteImage = styled.img`
  width: 24px;
  height: 24px;
`;

const TabSocial = ({
  setBodyRequest,
  bodyRequest,
  socialErrors,
  selectedPostsites,
  setSelectedPostsites,
  businessInfo,
  locationInfo,
}) => {
  const addNetworkPopover = usePopover();

  const { onBoardingData } = useContext(OnBoardingContext);

  const { postSites, userList } = onBoardingData;

  const locationLogo = locationInfo?.uploadedFile.filter((file) => file.meta === 'business_logo')[0]
    ?.url;

  const handleRemoveSite = (name) => {
    const updatedSites = selectedPostsites.filter((site) => site.name !== name);
    setSelectedPostsites(updatedSites);

    setBodyRequest({
      ...bodyRequest,
      postSites: updatedSites,
    });
  };

  const getUserImage = (userId) => {
    if (userId === 'none') {
      setBodyRequest({
        ...bodyRequest,
        settings: { ...bodyRequest.settings, userImage: {}, campaignUser: 'none' },
      });
      return;
    }
    baseUrl
      .get(`/user/${userId}`)
      .then((res) => {
        setBodyRequest({
          ...bodyRequest,
          settings: {
            ...bodyRequest.settings,
            userImage: res.data.uploadedFile[0],
            campaignUser: userId,
          },
        });
        // setPreviewData({
        //   ...previewData,
        //   userAvatar: res.data.uploadedFile[0].url,
        // });
      })
      .catch((err) => {
        // dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      });
  };

  return (
    <Box display={'flex'} style={{ columnGap: '2rem' }}>
      <MobilePreview width={'183px'} height={'375px'}>
        <CampaignPreview
          userImage={bodyRequest.userImage}
          businessLogo={locationLogo}
          settings={bodyRequest.settings}
          feedbackText={bodyRequest.title}
          postSites={selectedPostsites}
        />
      </MobilePreview>
      <div style={{ width: '75%' }}>
        <Box width={'50%'} marginBottom={'1rem'}>
          <ThemeProvider theme={defaultDropDownTheme}>
            <StyledFormControl>
              <StyledInputLabel>User Image</StyledInputLabel>
              <StyledSelect
                options={Array.from(userList)}
                label="User Image"
                value={bodyRequest.settings.campaignUser}
                onChange={(e) => getUserImage(e.target.value)}
              >
                <StyledMenuItem value="none">None</StyledMenuItem>
                {userList
                  .filter((user) => !user.isDeleted)
                  .map((user) => (
                    <StyledMenuItem key={user.value} value={user.value}>
                      {user.label}
                    </StyledMenuItem>
                  ))}
              </StyledSelect>
            </StyledFormControl>
          </ThemeProvider>
        </Box>
        <TextAreaCampaignSettigs
          title="Where to leave feedback text "
          placeholder="Please choose where you would like to leave feedback "
          value={bodyRequest.title}
          setValue={(e) =>
            setBodyRequest({
              ...bodyRequest,
              title: e,
            })
          }
          multiline
          withChip={false}
          handleAttachFileIcon={() => {}}
        />
        {socialErrors?.title && (
          <StyledFormControl error={!!socialErrors?.title}>
            <FormHelperText>{socialErrors?.title}</FormHelperText>
          </StyledFormControl>
        )}

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <StyledImpTextContainer>
              <StyledCardTitle>Review websites *</StyledCardTitle>
              <StyledDescriptionText>
                Add or remove websites to show on the review screen during the review request
                process
              </StyledDescriptionText>
            </StyledImpTextContainer>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
            {selectedPostsites.map((site) => (
              <StyledWebsiteContainer key={site.name}>
                <StyledSiteImage src={getIcon(site.name).icon} />
                <StyledWebsitesText>{site.name}</StyledWebsitesText>
                <IconButton size="small" onClick={() => handleRemoveSite(site.name)}>
                  <StyledClearIcon />
                </IconButton>
              </StyledWebsiteContainer>
            ))}

            <IconButton
              onClick={addNetworkPopover.handleClick}
              disabled={postSites.length === selectedPostsites.length}
            >
              <PlusCircleIcon color="#3BD482" />
            </IconButton>

            {postSites.length > 0 && (
              <WebsitePopover
                anchorEl={addNetworkPopover.anchorEl}
                handleClose={addNetworkPopover.handleClose}
                id={addNetworkPopover.id}
                allPostsites={postSites}
                selectedPostsites={selectedPostsites}
                setSelectedPostsites={setSelectedPostsites}
                open={addNetworkPopover.open}
                bodyRequest={bodyRequest}
                setBodyRequest={setBodyRequest}
              />
            )}
            {socialErrors?.postSites && (
              <StyledFormControl error={!!socialErrors?.postSites}>
                <FormHelperText>{socialErrors?.postSites}</FormHelperText>
              </StyledFormControl>
            )}
          </div>
        </div>
        <StyledImpTextContainer>
          <Box display="flex" flexDirection="row" justifyContent={'space-between'} width="50%">
            <Box display="flex" flexDirection="column" marginTop=".5rem" marginRight="1rem">
              <StyledCardTitle>Allow text feedback</StyledCardTitle>
              <StyledDescriptionText>
                When enabled the customer can choose to leave feedback when leaving a review is not
                possible
              </StyledDescriptionText>
            </Box>
            <CustomizedSwitches
              checked={bodyRequest.settings.allowTextFeedback}
              onChange={(e) =>
                setBodyRequest({
                  ...bodyRequest,
                  settings: {
                    ...bodyRequest.settings,
                    allowTextFeedback: e.target.checked,
                  },
                })
              }
            />
          </Box>
        </StyledImpTextContainer>
      </div>
    </Box>
  );
};

export default TabSocial;
