import React from 'react';
import styled from 'styled-components';

const StyledGoodText = styled.div`
  /* color: #32a753; */
  font-size: 14px;
  font-weight: 400;
  display: grid;
  grid-template-columns: 4fr 2fr 2fr 2fr 2fr 2fr;
  align-items: center;
`;

const BoldText = styled.div`
  font-weight: 600;
`;

const HeaderResultItem = () => (
  <StyledGoodText>
    <BoldText>Name</BoldText>
    <BoldText>Phone</BoldText>
    <BoldText />
    <BoldText>Email</BoldText>
    <BoldText />
    <BoldText>Last Sent</BoldText>
  </StyledGoodText>
);

export default HeaderResultItem;
