import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { HTML5Backend } from 'react-dnd-html5-backend';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DndProvider } from 'react-dnd';

import CircleOutlineIcon from 'mdi-react/CircleOutlineIcon';
import { TextField } from '@material-ui/core';
import styled from 'styled-components';
import SingleChoiceSortable from '../SingleChoiceSortable/SingleChoiceSortable';

const StyledSingleChoiceForm = styled.div`
  border: 1px solid #e6e6e6;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 20px;
  margin-bottom:10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  #E6E6E6
`;

const ButtonDelete = styled.div`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 10px;
  color: #e0230e;
  cursor: pointer;
`;
const ButtonSave = styled.div`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 10px;
  color: ${(props) => (props.enabled ? '#000000' : '#888888')};
  cursor: ${(props) => (props.enabled ? 'pointer' : 'not-allowed')};
`;
const ButtonDuplicate = styled.div`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  cursor: pointer;
  color: ${(props) => (props.enabled ? '#000000' : '#888888')};
  cursor: ${(props) => (props.enabled ? 'pointer' : 'not-allowed')};
`;

const LockedDiv = styled.div`
  cursor: pointer;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #ffffff;
`;

const StyleTitle = styled.div`
  background-color: #ffffff;
  padding: 10px 20px 10px 20px;
  height: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;
const SingleChoiceForm = ({
  handleSaveQuestion,
  surveyBody,
  setSurveyBody,
  updateQuestionIndex,
  setUpdateQuestion,
  setQuestionAddType,
  setQuestionFlowControl,
  setQuestionTriggers
}) => {
  const [titleQuestion, settitleQuestion] = useState('');
  const [optionsQuestion, setOptionsQuestions] = useState([
    { label: '', id: 1 },
    { label: '', id: 2 },
  ]);

  const [isDisableButton, setIsDisableButton] = useState(false);

  const handleSort = (sortedChoices) => {
    setOptionsQuestions(sortedChoices);
  };
  const generateCode = (inputString) => {
    const words = inputString.split(' ');

    const currentDate = new Date();

    const hour = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const seconds = currentDate.getSeconds();

    let code = '';
    const threeWords = words.slice(0, 5).join('');
    code += `${threeWords}${hour}${minutes}${seconds}`;



    return code;
  }


  const addQuestion = () => {
    const getLastId = (array) => {
      const lastEntry = array[array.length - 1];
      return lastEntry?.id ?? null;
    };

    const lastId = getLastId(optionsQuestion);

    setOptionsQuestions([...optionsQuestion, { label: '', id: lastId + 1 }]);
  };

  const AddQuestionComponent = () => {
    return (
      <LockedDiv onClick={addQuestion}>
        <div
          style={{
            width: '10%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div />
          <CircleOutlineIcon size={23} style={{ marginRight: 10, color: '#E6E6E6' }} />
        </div>
        <TextField
          placeholder=""
          onInput={(e) => { }}
          style={{
            width: '70%',
            opacity: 0.3,
            pointerEvents: 'none',
          }}
        />
        <div style={{ width: '20%' }} />
      </LockedDiv>
    );
  };

  const isValidQuestion = () => {
    const hasAtLeastTwoNonEmptyLabels = (options) => {
      const nonEmptyLabelsCount = options.filter((option) => option.label.trim() !== '').length;
      return nonEmptyLabelsCount >= 2;
    };
    const meetsCondition = hasAtLeastTwoNonEmptyLabels(optionsQuestion);
    if (titleQuestion.length > 0 && meetsCondition) {
      setIsDisableButton(true);
    } else {
      setIsDisableButton(false);
    }
  };

  const handleSave = () => {
    const payload = {
      text: titleQuestion,
      type: 'singleChoice',
      choices: optionsQuestion.map((item) => item.label),
      code: generateCode(titleQuestion)
    };

    handleSaveQuestion(payload);
    settitleQuestion('');
    setOptionsQuestions([
      { label: '', id: 1 },
      { label: '', id: 2 },
    ]);
    setQuestionAddType('');
  };

  const handleUpdate = () => {
    const payload = {
      text: titleQuestion,
      type: 'singleChoice',
      choices: optionsQuestion.map((item) => item.label),
    };

    // eslint-disable-next-line no-multi-assign

    const updatedQuestions = [...surveyBody.questions];
    updatedQuestions[updateQuestionIndex] = {
      ...updatedQuestions[updateQuestionIndex],
      ...payload,
    };

    setSurveyBody({
      ...surveyBody,
      questions: updatedQuestions,
    });

    settitleQuestion('');
    setOptionsQuestions([
      { label: '', id: 1 },
      { label: '', id: 2 },
    ]);

    setQuestionAddType('');
    setUpdateQuestion(null);
    setQuestionFlowControl([])
    setQuestionTriggers([])
  };
  const handleDuplicate = () => {
    alert('Duplicate');
  };
  const handleDelete = () => {
    alert('Delete');
  };

  useEffect(() => {
    isValidQuestion();
  }, [titleQuestion, optionsQuestion]);

  useEffect(() => {
    if (surveyBody.questions[updateQuestionIndex]) {
      const newOptions = [];

      // eslint-disable-next-line no-plusplus
      for (
        let index = 0;
        index < surveyBody.questions[updateQuestionIndex].choices.length;
        index++
      ) {
        const item = surveyBody.questions[updateQuestionIndex].choices[index];
        newOptions.push({ label: item, id: index });
      }
      settitleQuestion(surveyBody.questions[updateQuestionIndex].text);
      setOptionsQuestions(newOptions);
    }
  }, [updateQuestionIndex]);

  return (
    <StyledSingleChoiceForm>
      <StyleTitle>
        <TextField
          placeholder="Write a question"
          value={titleQuestion}
          onInput={(e) => {
            settitleQuestion(e.target.value);
          }}
          style={{ width: '100%' }}
        />
      </StyleTitle>
      <div style={{ width: '100%' }}>
        <DndProvider backend={HTML5Backend}>
          <SingleChoiceSortable
            choices={optionsQuestion}
            setChoices={setOptionsQuestions}
            onSort={handleSort}
          />
        </DndProvider>
        <AddQuestionComponent />
      </div>

      <ButtonContainer>
        {updateQuestionIndex !== null ? (
          <ButtonDuplicate
            enabled={isDisableButton}
            onClick={isDisableButton ? handleUpdate : null}
          >
            Update
          </ButtonDuplicate>
        ) : (
          <>
            <ButtonSave
              enabled={isDisableButton}
              onClick={() => {
                setQuestionAddType('');
              }}
            >
              Cancel
            </ButtonSave>

            <ButtonDelete onClick={handleDelete}>Delete</ButtonDelete>
            <ButtonSave enabled={isDisableButton} onClick={isDisableButton ? handleSave : null}>
              Save
            </ButtonSave>
          </>
        )}
      </ButtonContainer>
    </StyledSingleChoiceForm>
  );
};

export default SingleChoiceForm;
