/* eslint-disable no-prototype-builtins */
/* eslint-disable array-callback-return */
/* eslint-disable */
import React, { useState, useEffect, useContext, useCallback } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import {
  Grid,
  InputAdornment,
  IconButton,
  ThemeProvider as MUIThemeProvider,
  FormHelperText,
  InputLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Axios from 'axios';
import Dropdown from '../../Dropdown/Dropdown';
import baseUrl from '../../../utils/services/config';
import baseUrlLogin from '../../../utils/services/auth.config';
import CusotmAlert from '../../CustomAlert/CustomAlert';
import { GlobalContext } from '../../../Stores/GlobalStateStore';
import AvatarUploader from '../../AvatarUploader/AvatarUploader';
import NumberField from '../../NumberField/NumberField';
import CustomButton from '../../CustomButton/CustomButton';
import ButtonLoader from '../../ButtonLoader/ButtonLoader';
import Loader from '../../Loader/Loader';
import GenricPopup from '../GenricPopup';
import {
  handleLocationViewErrors,
  handlePhoneError,
  handleEmailError,
  handleResetPasswordError,
  errorChecker,
  convertIntoArrayOfObj,
  ConvertInDropDownFormat,
  getValueFromArray,
  getMultiSelectSelectedRecords,
  roleListAsPerUser,
  getDateAfterDays,
  convertIntoPhoneFormat,
} from '../../../utils/helper/helper';
import { emailRegEx, passwordRegex } from '../../../utils/helper/regEx';
// import StyledSwitch from '../../components/StyledSwitch/StyledSwitch';
import StyledTextField from '../../GeneralInputField/GeneralInputField';
import defaultDropDownTheme from '../../../themes/Dropdown/DefaultDropdownTheme';
import useShowPassword from '../../../state/ShowPassword';
import useInputFieldTheme from '../../../hooks/useInputFieldTheme';
import MultiSelect from '../../MultiSelect/MultiSelect';
import StyledFormControl from '../../StyledFormControl';
import StyledImpText from '../../ImpText/ImpText';
import StyledDescriptionText from '../../DescriptionText';
import useTextColors from '../../../hooks/useTextColors';
// import useAntSwitchTheme from '../../hooks/useAntSwitchTheme';
import StyledTrashIcon from '../../Icons/TrashIcon';
import TransperentButtonTheme from '../../../themes/Button/TransperentButtonTheme';
import AutoCompleteWidget from '../../AutoComplete';
import { FormHeader } from '../../Forms/FormHeader';
import PrimaryButton from '../../Common/PrimaryButton';
import ContainerLoader from '../../Popover/SendInvitePopover/ContainerLoader';

const dropDownTheme = {
  padding: '.825em 0.5em',
  margin: '1.5em 0em 0',
  maxWidth: '100%',
  marginTop: '1px',
};

const notifiyDropDownTheme = {
  padding: '.825em 0.5em',
  margin: '0em 0em 0',
  maxWidth: '100%',
};

const ErrorTxt = styled(Typography)`
  font-size: 12px !important;
  color: #f44336;
  font-family: Montserrat, Medium !important;
  // @media screen and (max-width: 600px) {
  //   & {
  //     font-size: 10px !important;
  //   }
  // }
`;

const StyledDashboard = styled(Grid)`
  padding: 1.5rem;
  min-height: 100%;
`;

const defaultValues = {
  notifications: {
    ListingsSynced: false,
    customerTracking: false,
    listingsInsights: false,
    profileNotification: false,
    reviewNotification: false,
    reviewResponse: false,
  },
  selectMultipleBusiness: [parseInt(localStorage.getItem('SELECTED_BUSINESS'), 10)],
  location: [parseInt(localStorage.getItem('SELECTED_LOCATION'), 10)],
};
function EditUserPopup(props) {
  const { show, handleClose, employeeToEdit } = props;

  const id = employeeToEdit ? employeeToEdit : localStorage.getItem('USER_ID');
  const loginUser = localStorage.getItem('USER_ID');
  const userId = localStorage.getItem('USER_ID');
  const { state, dispatch } = useContext(GlobalContext);
  const { roles, userRole } = state;
  const { isBusinessAdmin, isSuperAdmin, isEmployee } = roles;
  const [userdata, setuserData] = useState({});
  const [roleList, setRoleList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [campaignList, setCampaignList] = useState([]);
  const [contact, setContact] = useState([]);
  const [business, setBusiness] = useState([]);
  const [imagesContainer, setImagesContainer] = useState({});
  const [loader, setLoader] = useState(false);
  const [renderView, setRenderView] = useState(false);
  const [passwordView, setPasswordView] = useState(false);
  const [RoleDisabled, setRoleDisabled] = useState(false);
  const [locationDisabled, setlocationDisabled] = useState(false);
  // const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [disabledBtn, setDisableBtn] = useState(false);
  const [emailLoader, setEmailLoader] = useState(false);
  const [currentUserRole, setCurrentUserRole] = useState(null);
  const [hasRoleChangeAccess, setHasRoleChangeAccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [loggedInUserRole, setLoggedInUserRole] = useState(
    JSON.parse(localStorage.getItem('User')).role?.[0].id,
  );

  // const [phoneLoader, setPhoneLoader] = useState(false);
  const { handlePasswordViewToggle, passwordInfo } = useShowPassword({
    password: false,
    confirmPassword: false,
  });

  const isDisabled = loggedInUserRole === 3;

  const { inputTheme } = useInputFieldTheme();
  const { textColors } = useTextColors();
  // const { antSwitchTheme } = useAntSwitchTheme();

  const empId = id !== 'new' ? id : userId;

  const [showAlert, setAlert] = useState([
    {
      show: false,
      messageType: '',
      message: '',
    },
  ]);
  const {
    register,
    handleSubmit,
    control,
    errors,
    setValue,
    getValues,
    watch,
    setError,
    reset,
    clearError,
  } = useForm({
    defaultValues,
  });
  const copyrolelist = [];
  const history = useHistory();

  const watchFields = watch('selectMultipleBusiness');
  const watchLocationField = watch('location');
  const watchContactField = watch('phoneNumber');
  const watchEmailAddress = watch('emailAddress');
  const watchContactContainer = watch('contact');
  const watchCampaignContainer = watch('campaign');

  const fetchUserData = useCallback(() => {
    baseUrl
      .get(`/user/${empId}`)
      .then((res) => {
        if (res.data.id === parseInt(userId, 10)) {
          dispatch({
            type: 'SET_PROFILE_PIC',
            payload: res.data.uploadedFile.length > 0 ? res.data.uploadedFile[0].url : null,
          });
          setCurrentUserRole(res.data.role[0].id);
        }
        // localStorage.setItem('User', JSON.stringify(res.data));
      })
      .catch((err) => {
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      });

    // baseUrl
    //   .get(`/user/${empId}/business`)
    //   .then((res) => dispatch({ type: 'SET_BUSINESS_CONTAINER', payload: Array.from(res.data) }))
    //   .catch((err) => {
    //     dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    //   });
  }, [dispatch]);

  function dynamicSort(property) {
    let sortOrder = 1;
    let prop = property;
    if (property[0] === '-') {
      sortOrder = -1;
      prop = property.substr(1);
    }
    // eslint-disable-next-line func-names
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      // eslint-disable-next-line no-nested-ternary
      const result = a[prop] < b[prop] ? -1 : a[prop] > b[prop] ? 1 : 0;
      return result * sortOrder;
    };
  }

  useEffect(() => {
    return () => {
      setLocationList([]);
      setBusiness([]);
      setCampaignList([]);
    };
  }, []);

  const fetchRoles = useCallback(() => {
    const UserInfo = JSON.parse(localStorage.getItem('User'));
    const { role } = UserInfo;
    const url = currentUserRole !== 1 ? '/role?skip=1' : '/role';
    baseUrl
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          res.data.map((data) => copyrolelist.push({ label: data.name, value: data.id }));
          if (id === 'new') {
            setRoleList(roleListAsPerUser(copyrolelist));
          } else if (id !== 'new') {
            setRoleList(copyrolelist);
          }
        }
      })
      .catch(() => {
        setValue('role');
      });

    if (id === 'new') {
      setHasRoleChangeAccess(true);
    }
    if (loggedInUserRole >= role[0]?.id) {
      setHasRoleChangeAccess(true);
    }
  }, [currentUserRole]);
  useEffect(() => {
    register({ name: 'profileImg' });
    register(
      { name: 'phoneNumber' },
      { required: true, minLength: 10, validate: (value) => value.trim() !== '' },
    );
    register({ name: 'contact.zip' }, { minLength: 5 });
    register({ name: 'contact.fax' }, { minLength: 10 });
    register({ name: 'contact.officeNumber' }, { minLength: 10 });
    register({ name: 'contact.extension' }, { minLength: 4 });
  }, [register]);

  const fetchLoginUserBusiness = useCallback(() => {
    if (loginUser) {
      baseUrl
        .get(`business/list?user=${loginUser}&deleted=false&page=0&offset=10000`)
        .then((res) => {
          if (res.status === 200) {
            const copybusiness = [];
            res.data.list.map((data) => copybusiness.push({ label: data.name, value: data.id }));
            setBusiness(copybusiness);
          }
        })
        .catch((err) => {
          dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        });
    }
  }, [loginUser]);

  useEffect(() => {
    fetchLoginUserBusiness();
  }, [fetchLoginUserBusiness]);

  const fetchUserBusiness = useCallback(() => {
    if (empId && locationList) {
      baseUrl
        .get(`business/list?user=${empId}&deleted=false&page=0&offset=1000`)
        .then((res) => {
          if (res.status === 200) {
            const selectedBusiness = res.data.list.reduce((acc, el) => {
              acc.push(el.id);
              return acc;
            }, []);

            // setValue('business', state.selectedBusiness);
            setValue('selectMultipleBusiness', selectedBusiness);
            // setMultipleBusiness([state.selectedBusiness]);
          }
        })
        .catch((err) => {
          dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        });
    }
  }, [empId, locationList]);

  const fetchLoginUserLocations = useCallback(() => {
    if (loginUser && watchFields) {
      const copylocations = [];
      watchFields.forEach((el) => {
        baseUrl
          .get(`location/list?&user=${loginUser}&businessId=${el}&page=0&offset=100`)
          .then((res) => {
            if (res.status === 200) {
              res.data.list.map((data) =>
                copylocations.push({ label: data.nickName, value: data.id }),
              );
              setLocationList(copylocations.sort(dynamicSort('label')));
            }
          })
          .catch((err) => {
            dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
          });
      });
    }
  }, [loginUser, watchFields]);

  const fetchUserLocations = useCallback(() => {
    if (loginUser && watchFields && empId) {
      const locationContainer = [];
      watchFields.forEach((el) => {
        baseUrl
          .get(`location/list?&user=${empId}&businessId=${el}&page=0&offset=100`)
          .then((res) => {
            if (res.status === 200) {
              res.data.list.reduce((acc, el1) => {
                acc.push(el1.id);
                return acc;
              }, locationContainer);
              setValue('location', locationContainer);
            }
          })
          .catch((err) => {
            dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
          });
      });
    }
  }, [watchFields, empId]);

  const fetchCampaignList = useCallback(async () => {
    setTimeout(async () => {
      if (watchLocationField && watchLocationField !== undefined) {
        try {
          const promises = watchLocationField.map((element) =>
            baseUrl.get(`location/${element}/campaign?isDeleted=false&isActive=true&limit=10000`),
          );
          const results = await Axios.all(promises);
          const container = [];
          results.map((el) => {
            container.push(el.data);
          });
          setCampaignList(
            ConvertInDropDownFormat(convertIntoArrayOfObj(container)).sort(dynamicSort('label')),
          );
          setIsLoading(false);
        } catch (err) {
          dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        }
      }
    }, 500);
  }, [watchLocationField]);

  const fetchUserCampaignList = useCallback(async () => {
    if (campaignList.length && id !== 'new') {
      try {
        const results = await baseUrl.get(
          `user/${id}/campaign?isDeleted=false&isActive=true&limit=10000`,
        );
        setValue('campaign', getMultiSelectSelectedRecords(results.data, campaignList));
      } catch (err) {
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      }
    }
  }, [campaignList, id]);

  useEffect(() => {
    fetchUserCampaignList();
    setValue('campaign', []);
  }, [fetchUserCampaignList]);

  useEffect(() => {
    fetchCampaignList();
  }, [fetchCampaignList]);

  useEffect(() => {
    if (id !== 'new') {
      setImagesContainer({});
      setIsLoading(true);
      setPasswordView(false);
      reset();
      baseUrl
        .get(`/user/${id}`)
        .then((res) => {
          setCurrentUserRole(res.data.role[0].id);
          localStorage.setItem('UserName', res.data.firstName);
          if (res.status === 200) {
            let imagesContainerCopy = { ...imagesContainer };
            // const locationArray = [];
            fetchLoginUserBusiness();
            fetchUserBusiness();

            setRenderView(true);
            // res.data.location.forEach((element) => {
            //   locationArray.push(element.id);
            // });

            // setValue('location', locationArray);

            if (res.data.uploadedFile.length > 0) {
              imagesContainerCopy = {
                url: res.data.uploadedFile[0].url || '',
                id: res.data.uploadedFile[0].id || '',
              };
              setImagesContainer(imagesContainerCopy);
            }

            // res.data.business.forEach((element) => {
            //   businessArray.push(element.id);
            // });

            let contactDetails = { ...res.data.contact };
            contactDetails = {
              ...contactDetails,
              fax: contactDetails.fax ? contactDetails.fax.substring(2) : '',
              officeNumber: contactDetails.officeNumber
                ? contactDetails.officeNumber.substring(2)
                : '',
              extension: contactDetails.extension ? contactDetails.extension : '',
            };
            setuserData(res.data);
            setValue('contact', contactDetails);
            setContact(res.data.business[0] !== undefined ? res.data.business[0].contact : '');
            setValue('firstName', res.data.firstName);
            setValue('lastName', res.data.lastName);
            setValue('phoneNumber', res.data.phoneNumber ? res.data.phoneNumber.substring(2) : '');
            setValue('role', res.data.role.length > 0 ? res.data.role[0].id : '');
            setValue('emailAddress', res.data.emailAddress);
            setValue('campaign', getMultiSelectSelectedRecords(res.data.campaign, campaignList));
            setValue(
              'notifications.profileNotification',
              // eslint-disable-next-line no-nested-ternary
              res.data.notifications
                ? res.data.notifications.profileNotification !== undefined
                  ? res.data.notifications.profileNotification
                  : false
                : false,
            );
            setValue(
              'notifications.reviewNotification',
              // eslint-disable-next-line no-nested-ternary
              res.data.notifications
                ? res.data.notifications.reviewNotification !== undefined
                  ? res.data.notifications.reviewNotification
                  : false
                : false,
            );
            setValue(
              'notifications.ListingsSynced',
              res.data.notifications ? res.data.notifications.ListingsSynced : false,
            );
            setValue(
              'notifications.listingsInsights',
              res.data.notifications ? res.data.notifications.listingsInsights : false,
            );
            setValue(
              'notifications.reviewResponse',
              // eslint-disable-next-line no-nested-ternary
              res.data.notifications
                ? res.data.notifications.reviewResponse !== undefined
                  ? res.data.notifications.reviewResponse
                  : false
                : false,
            );
            setValue(
              'notifications.customerTracking',
              // eslint-disable-next-line no-nested-ternary
              res.data.notifications
                ? res.data.notifications.customerTracking !== undefined
                  ? res.data.notifications.customerTracking
                  : false
                : false,
            );
            setValue(
              'notifications.reviewMetricsSummary',
              res.data.notifications ? res.data.notifications.reviewMetricsSummary : null,
            );
            setValue(
              'notifications.rankingsReportEmail',
              res.data.notifications ? res.data.notifications.rankingsReportEmail : null,
            );
            setValue('accountType', res.data.accountType);
            if (loginUser === id) {
              setRoleDisabled(true);
            }
          } else {
            setRenderView(true);
          }
        })
        .catch((err) => {
          dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        });
    } else if (state) {
      setPasswordView(true);
      const copyContact = state.businesses[0];
      if (copyContact) {
        setContact({ ...contact, webSite: copyContact.contact.webSite });
      }
      if (state.selectedBusiness !== '') {
        setValue('selectMultipleBusiness', [state.selectedBusiness]);
      }
      if (state.selectedLoaction !== '') {
        setValue('location', [state.selectedLoaction]);
      }
      setRenderView(true);
    }
  }, [id, employeeToEdit]);

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  useEffect(() => {
    if (watchFields.length > 0 || id === 'new') {
      fetchLoginUserLocations();
      fetchUserLocations();
      setlocationDisabled(false);
    } else {
      setlocationDisabled(true);
      setValue('location', []);
      setLocationList([]);
    }
  }, [watchFields]);

  const recoveryEmployeePassword = () => {
    setLoader(true);
    baseUrlLogin
      .post('/account/password-recovery', { ...userdata, currentUserRole })
      .then((res) => {
        if (res.status === 200) {
          setLoader(false);
          setAlert({
            show: true,
            message: `The reactivation link was sent succesfully to: ${userdata.emailAddress}`,
            messageType: 'success',
          });
        }
      })
      .catch((err) => {
        setLoader(false);
        setAlert({
          show: true,
          messageType: 'error',
          message: errorChecker(err),
        });
      });
  };

  const onSubmit = (userData) => {
    setIsSaving(true);
    //setIsLoading(true);

    if (hasRoleChangeAccess || isSuperAdmin) {
      if (!emailError) {
        if (userData.empPassword !== userData.confirmPassword) {
          setError('empPassword', {
            type: 'manual',
            types: '',
            message: 'Password and confirm password must be same',
          });
          setError('confirmPassword', {
            type: 'manual',
            types: '',
            message: 'Password and confirm password must be same',
          });
        } else if (userData.selectMultipleBusiness.length > 0 && userData.location.length > 0) {
          setLoader(true);
          const userContact = {
            fullName: `${userData.firstName} ${userData.lastName}`,
            phoneNumber: `+1${userData.phoneNumber}`,
            email: userData.emailAddress,
            fax: userData.contact.fax ? `+1${userData.contact.fax}` : '',
            officeNumber: userData.contact.officeNumber ? `+1${userData.contact.officeNumber}` : '',
          };
          const campaign =
            userData.campaign === undefined || userData.campaign === ''
              ? []
              : getValueFromArray(userData.campaign, 'value');
          let body = {
            firstName: userData.firstName,
            lastName: userData.lastName,
            emailAddress: userData.emailAddress,
            phoneNumber: `+1${userData.phoneNumber}`,
            createdBy: parseInt(userId, 10),
            role: [userData.role],
            contact: Object.assign(userData.contact, userContact),
            notifications: userData.notifications,
            location: userData.location,
            business: userData.selectMultipleBusiness,
            // eslint-disable-next-line no-prototype-builtins
            accountType:
              userData && userData.accountType !== undefined && userData.accountType
                ? userData.accountType
                : 'regular',
            // eslint-disable-next-line no-prototype-builtins
            trialExpiresAt:
              userData &&
              userData.accountType !== undefined &&
              userData.accountType &&
              userData.accountType === 'trial'
                ? new Date(getDateAfterDays(30)).getTime()
                : 0,
          };
          if (!roles.isSuperAdmin) {
            delete body.trialExpiresAt;
            delete body.accountType;
          }
          const uploadedFile = imagesContainer.id !== undefined ? [imagesContainer.id] : [];
          // if (userData.campaign) {
          //   body = {
          //     ...body,
          //     campaign: id ? getValueFromArray(userData.campaign, 'value') : campaign,
          //   };
          // }

          const patchBody = {
            ...body,
            uploadedFile,
          };
          const postBody = {
            ...patchBody,
            id: userdata.id,
            password: userData.empPassword,
            confirmPassword: userData.confirmPassword,
            campaign: [],
          };

          const locationNames = userData.location
            .map((userLocation) => {
              const location = locationList.find((loc) => loc.value === userLocation);
              return location ? location.label : null;
            })
            .filter((name) => name !== null);

          const newOrUpdatedEmployee = {
            accountType:
              userData && userData.accountType !== undefined && userData.accountType
                ? userData.accountType
                : 'Regular',
            email: userData.emailAddress,
            firstName: userData.firstName,
            id: userdata.id,
            isDeleted: 0,
            lastName: userData.lastName,
            linkSent: 0,
            location_names: locationNames,
            name: {
              name: `${userData.firstName} ${userData.lastName}`,
              url: imagesContainer.url,
            },
            phone: convertIntoPhoneFormat(`+1${userData.phoneNumber}`),
            reviews: 0,
            roles: roleList.find((role) => role.value === userData.role)?.label,
          };

          if (id !== 'new') {
            baseUrl
              .patch(`user/${userdata.id}`, { ...patchBody })
              .then((res) => {
                if (res.status === 200) {
                  fetchUserData();
                  setAlert({
                    show: true,
                    message: 'User Updated Successfully',
                    messageType: 'success',
                  });

                  setTimeout(() => {
                    setIsSaving(false);
                    setLoader(false);
                    handleCloseAlert();
                    handleClose(newOrUpdatedEmployee); //history.push('/app/setup/users');
                  }, 1000);
                }
              })
              .catch((err) => {
                setLoader(false);
                setAlert({
                  show: true,
                  messageType: 'error',
                  message: errorChecker(err),
                });
              });
          } else {
            baseUrl
              .post(`/user/create-new-user`, { ...postBody })
              .then((res) => {
                if (res.status === 200) {
                  fetchUserData();
                  setAlert({
                    show: true,
                    messageType: 'success',
                    message:
                      isBusinessAdmin || isSuperAdmin
                        ? 'Invitation link sent successfully'
                        : 'Invitation link sent successfully',
                  });
                  baseUrl
                    .patch(`user/${res.data.id}`, {
                      uploadedFile,
                    })
                    .then((result) => {
                      if (result.status === 200) {
                        setTimeout(() => {
                          setLoader(false);
                          history.push('/app/setup/users');
                        }, 1000);
                      }
                    })
                    .catch((err) => {
                      setLoader(false);
                      setAlert({
                        show: true,
                        messageType: 'error',
                        message: errorChecker(err),
                      });
                    });
                }
                setIsLoading(false);
                handleClose();
              })
              .catch((err) => {
                setLoader(false);
                setAlert({
                  show: true,
                  message: errorChecker(err),
                  messageType: 'error',
                });
              });
          }
        } else {
          if (!userData.selectMultipleBusiness.length) {
            setError('selectMultipleBusiness', {
              type: 'required',
              message: 'Business is a required field',
            });
          }
          if (!userData.location.length) {
            setError('location', {
              type: 'required',
              message: 'Location is a required field',
            });
          }
        }
      } else if (emailError) {
        // if (phoneError) {
        //   setError('phoneNumber', {
        //     type: 'exists',
        //     message: 'Phone Number already taken',
        //   });
        // }

        setError('emailAddress', {
          type: 'exists',
          message: 'Email address already taken',
        });
      }
    } else {
      setAlert({
        show: true,
        message: 'You are not authorized for this action',
        messageType: 'error',
      });
    }
  };

  const handleImagesContainer = (name, data) => {
    let imagesContainerCopy = { ...imagesContainer };
    imagesContainerCopy = { url: data.url, id: data.id };
    setImagesContainer(imagesContainerCopy);
  };

  const handleCloseAlert = () => {
    setAlert({
      show: false,
      messageType: 'error',
    });
  };

  const handlePhoneChange = (values) => {
    setuserData({ ...userdata, phoneNumber: values.value });
    setValue('phoneNumber', values.value);
  };

  const handleEmailChange = (e) => {
    setuserData({ ...userdata, emailAddress: e.target.value });
  };

  const validateEmail = (e) => {
    setValue('emailAddress', e.target.value.trim());
    if (emailRegEx.test(watchEmailAddress)) {
      setDisableBtn(true);
      setEmailLoader(true);
      baseUrl(`/user?emailAddress=${watchEmailAddress}`)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.length > 0 && res.data[0].id !== parseInt(id, 10)) {
              setDisableBtn(false);
              setEmailLoader(false);
              setEmailError(true);
              setError('emailAddress', {
                type: 'exists',
                message: 'Email address already taken',
              });
            } else {
              setEmailError(false);
              setEmailLoader(false);
              clearError('emailAddress');
              setDisableBtn(false);
            }
          }
        })
        .catch((err) => {
          setDisableBtn(false);
          setEmailLoader(false);
          dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        });
    } else {
      clearError('emailAddress');
      setDisableBtn(false);
    }
  };

  // const validatePhone = () => {
  //   if (watchContactField && watchContactField.length === 10) {
  //     const phoneNo = encodeURIComponent(`+1${watchContactField}`);
  //     setDisableBtn(true);
  //     setPhoneLoader(true);
  //     baseUrl(`/user?phoneNumber=${phoneNo}`)
  //       .then((res) => {
  //         if (res.status === 200) {
  //           if (res.data.length > 0 && res.data[0].id !== parseInt(id, 10)) {
  //             setDisableBtn(false);
  //             setPhoneError(true);
  //             setPhoneLoader(false);
  //             setError('phoneNumber', {
  //               type: 'exists',
  //               message: 'Phone Number already taken',
  //             });
  //           } else {
  //             setDisableBtn(false);
  //             setPhoneLoader(false);
  //             clearError('phoneNumber');
  //             setPhoneError(false);
  //           }
  //         }
  //       })
  //       .catch((err) => {
  //         setDisableBtn(false);
  //         setPhoneLoader(false);
  //         dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
  //       });
  //   } else {
  //     clearError('phoneNumber');
  //     setDisableBtn(false);
  //   }
  // };

  const handleDeleteProfileImage = () => {
    setValue('profileImg', '');
    setImagesContainer({});
  };

  const emailLoaderContent = emailLoader ? (
    <InputAdornment position="start">
      <ButtonLoader />
    </InputAdornment>
  ) : null;

  // const phoneLoaderContent = phoneLoader ? (
  //   <InputAdornment position="start">
  //     <ButtonLoader />
  //   </InputAdornment>
  // ) : null;

  const handleCampaignChange = (selectedOptions) => {
    //setValue('campaign', selectedOptions);
    console.log(selectedOptions.target.value);
  };

  const showLoader = loader ? <ButtonLoader /> : null;
  const watchOfficeNumber = watch('contact.officeNumber');
  const watchPhoneNumber = watch('phoneNumber');

  const imgDeleteBtn = Object.keys(imagesContainer).length ? (
    <ThemeProvider theme={TransperentButtonTheme}>
      <CustomButton onClick={handleDeleteProfileImage}>
        <StyledTrashIcon />
      </CustomButton>
    </ThemeProvider>
  ) : null;

  const userNameContainer =
    userdata.firstName &&
    userdata.lastName &&
    userdata.lastName !== undefined &&
    userdata.firstName !== undefined &&
    userdata.firstName.trim().length &&
    userdata.lastName.trim().length
      ? `${userdata.firstName} ${userdata.lastName}`
      : 'Employee';

  const plan = isSuperAdmin ? (
    <Grid item xs={12} sm={12} md={12} lg={12} style={{ margin: '.5em' }}>
      <Grid container alignItems="center">
        <Grid item xs={8} sm={8} md={8} lg={8}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ margin: '.25em' }}>
              <ThemeProvider theme={{ color: textColors.impTextColor }}>
                <StyledImpText item>Select a plan </StyledImpText>
              </ThemeProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <ThemeProvider theme={{ color: textColors.descriptionTextColor }}>
                <StyledDescriptionText item>
                  Please select a plan to use this portal
                </StyledDescriptionText>
              </ThemeProvider>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4}>
          <ThemeProvider theme={notifiyDropDownTheme}>
            <Controller
              as={
                <Dropdown
                  listOptions={[
                    { value: 'trial', label: 'Trial' },
                    { value: 'regular', label: 'Regular' },
                    { value: 'reseller', label: 'Reseller' },
                  ]}
                  label="Select Options"
                  disableUnderline
                />
              }
              name="accountType"
              control={control}
            />
          </ThemeProvider>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    ''
  );
  return renderView ? (
    <GenricPopup
      open={show}
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      {isLoading ? <ContainerLoader hideContent={true} /> : null}
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <StyledDashboard container direction="column">
          <FormHeader>{id === 'new' ? 'New' : 'Edit'} User</FormHeader>
          <CusotmAlert
            open={showAlert.show}
            message={showAlert.message}
            messageType={showAlert.messageType}
            handleClose={handleCloseAlert}
          />
          <MUIThemeProvider theme={inputTheme}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3} lg={3} sm={12} xl={3}>
                <AvatarUploader
                  name="profileImg"
                  setValue={setValue}
                  value={imagesContainer.url}
                  handleImagesContainer={handleImagesContainer}
                />
                <div style={{ width: '40px' }}>{imgDeleteBtn}</div>
              </Grid>
              <Grid item xs={12} md={9} lg={9} sm={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <StyledTextField
                      type="input"
                      name="firstName"
                      onBlur={(e) => setValue('firstName', e.target.value.trim())}
                      label="First Name *"
                      onChange={(event) => {
                        setuserData({
                          ...userdata,
                          firstName: event.target.value,
                        });
                      }}
                      inputRef={register({
                        required: true,
                        validate: (value) => value.trim() !== '',
                      })}
                      defaultValue={userdata.firstName}
                      error={!!errors.firstName}
                      helperText={errors.firstName ? 'first name  is a required field' : ''}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <StyledTextField
                      name="lastName"
                      label="Last Name *"
                      onBlur={(e) => setValue('lastName', e.target.value.trim())}
                      inputRef={register({
                        required: true,
                        validate: (value) => value.trim() !== '',
                      })}
                      defaultValue={userdata.lastName}
                      error={!!errors.lastName}
                      onChange={(e) => setuserData({ ...userdata, lastName: e.target.value })}
                      helperText={errors.lastName ? 'Last name  is a required field' : ''}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <StyledTextField
                      name="emailAddress"
                      label="Login Email *"
                      defaultValue={userdata.email}
                      disabled={isDisabled}
                      inputRef={register({
                        required: true,
                        pattern: emailRegEx,
                        validate: (value) => value.trim() !== '',
                      })}
                      onChange={handleEmailChange}
                      error={!!errors.emailAddress}
                      helperText={errors.emailAddress ? handleEmailError(errors.emailAddress) : ''}
                      onBlur={validateEmail}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        endAdornment: emailLoaderContent,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <StyledTextField
                      name="contact.communicationEmail"
                      label="Communication Email *"
                      // defaultValue={userdata.contact.communicationEmail}
                      disabled={isDisabled}
                      inputRef={register({
                        required: true,
                        pattern: emailRegEx,
                        // validate: (value) => value.trim() !== '',
                      })}
                      error={
                        handleLocationViewErrors(errors, 'contact')
                          ? errors.contact.communicationEmail
                          : false
                      }
                      InputLabelProps={{ shrink: true }}
                      helperText={
                        handleLocationViewErrors(errors, 'contact') &&
                        errors.contact.communicationEmail
                          ? 'Communication Email  is a required field'
                          : ''
                      }
                      // onChange={handleEmailChange}
                      // error={!!errors.emailAddress}
                      // helperText={errors.emailAddress ? handleEmailError(errors.emailAddress) : ''}
                      // onBlur={validateEmail}
                      // InputProps={{
                      //   endAdornment: emailLoaderContent,
                      // }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <NumberField
                      name="phoneNumber"
                      label="Phone Number *"
                      format="+1 ### ### ####"
                      onChange={(values) => handlePhoneChange(values)}
                      InputLabelProps={{ shrink: true }}
                      error={
                        handleLocationViewErrors(errors, 'phoneNumber') ? errors.phoneNumber : false
                      }
                      helperText={
                        handleLocationViewErrors(errors, 'phoneNumber') && errors.phoneNumber
                          ? handlePhoneError(errors.phoneNumber, 'Contact Mobile')
                          : ''
                      }
                      // onBlur={validatePhone}
                      defaultValue={watchContactField && watchContactField ? watchContactField : ''}
                      value={watchContactField && watchContactField ? watchContactField : ''}
                    />
                  </Grid>
                  {isBusinessAdmin || isSuperAdmin ? (
                    <Grid item xs={12} sm={6} md={6} style={{ marginTop: '18px' }}>
                      <ThemeProvider theme={dropDownTheme}>
                        <Controller
                          as={<Dropdown listOptions={Array.from(roleList)} label="Select Role *" />}
                          name="role"
                          control={control}
                          rules={{ required: true }}
                          defaultValue=""
                          disabled={!hasRoleChangeAccess}
                        />
                        {errors.role && <ErrorTxt>Role is a required field</ErrorTxt>}
                      </ThemeProvider>
                    </Grid>
                  ) : (
                    ''
                  )}
                  {passwordView ? (
                    <>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <StyledTextField
                          name="empPassword"
                          type={passwordInfo.password ? 'text' : 'password'}
                          label="Password *"
                          inputRef={register({
                            required: true,
                            pattern: passwordRegex,
                            validate: (value) => value.trim() !== '',
                          })}
                          error={!!errors.empPassword}
                          helperText={
                            errors.empPassword
                              ? handleResetPasswordError(errors.empPassword, 'Password')
                              : null
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => handlePasswordViewToggle('password')}
                                >
                                  {passwordInfo.password ? (
                                    <Visibility style={{ color: textColors.fontColor12 }} />
                                  ) : (
                                    <VisibilityOff style={{ color: textColors.fontColor3 }} />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <StyledTextField
                          name="confirmPassword"
                          type={passwordInfo.confirmPassword ? 'text' : 'password'}
                          label="Confirm Password *"
                          inputRef={register({
                            required: true,
                            pattern: passwordRegex,
                            validate: (value) => value.trim() !== '',
                          })}
                          error={!!errors.confirmPassword}
                          helperText={
                            errors.confirmPassword
                              ? handleResetPasswordError(errors.confirmPassword, 'Confirm Password')
                              : null
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => handlePasswordViewToggle('confirmPassword')}
                                >
                                  {passwordInfo.confirmPassword ? (
                                    <Visibility style={{ color: textColors.fontColor3 }} />
                                  ) : (
                                    <VisibilityOff style={{ color: textColors.fontColor3 }} />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </>
                  ) : (
                    ''
                  )}
                  <Grid item xs={12} sm={12} md={6} lg={6} style={{ display: 'none' }}>
                    <ThemeProvider theme={defaultDropDownTheme}>
                      <StyledFormControl error={!!errors.selectMultipleBusiness}>
                        <Controller
                          as={
                            <MultiSelect
                              listOptions={Array.from(business)}
                              label="Select Business *"
                              name="selectMultipleBusiness"
                              disabled={isDisabled}
                            />
                          }
                          value={watchFields || []}
                          name="selectMultipleBusiness"
                          control={control}
                          rules={{ required: true }}
                          defaultValue={[]}
                        />
                        {errors.selectMultipleBusiness ? (
                          <FormHelperText>Business is a required field</FormHelperText>
                        ) : null}
                      </StyledFormControl>
                    </ThemeProvider>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <ThemeProvider theme={defaultDropDownTheme}>
                      <StyledFormControl error={!!errors.location}>
                        <Controller
                          as={
                            <MultiSelect
                              listOptions={Array.from(locationList)}
                              label="Select location *"
                              name="locations"
                            />
                          }
                          value={watchLocationField || []}
                          name="location"
                          control={control}
                          rules={{ required: true }}
                          InputLabelProps={{ shrink: true }}
                          defaultValue={[]}
                          disabled={locationDisabled || isDisabled}
                        />
                        {errors.location ? (
                          <FormHelperText>Location is a required field</FormHelperText>
                        ) : null}
                      </StyledFormControl>
                    </ThemeProvider>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} style={{ marginTop: '7px' }}>
                    {/*<AutoCompleteWidget
                      control={control}
                      name="campaign"
                      defaultValue={watchCampaignContainer || []}
                      label="Select Campaign"
                      options={campaignList}
                      optionKey="label"
                      disabled={isDisabled}
                      selectedValues={watchCampaignContainer}
                      handleEvent={() => {}}
                    />*/}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container style={{ display: 'none' }}>
              {plan}
            </Grid>
          </MUIThemeProvider>
        </StyledDashboard>
        <div
          style={{
            display: 'flex',
            justifyContent: id === 'new' ? 'flex-end' : 'space-between',
            margin: '0 1rem 1rem 1rem',
          }}
        >
          {!isEmployee && id !== 'new' && (
            <PrimaryButton onClick={() => recoveryEmployeePassword()} disabled={loader} outlined>
              Resend Activation Email
            </PrimaryButton>
          )}
          <div style={{ display: 'flex', columnGap: '.5rem' }}>
            <PrimaryButton onClick={handleClose} text>
              Cancel
            </PrimaryButton>
            <PrimaryButton
              disabled={loader || disabledBtn || emailLoader}
              type="submit"
              id="submit"
            >
              Save {showLoader}
            </PrimaryButton>
          </div>
        </div>
      </form>
      {isSaving ? <ContainerLoader /> : null}
    </GenricPopup>
  ) : (
    <Loader />
  );
}

export default EditUserPopup;
