import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import WelcomeCard from '../components/ReviewRequestPages/WelcomeCard';
import ThankYouCard from '../components/ReviewRequestPages/ThankYouCard';
import ConfirmationDialog from '../components/ConfirmationDialog/ConfirmationDialog';
import baseUrlLogin from '../utils/services/auth.config';
import { getParameterByName } from '../utils/helper/helper';
import Loader from '../components/Loader/Loader';
import SocialSites from '../components/ReviewRequestPages/SocialSites';
import PositiveFeedback from '../components/ReviewRequestPages/PositiveFeedback';
import NegativeFeedback from '../components/ReviewRequestPages/NegativeFeedback';
import FirstCard from '../components/SurveyReview/FirstCard';
import RatingCard from '../components/SurveyReview/RatingCard';
import SentimentCard from '../components/SurveyReview/SentimentCard';
import SingleActionCard from '../components/NotificationReviewPages/SingleAction';
import NoReplyCard from '../components/NotificationReviewPages/NoReplyCard';
import SurveyCard from '../components/PropertyManagementSurvey/SurveyCard';
import SurveyComplete from '../components/PropertyManagementSurvey/SurveyComplete';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: '80%',
    maxHeight: 435,
  },
}));
const StyledPNFGrid = styled(Grid)`
  height: 100%;
  font-size: 36px;
  color: #37423e;
  font-weight: 200;
  letter-spacing: 1.08px;
  background: #fff;
  overflow: scroll;
`;

function ReviewResponse() {
  const classes = useStyles();
  const [showExperienceReviewCard, setExperienceReviewCard] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [showGuidedCard, setShowGuidedCard] = useState(false);
  const [showPositiveFeedbackCard, setshowPositiveFeedbackCard] = useState(false);
  const [showWelcomeCard, setshowWelcomeCard] = useState(false);
  const [allowTextFeedback, setallowTextFeedback] = useState(false);
  const [reviewResponseData, setReviewResponseData] = useState({});
  const [firstSocialSites, setfirstSocialSites] = useState({});
  const [socialSites, setSocialSites] = useState({});
  const [sentimentRating, setSentimentRating] = useState(0);
  const [thankYouCard, setThankYouCard] = useState({});
  const [textSurvey, setTextSurvey] = useState({});
  const [ratingSurvey, setRatingSurvey] = useState({});
  const [sentimentData, setSentimentData] = useState({});
  const [firstCard, setFirstCard] = useState(false);
  const [showRatingCard, setshowRatingCard] = useState(false);
  const [sentimentCard, setSentimentCard] = useState(false);
  const [singleAction, setSingleAction] = useState({});
  const [showSingleAction, setShowSingleAction] = useState(false);
  const [noReplyData, setNoReplyData] = useState({});
  const [showNoReplyCard, setShowNoReplyCard] = useState(false);
  const [ReviewFeedback, setrReviewFeedback] = useState({
    reviewFeedback: '',
    rating: 0,
  });
  const [postitiveFeedbackCard, setPostitiveFeedbackCard] = useState({});
  const [negativeFeedbackCard, setNegativeFeedbackCard] = useState({});
  const [showSurveyCard, setShowSurveyCard] = useState(false);
  const [showSurveyCompleteCard, setShowSurveyCompleteCard] = useState(false);
  const [surveyData, setSurveyData] = useState({});
  const [recommendCard, setRecommendCard] = useState({});
  const [responseData, setResponseData] = useState({});
  const [open, setOpen] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [code, setCode] = useState();
  const [renderView, setRenderView] = useState(false);
  const [headerImageURL, setHeaderImageURL] = useState('');
  const [socialSiteCount, setSocialSiteCount] = useState(0);
  const [userImage, setUserImage] = useState('');
  const previousCount = localStorage.getItem(`linkClicks_${code}`)
    ? parseInt(localStorage.getItem(`linkClicks_${code}`), 10)
    : 0;

  const saveCampaignAnalytics = (data, currentPage, campaignAnalyticsId = null) => {
    const id = reviewResponseData.campaignAnalyticsId
      ? reviewResponseData.campaignAnalyticsId
      : campaignAnalyticsId;
    setDisabledBtn(true);

    // const postsitesData = baseUrlLogin.get(`/postsites?isDeleted=${false}`);
    // if (postsitesData.status === 200) {
    //   console.log(postsitesData.data);
    // }

    baseUrlLogin
      .patch(`/analytics/${id}`, {
        ...data,
        response: { ...data.response, requestType: getParameterByName('type') },
      })
      .then((res) => {
        if (res.status === 200) {
          setDisabledBtn(false);
          if (currentPage === 'positiveBtn') {
            setshowPositiveFeedbackCard(true);
            setshowWelcomeCard(false);
          } else if (
            currentPage === 'firstNegativeBtn' &&
            reviewResponseData.name.toLowerCase() === 'simple'
          ) {
            setShowThankYou(true);
            setshowWelcomeCard(false);
          } else if (
            currentPage === 'googleLink' ||
            currentPage === 'facebookLink' ||
            currentPage === 'dynamicLink' ||
            currentPage === 'onMessageTextFeedback'
          ) {
            setshowPositiveFeedbackCard(false);
            setShowThankYou(true);
          } else if (currentPage === 'saveFeedback') {
            setShowThankYou(true);
            setshowPositiveFeedbackCard(false);
            setExperienceReviewCard(false);
          } else if (currentPage === 'facebookPositiveBtn') {
            setShowThankYou(true);
            setShowGuidedCard(false);
            setshowPositiveFeedbackCard(false);
          } else if (currentPage === 'simplePositiveBtn') {
            setShowThankYou(true);
            setshowWelcomeCard(false);
            setshowPositiveFeedbackCard(false);
          } else if (
            currentPage === 'firstNegativeBtn' &&
            reviewResponseData.name.toLowerCase() === 'semifiltered'
          ) {
            setExperienceReviewCard(true);
            setshowWelcomeCard(false);
            setshowPositiveFeedbackCard(false);
          } else if (currentPage === 'notification') {
            setShowThankYou(true);
            setShowNoReplyCard(false);
            setFirstCard(false);
            setshowRatingCard(false);
            setSentimentCard(false);
            setShowSingleAction(false);
          }
        }
      })
      .catch(() => {
        setDisabledBtn(false);
      });
  };

  const positiveBtnAction = (data) => {
    let currentPage = '';
    let reviewAt = '';
    if (data.screen === 'social-site') {
      window.open(data.postReviewLink, '_blank');
      reviewAt = data.type;
      currentPage = 'facebookPositiveBtn';
    } else if (data.type === 'facebook') {
      const facebookLink = 'https://www.facebook.com';
      window.open(facebookLink, '_blank');
      reviewAt = data.type;
      currentPage = 'facebookPositiveBtn';
    } else if (data.type === 'google') {
      const link = 'https://www.google.com';
      window.open(link, '_blank');
      reviewAt = data.type;
      currentPage = 'facebookPositiveBtn';
    } else if (data.type === 'positiveBtn' && reviewResponseData.name.toLowerCase() === 'simple') {
      currentPage = 'simplePositiveBtn';
    } else if (postitiveFeedbackCard.postSites.length) {
      currentPage = 'positiveBtn';
    } else {
      currentPage = 'simplePositiveBtn';
    }
    const body = {
      response: {
        ...responseData,
        linkClicks: parseInt(localStorage.getItem(`linkClicks_${code}`), 10),
        reviewCode: code,
        recommended: true,
        opened: true,
        responseType: reviewResponseData.name,
        reviewAt,
      },
    };
    saveCampaignAnalytics(body, currentPage);
  };

  useEffect(() => {
    setCode(getParameterByName('code'));
    setRenderView(false);
    if (code) {
      baseUrlLogin
        .get(`/campaign/review-request?code=${code}`)
        .then((res) => {
          if (res.status === 200) {
            setRenderView(true);
            setReviewResponseData(res.data);
            const { response } = res.data;
            setResponseData(res.data.response);
            const type = res.data.name;
            const copySocialSites = [];

            setSurveyData(res.data);
            //REMOVE THIS
            //console.log(res.data);
            if (res.data.name === 'survey complete') {
              setShowSurveyCompleteCard(true);
            } else if (res.data.name.toLowerCase().includes('survey')) {
              setShowSurveyCard(true);
            }

            setallowTextFeedback(res.data.metaData.allowTextFeedback);
            setHeaderImageURL(res.data.metaData.headerImage);
            setUserImage(res.data.settings.userImage?.url);
            //TODO This needs to be rewritten to accept type names that do not include the word survey

            let socialSitescopy = { ...socialSites };

            res.data.meta[type].screens.forEach((item) => {
              if (item.screen === 'social-site') {
                socialSitescopy = {
                  ...socialSitescopy,
                  [item.type.toLowerCase()]: item,
                };
              }
              if (item.screen === 'postitiveFeedback') setPostitiveFeedbackCard(item);
              if (item.screen === 'thankyou') setThankYouCard(item);
              if (item.screen === 'negativefeedback') setNegativeFeedbackCard(item);
              if (item.screen === 'recommend') setRecommendCard(item);
              if (item.screen === 'text-survey') setTextSurvey(item);
              if (item.screen === 'sentiment-survey') setSentimentData(item);
              if (item.screen === 'rating-survey') setRatingSurvey(item);
              if (item.screen === 'confirmation') setTextSurvey(item);
              if (item.screen === 'no-reply') setNoReplyData(item);
              if (item.screen === 'opt-in-or-out') setTextSurvey(item);
              if (item.screen === 'single-action') setSingleAction(item);
            });
            copySocialSites.push(socialSitescopy);
            setSocialSites(socialSitescopy);
            if (type === 'guided') {
              setRenderView(true);
              if (Object.keys(socialSitescopy).length) {
                setShowGuidedCard(true);
                setfirstSocialSites(socialSitescopy[Object.keys(socialSitescopy)[0]]);
              } else if (Object.keys(postitiveFeedbackCard).length) {
                setshowPositiveFeedbackCard(true);
              } else {
                setShowThankYou(true);
                setshowPositiveFeedbackCard(false);
              }
            } else if (type === 'semifiltered' || type === 'semiFiltered') {
              setRenderView(true);
              setshowWelcomeCard(true);
            } else if (type === 'simple') {
              setRenderView(true);
              setshowPositiveFeedbackCard(true);
            } else if (type === 'yesNo') {
              setFirstCard(true);
            } else if (type === 'sentiment') {
              setSentimentCard(true);
            } else if (type === 'rating') {
              setshowRatingCard(true);
            }
            if (type === 'singleAction') {
              setShowSingleAction(true);
            } else if (type === 'confirmation' || type === 'optInOrOut') {
              setFirstCard(true);
            } else if (type === 'noReply') {
              setShowNoReplyCard(true);
            }

            localStorage.setItem(`linkClicks_${code}`, previousCount + 1);
            const intialBody = {
              response: {
                ...response,
                opened: true,
                responseType: res.data.name,
                linkClicks: res.data.name === 'noReply' ? 1 : 0,
                // recommended:null
              },
            };

            if (res.data.campaignAnalyticsId) {
              saveCampaignAnalytics(intialBody, '', res.data.campaignAnalyticsId);
            }
          }
        })
        .catch(() => {
          setRenderView(true);
        });
    }
  }, [code]);

  const onClientFeedback = (e) => {
    setrReviewFeedback({
      ...ReviewFeedback,
      reviewFeedback: e,
    });
  };

  const onClientRating = (event, newValue) => {
    setrReviewFeedback({
      ...ReviewFeedback,
      rating: newValue,
    });
  };

  const navigateOnReviewCard = () => {
    const analyticsData = {
      response: {
        ...responseData,
        linkClicks: parseInt(localStorage.getItem(`linkClicks_${code}`), 10),
        reviewCode: code,
        recommended: false,
        opened: true,
        responseType: reviewResponseData.name,
      },
    };
    saveCampaignAnalytics(analyticsData, 'firstNegativeBtn');
  };

  const negativeBtnAction = () => {
    setSocialSiteCount((count) => count + 1);
  };

  useEffect(() => {
    if (Object.keys(socialSites).length) {
      if (Object.keys(socialSites).length - 1 >= socialSiteCount) {
        setfirstSocialSites(socialSites[Object.keys(socialSites)[socialSiteCount]]);
      }
      // else if (postitiveFeedbackCard.postSites && postitiveFeedbackCard.postSites.length) {
      //   setNegativeFeedbackCard(true);
      //   setshowPositiveFeedbackCard(false);
      //   setShowGuidedCard(false);
      // }
      else {
        setExperienceReviewCard(true);
        setShowThankYou(false);
        setshowPositiveFeedbackCard(false);
        setShowGuidedCard(false);
      }
    }
  }, [socialSiteCount, socialSites]);

  const onMessageTextFeedback = () => {
    setshowPositiveFeedbackCard(false);
    setShowThankYou(true);
    const analyticsData = {
      response: {
        ...responseData,
        linkClicks: parseInt(localStorage.getItem(`linkClicks_${code}`), 10),
        reviewCode: code,
        recommended: true,
        reviewAt: 'text-us-feedback',
        opened: true,
        responseType: reviewResponseData.name,
      },
    };
    saveCampaignAnalytics(analyticsData, '');
  };
  const openFacebookLink = () => {
    const facebookLink = 'https://www.facebook.com';
    window.open(facebookLink, '_blank');

    const analyticsData = {
      response: {
        ...responseData,
        linkClicks: parseInt(localStorage.getItem(`linkClicks_${code}`), 10),
        reviewCode: code,
        recommended: true,
        reviewAt: 'facebook',
        opened: true,
        responseType: reviewResponseData.name,
      },
    };
    saveCampaignAnalytics(analyticsData, 'facebookLink');
  };

  const saveExperienceReview = () => {
    const analyticsData = {
      response: {
        ...responseData,
        linkClicks: parseInt(localStorage.getItem(`linkClicks_${code}`), 10),
        feedback: ReviewFeedback.reviewFeedback,
        reviewAt: 'reveo',
        recommended: ReviewFeedback.rating > 2,
        rating: ReviewFeedback.rating,
        opened: true,
        responseType: reviewResponseData.name,
      },
    };
    saveCampaignAnalytics(analyticsData, 'saveFeedback');
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOk = () => {
    const analyticsData = {
      response: {
        ...responseData,
        linkClicks: parseInt(localStorage.getItem(`linkClicks_${code}`), 10),
        reviewCode: code,
        reviewAt: '',
        recommended: false,
        opened: true,
        responseType: reviewResponseData.name,
      },
    };
    saveCampaignAnalytics(analyticsData);
    setOpen(false);
    setExperienceReviewCard(false);
    setShowThankYou(true);
  };
  const openDynamickLink = (item) => {
    window.open(item.postReviewLink, '_blank');
    const analyticsData = {
      response: {
        ...responseData,
        linkClicks: parseInt(localStorage.getItem(`linkClicks_${code}`), 10),
        reviewCode: code,
        recommended: true,
        reviewAt: item.name,
        opened: true,
        responseType: reviewResponseData.name,
      },
    };
    saveCampaignAnalytics(analyticsData, 'dynamicLink');
  };

  const onYesNoClick = (action) => {
    const responseType = reviewResponseData.name;
    const data = {
      response: {
        ...responseData,
        responseType,
        action,
        linkClicks: parseInt(localStorage.getItem(`linkClicks_${code}`), 10),
        opened: true,
        recommended: action === 'yes',
      },
    };
    saveCampaignAnalytics(data, 'notification');
  };

  const onSendCard = () => {
    const responseType = reviewResponseData.name;
    const data = {
      response: {
        ...responseData,
        responseType,
        rating: ReviewFeedback.rating,
        feedback: ReviewFeedback.reviewFeedback,
        linkClicks: parseInt(localStorage.getItem(`linkClicks_${code}`), 10),
        opened: true,
        reviewAt: 'reveo',
        recommended: true,
      },
    };
    saveCampaignAnalytics(data, 'notification');
  };

  const ratingByFace = (value) => {
    setSentimentRating(value);
    if (value === 'send') {
      const responseType = reviewResponseData.name;
      const data = {
        response: {
          ...responseData,
          responseType,
          sentiment: sentimentRating,
          feedback: ReviewFeedback.reviewFeedback,
          linkClicks: parseInt(localStorage.getItem(`linkClicks_${code}`), 10),
          opened: true,
          reviewAt: 'reveo',
          recommended: true,
        },
      };
      saveCampaignAnalytics(data, 'notification');
    }
  };

  const confimationInput = () => {
    const responseType = reviewResponseData.name;
    const data = {
      response: {
        ...responseData,
        responseType,
        action: 'Clicked',
        linkClicks: parseInt(localStorage.getItem(`linkClicks_${code}`), 10),
        opened: true,
        reviewAt: reviewResponseData.metaData.externalLink,
        recommended: true,
      },
    };
    saveCampaignAnalytics(data, 'notification');
  };

  return renderView ? (
    <>
      <StyledPNFGrid container justifyContent="center">
        <Grid item xs={12} md={4} lg={3} sm={5}>
          {showWelcomeCard ? (
            <WelcomeCard
              negativeBtnAction={navigateOnReviewCard}
              positiveBtnAction={positiveBtnAction}
              disabledBtn={disabledBtn}
              powerImageWidth={2.5}
              recommendCard={recommendCard}
              url={headerImageURL}
            />
          ) : (
            ''
          )}
          {showGuidedCard ? (
            <SocialSites
              socialSites={firstSocialSites}
              positiveBtnAction={positiveBtnAction}
              negativeBtnAction={negativeBtnAction}
              url={headerImageURL}
              reviewResponseData={reviewResponseData}
            />
          ) : (
            ''
          )}

          {showPositiveFeedbackCard ? (
            <PositiveFeedback
              shareReviewBtn={[]}
              // openGoogleLink={openGoogleLink}
              openFacebookLink={openFacebookLink}
              postitiveFeedbackCard={postitiveFeedbackCard}
              openDynamickLink={openDynamickLink}
              onMessageTextFeedback={onMessageTextFeedback}
              allowTextFeedback={allowTextFeedback}
              reviewResponseData={reviewResponseData}
              url={headerImageURL}
              userImage={userImage}
            />
          ) : (
            ''
          )}

          {showExperienceReviewCard ? (
            <NegativeFeedback
              saveExperienceReview={saveExperienceReview}
              negativeBtnAction={negativeBtnAction}
              onClientFeedback={onClientFeedback}
              onClientRating={onClientRating}
              disabledBtn={disabledBtn}
              powerImageWidth={2.5}
              negativeFeedbackCard={negativeFeedbackCard}
              url={headerImageURL}
            />
          ) : (
            ''
          )}

          {showThankYou ? (
            <ThankYouCard powerImageWidth={2.5} thankyouCard={thankYouCard} url={headerImageURL} />
          ) : (
            ''
          )}

          {firstCard ? (
            <FirstCard
              textSurvey={textSurvey}
              onYesNoClick={onYesNoClick}
              notificationType={reviewResponseData.name}
              allowTextFeedback={allowTextFeedback}
              url={headerImageURL}
            />
          ) : (
            ''
          )}

          {showRatingCard ? (
            <RatingCard
              onClientFeedback={onClientFeedback}
              onClientRating={onClientRating}
              ratingSurvey={ratingSurvey}
              onSendCard={onSendCard}
              url={headerImageURL}
            />
          ) : (
            ''
          )}
          {sentimentCard ? (
            <SentimentCard
              onClientFeedback={onClientFeedback}
              sentimentData={sentimentData}
              //  onClientRating={onClientRating}
              ratingByFace={ratingByFace}
              url={headerImageURL}
            />
          ) : (
            ''
          )}

          {showSingleAction ? (
            <SingleActionCard
              notificationReviewData={reviewResponseData}
              singleActionData={singleAction}
              onYesClick={confimationInput}
              url={headerImageURL}
            />
          ) : (
            ''
          )}
          {showNoReplyCard ? (
            <NoReplyCard noReplyData={noReplyData} onSendCard={onSendCard} url={headerImageURL} />
          ) : (
            ''
          )}
          {showSurveyCard ? <SurveyCard data={surveyData} /> : ''}
          {showSurveyCompleteCard ? <SurveyComplete /> : ''}
        </Grid>

        <ConfirmationDialog
          classes={{
            paper: classes.paper,
          }}
          id="confirmDilog"
          keepMounted
          open={open}
          onClose={handleClose}
          handleOk={handleOk}
        />
      </StyledPNFGrid>
    </>
  ) : (
    <Loader />
  );
}

ReviewResponse.propTypes = {};

export default ReviewResponse;
