import React, { useCallback, useContext, useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  InputAdornment,
  ThemeProvider as MUIThemeProvider,
} from '@material-ui/core';
import styled, { ThemeProvider } from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import CloseIcon from '@material-ui/icons/Close';
import MainViewActionButtonTheme from '../../../themes/Button/MainViewActionButtonTheme';
import CustomButton from '../../CustomButton/CustomButton';
import StyledTextField from '../../GeneralInputField/GeneralInputField';
import NumberField from '../../NumberField/NumberField';
import {
  errorChecker,
  handleEmailError,
  handlePhoneError,
  ConvertIntoLocationDropDown,
  getMultiSelectIds,
} from '../../../utils/helper/helper';
import ButtonLoader from '../../ButtonLoader/ButtonLoader';
import baseUrl from '../../../utils/services/config';
import { emailRegEx } from '../../../utils/helper/regEx';
import { GlobalContext } from '../../../Stores/GlobalStateStore';
import TransperentButtonTheme from '../../../themes/Button/TransperentButtonTheme';
import CusotmAlert from '../../CustomAlert/CustomAlert';
import Dropdown from '../../Dropdown/Dropdown';
import defaultDropDownTheme from '../../../themes/Dropdown/DefaultDropdownTheme';
import Loader from '../../Loader/Loader';
import useAlert from '../../../hooks/useAlert';
import MultiSelect from '../../MultiSelect/MultiSelect';
import StyledChip from '../../Chip/BasicChip';
import StyledFormControl from '../../StyledFormControl';
import useIconTheme from '../../../hooks/useIconTheme';
import useInputFieldTheme from '../../../hooks/useInputFieldTheme';
import useButtonTheme from '../../../hooks/useButtonTheme';

const StyledDialogTitle = styled(DialogTitle)`
  letter-spacing: 1.08px;
  color: #6c6c6c;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 400 !important;
  & > h2 {
    letter-spacing: 1.08px;
    color: #6c6c6c;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 400 !important;
  }
`;

const StyledContentDialog = styled(DialogContent)`
  padding: 0 0.5em !important;
`;

const StyledActions = styled(DialogActions)`
  padding: 0.5 !important;
`;

const StyledCloseIcon = styled(CloseIcon)``;

function AddClientPopupForm(props) {
  const {
    handleEditModeToggle,
    contactId,
    fetchConversations,
    handleSelectedConversationInit,
    open,
  } = props;
  const [loader, setLoader] = useState(false);
  const [renderView, setRenderView] = useState(true);
  const [tel, setTel] = useState('');
  const [contactData, setContactData] = useState({});
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [disabledBtn, setDisableBtn] = useState(false);
  const [emailLoader, setEmailLoader] = useState(false);
  const [phoneLoader, setPhoneLoader] = useState(false);
  const { state, dispatch } = useContext(GlobalContext);
  const descriptionElementRef = React.useRef(null);
  const { locations, selectedBusiness, selectedLoaction } = state;
  const ContactAlert = useAlert();
  const { iconsTheme } = useIconTheme();
  const { buttonTheme } = useButtonTheme();
  const { register, handleSubmit, errors, setValue, watch, setError, clearError, control } =
    useForm({});
  const { inputTheme } = useInputFieldTheme();

  const watchAllFields = watch({ nest: true });

  const fetchClientInfo = useCallback(async () => {
    if (contactId !== null && contactId >= 0) {
      setRenderView(false);
      const result = await baseUrl.get(`/client/${contactId}`);
      if (result.status === 200) {
        setContactData(result.data);
        setRenderView(true);
        setValue('firstName', result.data.firstName);
        setValue('lastName', result.data.lastName);
        setValue('phoneNumber', result.data.phoneNumber.substring(2));
        setValue('emailAddress', result.data.emailAddress);
        setValue(
          'location',
          contactId ? result.data.location[0].id : getMultiSelectIds(result.data.location),
        );
      }
    }
  }, [contactId, setValue]);

  useEffect(() => {
    fetchClientInfo();
  }, [fetchClientInfo]);

  const onSubmit = async (data) => {
    const { handleSetMessage, handleSetMessageType, handleShow } = ContactAlert;
    const locationValidation =
      watchAllFields.location !== 'number' && !contactId
        ? watchAllFields.location && watchAllFields.location.length
        : true;
    if (contactId) {
      if (!phoneError && !emailError) {
        setLoader(true);
        setDisableBtn(true);
        const postBody = {
          firstName: data.firstName,
          lastName: data.lastName,
          phoneNumber: data.phoneNumber ?? '',
          emailAddress: data.emailAddress,
          location: data.location,
          createdBy: parseInt(localStorage.getItem('USER_ID'), 10),
        };
        const conversationPostBody = {
          current_location: selectedLoaction,
          new_location: data.location,
          client: contactId,
        };
        try {
          const result = await baseUrl.post(`/xfer-conversation`, { ...conversationPostBody });
          if (result.status === 200) {
            handleSetMessageType('success');
            handleSetMessage('Conversation transferred successfully');
            fetchConversations();
            handleShow(true);

            baseUrl
              .patch(`/client/${contactId}`, { ...postBody })
              .then((res) => {
                if (res.status === 200) {
                  setLoader(false);
                  setTimeout(() => {
                    setDisableBtn(false);
                    handleSelectedConversationInit();
                    handleEditModeToggle(res.data);
                  }, 1000);
                }
              })
              .catch((err) => {
                setLoader(false);
                setDisableBtn(false);
                dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
              });
          }
        } catch (err) {
          setLoader(false);
          setDisableBtn(false);
          dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        }
      } else {
        if (phoneError) {
          setError('phoneNumber', {
            type: 'exists',
            message: 'Phone Number already taken',
          });
        }
        if (emailError) {
          setError('emailAddress', {
            type: 'exists',
            message: 'Email address already taken',
          });
        }
      }
    } else if (locationValidation && !phoneError && !emailError) {
      setLoader(true);
      setDisableBtn(true);
      const postBody = {
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: data.phoneNumber ?? '',
        emailAddress: data.emailAddress,
        location: data.location,
        createdBy: parseInt(localStorage.getItem('USER_ID'), 10),
      };
      baseUrl
        .post(`/client`, { ...postBody })
        .then((res) => {
          if (res.status === 200) {
            setLoader(false);
            handleSetMessageType('success');
            handleSetMessage('Contact saved successfully');
            handleShow(true);
            setTimeout(() => {
              setDisableBtn(false);
              handleEditModeToggle(res.data);
            }, 1000);
          }
        })
        .catch((err) => {
          setLoader(false);
          setDisableBtn(false);
          dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        });
    } else {
      if (data.location.length <= 0) {
        setError('location', {
          type: 'required',
          message: '',
        });
      }
      if (phoneError) {
        setError('phoneNumber', {
          type: 'exists',
          message: 'Phone Number already taken',
        });
      }
      if (emailError) {
        setError('emailAddress', {
          type: 'exists',
          message: 'Email address already taken',
        });
      }
    }
  };

  const validateEmail = () => {
    if (emailRegEx.test(watchAllFields.emailAddress)) {
      setDisableBtn(true);
      setEmailLoader(true);
      baseUrl(
        `/client-exist?business=${selectedBusiness}&field=emailAddress&value=${watchAllFields.emailAddress}`,
      )
        .then((res) => {
          if (res.status === 200) {
            if (res.data.length > 0 && res.data[0].id) {
              setDisableBtn(false);
              setEmailError(true);
              setEmailLoader(false);
              setError('emailAddress', {
                type: 'exists',
                message: 'Email address already taken',
              });
            } else {
              setEmailError(false);
              setEmailLoader(false);
              clearError('emailAddress');
              setDisableBtn(false);
            }
          }
        })
        .catch((err) => {
          setDisableBtn(false);
          setEmailLoader(false);
          dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        });
    } else {
      clearError('emailAddress');
      setDisableBtn(false);
    }
  };

  const validatePhone = () => {
    if (watchAllFields.phoneNumber && watchAllFields.phoneNumber.length === 10) {
      const phoneNo = encodeURIComponent(`+1${watchAllFields.phoneNumber}`);
      setDisableBtn(true);
      setPhoneLoader(true);
      baseUrl(`/client-exist?business=${selectedBusiness}&field=phoneNumber&value=${phoneNo}`)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.length > 0 && res.data[0].id) {
              setDisableBtn(false);
              setPhoneError(true);
              setPhoneLoader(false);
              setError('phoneNumber', {
                type: 'exists',
                message: 'Phone Number already taken',
              });
            } else {
              setDisableBtn(false);
              clearError('phoneNumber');
              setPhoneError(false);
              setPhoneLoader(false);
            }
          }
        })
        .catch((err) => {
          setDisableBtn(false);
          setPhoneLoader(false);
          dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        });
    } else {
      clearError('phoneNumber');
      setDisableBtn(false);
    }
  };

  const isPhoneRequired = useCallback(() => {
    if (watchAllFields.emailAddress) {
      return false;
    }
    return true;
  }, [watchAllFields.emailAddress]);

  useEffect(() => {
    register({ name: 'phoneNumber' }, { required: isPhoneRequired(), minLength: 10 });
    setValue('phoneNumber', tel);
  }, [register, isPhoneRequired, setValue, tel]);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const unsubscribed =
    contactData && (contactData.unsubscribeEmail || contactData.unsubscribeMobile);

  const endAdornmentContainer = unsubscribed ? (
    <InputAdornment position="start">
      <ThemeProvider theme={{ color: iconsTheme.badgeColor, bgColor: iconsTheme.badgebgColor }}>
        <StyledChip label="Unsubscribed" />
      </ThemeProvider>
    </InputAdornment>
  ) : null;

  const phoneLoaderContent = phoneLoader ? (
    <InputAdornment position="start">
      <ButtonLoader />
    </InputAdornment>
  ) : (
    endAdornmentContainer
  );

  const emailLoaderContent = emailLoader ? (
    <InputAdornment position="start">
      <ButtonLoader />
    </InputAdornment>
  ) : null;

  const showLoader = loader ? <ButtonLoader /> : null;

  const locationSelectionContent =
    typeof watchAllFields.location !== 'number' && !contactId ? (
      <ThemeProvider theme={defaultDropDownTheme}>
        <StyledFormControl error={!!errors.location}>
          <Controller
            as={
              <MultiSelect
                listOptions={Array.from(ConvertIntoLocationDropDown(locations))}
                label="Select Location *"
              />
            }
            value={watchAllFields.location ? watchAllFields.location : []}
            name="location"
            control={control}
            rules={{ required: true }}
            defaultValue={[]}
          />
          {errors.location ? <FormHelperText>Location is a required field</FormHelperText> : null}
        </StyledFormControl>
      </ThemeProvider>
    ) : (
      <ThemeProvider theme={defaultDropDownTheme}>
        <StyledFormControl error={!!errors.location}>
          <Controller
            as={
              <Dropdown
                listOptions={Array.from(ConvertIntoLocationDropDown(locations))}
                label="Location *"
              />
            }
            name="location"
            control={control}
            rules={{ required: true }}
            defaultValue=""
          />
          {errors.location ? <FormHelperText>Location is a required field</FormHelperText> : null}
        </StyledFormControl>
      </ThemeProvider>
    );

  // const locationSelectionContent = contactId ? (

  // ) : (

  // );

  const formContent = renderView ? (
    <MUIThemeProvider theme={inputTheme}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledContentDialog id="scroll-dialog-description" ref={descriptionElementRef}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container justify="center">
                <Grid item xs={12} sm={10} md={10} lg={10}>
                  <StyledTextField
                    name="firstName"
                    label="First Name *"
                    inputRef={register({ required: true })}
                    error={!!errors.firstName}
                    helperText={errors.firstName ? 'First Name is a required field' : ''}
                  />
                </Grid>
              </Grid>
              <Grid container justify="center">
                <Grid item xs={12} sm={10} md={10} lg={10}>
                  <StyledTextField
                    name="lastName"
                    label="Last Name"
                    inputRef={register}
                    defaultValue=""
                  />
                </Grid>
              </Grid>
              <Grid container justify="center">
                <Grid item xs={12} sm={10} md={10} lg={10}>
                  <NumberField
                    name="phoneNumber"
                    format="+1 ### ### ####"
                    label={isPhoneRequired() ? 'Contact Mobile *' : 'Contact Mobile '}
                    onChange={(values) => {
                      setValue('phoneNumber', values.value);
                      clearError('phoneNumber');
                      setTel(values.value);
                    }}
                    error={!!errors.phoneNumber}
                    helperText={
                      errors.phoneNumber
                        ? handlePhoneError(errors.phoneNumber, 'Contact Mobile')
                        : ''
                    }
                    defaultValue=""
                    value={watchAllFields.phoneNumber}
                    onBlur={validatePhone}
                    InputProps={{
                      endAdornment: phoneLoaderContent,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container justify="center">
                <Grid item xs={12} sm={10} md={10} lg={10}>
                  <StyledTextField
                    name="emailAddress"
                    label={!watchAllFields.phoneNumber ? 'Email Address *' : 'Email Address'}
                    onChange={(e) => {
                      setValue('emailAddress', e.target.value);
                      clearError('emailAddress');
                    }}
                    inputRef={register({
                      required: !watchAllFields.phoneNumber,
                      pattern: emailRegEx,
                    })}
                    onBlur={validateEmail}
                    error={!!errors.emailAddress}
                    helperText={errors.emailAddress ? handleEmailError(errors.emailAddress) : ''}
                    InputProps={{
                      endAdornment: emailLoaderContent,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container justify="center">
                <Grid item xs={12} sm={10} md={10} lg={10}>
                  {locationSelectionContent}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </StyledContentDialog>
        <StyledActions>
          <Grid container justify="center">
            <Grid item xs={12} sm={12} md={3} lg={4}>
              <ThemeProvider
                theme={{
                  ...MainViewActionButtonTheme,
                  background: buttonTheme.buttonBgColor,
                  color: buttonTheme.fontColor3,
                }}
              >
                <CustomButton type="submit" disabled={disabledBtn || phoneLoader || emailLoader}>
                  Save
                  {showLoader}
                </CustomButton>
              </ThemeProvider>
            </Grid>
          </Grid>
        </StyledActions>
      </form>
    </MUIThemeProvider>
  ) : (
    <Loader />
  );

  return (
    <>
      <CusotmAlert
        message={ContactAlert.message}
        handleClose={ContactAlert.handleClose}
        open={ContactAlert.show}
        messageType={ContactAlert.messageType}
      />

      <StyledDialogTitle id="scroll-dialog-title">
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={10} md={10} sm={10} lg={10} />
          <Grid item xs={2} md={2} sm={2} lg={2}>
            <ThemeProvider theme={TransperentButtonTheme}>
              <CustomButton onClick={() => handleEditModeToggle(null)}>
                <ThemeProvider theme={{ color: iconsTheme.clearIcon }}>
                  <StyledCloseIcon />
                </ThemeProvider>
              </CustomButton>
            </ThemeProvider>
          </Grid>
        </Grid>
      </StyledDialogTitle>
      {formContent}
    </>
  );
}

AddClientPopupForm.propTypes = {
  handleEditModeToggle: Proptypes.func.isRequired,
  contactId: Proptypes.number,
  fetchConversations: Proptypes.func,
  handleSelectedConversationInit: Proptypes.func,
  open: Proptypes.bool,
};

AddClientPopupForm.defaultProps = {
  contactId: null,
  fetchConversations: () => {},
  handleSelectedConversationInit: () => {},
  open: false,
};

export default React.memo(AddClientPopupForm);
