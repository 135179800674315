import { Box, Grid } from '@material-ui/core';
import { format } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import CusotmAlert from '../../components/CustomAlert/CustomAlert';

import CustomButton from '../../components/CustomButton/CustomButton';
import StyledEditIcon from '../../components/Icons/EditIcon';
import StyledTrashIcon from '../../components/Icons/TrashIcon';
import PageTitle from '../../components/PageTitle/PageTitle';
import SurveyBuilderPopover from '../../components/Popover/SurveyBuilderPopover/SurveyBuilderPopover';
import CancellationPopup from '../../components/Popup/CancellationPopup/CancellationPopup';
import StyledTableCell from '../../components/TableRowCell';
import useAlert from '../../hooks/useAlert';
import useButtonTheme from '../../hooks/useButtonTheme';
import useIconTheme from '../../hooks/useIconTheme';
import useModal from '../../hooks/useModal';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import MainViewActionButtonTheme from '../../themes/Button/MainViewActionButtonTheme';
import TransperentButtonTheme from '../../themes/Button/TransperentButtonTheme';
import { errorChecker } from '../../utils/helper/helper';
import baseUrl from '../../utils/services/config';
import CustomersList from '../Customers/CustomersList';

const ButtonDelete = styled.div`
cursor: pointer;

`;
const SurveyBuilder = () => {
  const customerAlert = useAlert();
  const { state, dispatch } = useContext(GlobalContext);

  const { iconsTheme } = useIconTheme();
  const { buttonTheme } = useButtonTheme();
  const { themeContainer } = state;
  const [bringingMoreResults, setBringingMoreResults] = useState(false);
  const [isLoading, setisLoading] = useState(false)
  const [list, setList] = useState([]);
  const [showModal, setshowModal] = useState(false);
  const [deleteLoader, setDeleteloader] = useState(false);

  const [searchTerms, setSearchTerms] = useState({
    page: 0,
    count: 0,
    perPage: 30,
    text: '',
  });


  const handleDelete = (dataIndex) => {
    localStorage.setItem('delete_row_id', dataIndex);
    setshowModal(true);
  };

  const handleClose = () => {
    setshowModal(false);
    localStorage.removeItem('delete_row_id');
  };

  const showDeletionPopUp = async () => {
    const dataIndex = localStorage.getItem('delete_row_id');
    setDeleteloader(true);
    const { handleSetMessage, handleSetMessageType, handleShow } = customerAlert;
    if (Array.isArray(list) && list[dataIndex]) {
      const surveyId = list[dataIndex].id;
      if (surveyId) {
        const surveysCopy = [...list];
        surveysCopy.splice(dataIndex, 1);
        const postBody = {
          isDeleted: true,
        };
        try {
          const results = await baseUrl.patch(`/survey/${surveyId}`, postBody);
          if (results.status === 200) {
            handleSetMessageType('success');
            handleSetMessage('Customer deleted successfully');
            handleShow(true);
            setDeleteloader(false);
            handleClose();
            setList(surveysCopy);
          }
        } catch (err) {
          setDeleteloader(false);
          dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        }
      }
    }
  };


  const surveyModal = useModal();
  const actionPannelContainer = (
    handleDelete,
    dataIndex,
    rowIndex,

  ) => (

    <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'start' }}>
      <ThemeProvider theme={{ ...TransperentButtonTheme, color: iconsTheme.trashIconTheme }}>
        <ButtonDelete onClick={() => handleDelete(dataIndex, rowIndex)}>
          <StyledTrashIcon />
        </ButtonDelete>
      </ThemeProvider>
    </div>
  );

  const getSurveyColumns = (
  ) => [
      {
        name: 'displayName',
        label: 'Name',
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value) => {
            return (
              <StyledTableCell>
                <div style={{ display: 'flex' }}>
                  <span
                    style={{
                      padding: '10px 10px',
                      margin: 'auto 0',
                      fontSize: '16px',
                      fontWeight: 700,
                    }}
                  >
                    {value}
                  </span>
                </div>
              </StyledTableCell>
            );
          },
        },
      },
      {
        name: 'updatedAt',
        label: 'updatedAt',
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value) => {
            return <StyledTableCell>{format(value, 'LLL d, yyyy')}</StyledTableCell>;
          },
        },
      },
      {
        name: '',
        label: 'Action',
        options: {
          filter: false,
          sort: false,
          searchable: false,
          customBodyRenderLite: (dataIndex, rowIndex) => {
            return actionPannelContainer(
              handleDelete,
              dataIndex,
              rowIndex,
            );
          },
        },
      },
    ];

  const getSurveys = () => {
    setisLoading(true);
    baseUrl
      .get(`/survey?isDeleted=false`)
      .then((res) => {
        setTimeout(() => {
          setisLoading(false);
          setList(res.data);

        }, 500);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        setisLoading(false);
      });
  }



  useEffect(() => {
    if (bringingMoreResults) {
      if (searchTerms.count !== list.length) {
        getSurveys();
      }
      setBringingMoreResults(false);
    }
  }, [bringingMoreResults]);
  useEffect(() => {
    getSurveys()
  }, [])

  return (
    <div>
      <Box display="flex" justifyContent="space-between">
        <PageTitle title="Survey Builder" />

        <Grid item xs={12} md={6} lg={6} sm={12}>
          <Grid container alignItems="flex-end" justifyContent="flex-end">
            <ThemeProvider
              theme={{
                ...MainViewActionButtonTheme,
                background: buttonTheme.buttonBgColor,
                color: themeContainer.fontColor11,
              }}
            >
              <CustomButton onClick={surveyModal.handleShowModal}>Create survey</CustomButton>
            </ThemeProvider>
          </Grid>
        </Grid>

        <SurveyBuilderPopover
          handleClose={surveyModal.handleHideShowModal}
          show={surveyModal.showModal}
          getSurveys={getSurveys}
        />

      </Box>
      <CancellationPopup
        show={showModal}
        showDeletionPopUp={showDeletionPopUp}
        handleClose={handleClose}
        deleteLoader={deleteLoader}
      />
      <CustomersList
        data={list}
        columns={getSurveyColumns()}
        getCustomers={() => { }}
        searchTerms={searchTerms}
        isLoading={isLoading}
      />
      <CusotmAlert
        message={customerAlert.message}
        handleClose={customerAlert.handleClose}
        open={customerAlert.show}
        messageType={customerAlert.messageType}
      />
    </div>
  );
};

export default SurveyBuilder;
