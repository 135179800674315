/* eslint-disable */
import React from 'react';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import AssessmentIcon from '@material-ui/icons/Assessment';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import PersonIcon from '@material-ui/icons/Person';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const navList = [
  // {
  //   title: 'Dashboard',
  //   iconName: <DashboardIcon />,
  //   pathname: '/app/dashboard',
  //   count: 0,
  // },
  {
    title: 'Conversations',
    iconName: <ChatBubbleOutlineIcon />,
    pathname: '/app/conversations',
    count: 10,
  },
  {
    title: 'Reports',
    iconName: <EqualizerIcon />,
    pathname: '/app/reports',
    count: 0,
  },
  {
    title: 'Reviews',
    iconName: <ThumbUpIcon />,
    pathname: '/app/reviews',
    count: 0,
  },
  // {
  //   title: 'Surveys',
  //   iconName: <AssessmentIcon />,
  //   pathname: '/app/survey',
  //   count: 0,
  // },
  // {
  //   title: 'Listings',
  //   iconName: <CheckCircleIcon />,
  //   pathname: '/app/list',
  //   count: 0,
  // },
  {
    title: 'Feedback',
    iconName: <SupervisorAccountIcon />,
    pathname: '/app/feedback',
    count: 0,
  },
  {
    title: 'Contacts',
    iconName: <PersonIcon />,
    pathname: '/app/account/contacts',
    count: 0,
  },
];

export default navList;

export const managerNavList = [
  // {
  //   title: 'Dashboard',
  //   iconName: <DashboardIcon />,
  //   pathname: '/app/dashboard',
  //   count: 0,
  // },
  {
    title: 'Conversations',
    iconName: <ChatBubbleOutlineIcon />,
    pathname: '/app/conversations',
    count: 10,
  },
  {
    title: 'Reports',
    iconName: <EqualizerIcon />,
    pathname: '/app/reports',
    count: 0,
  },
  {
    title: 'Reviews',
    iconName: <ThumbUpIcon />,
    pathname: '/app/reviews',
    count: 0,
  },
  // {
  //   title: 'Surveys',
  //   iconName: <AssessmentIcon />,
  //   pathname: '/app/survey',
  //   count: 0,
  // },
  // {
  //   title: 'Listings',
  //   iconName: <CheckCircleIcon />,
  //   pathname: '/app/list',
  //   count: 0,
  // },
  {
    title: 'Feedback',
    iconName: <SupervisorAccountIcon />,
    pathname: '/app/feedback',
    count: 0,
  },
  {
    title: 'Contacts',
    iconName: <PersonIcon />,
    pathname: '/app/account/contacts',
    count: 0,
  },
];

export const empNavList = [
  // {
  //   title: 'Dashboard',
  //   iconName: <DashboardIcon />,
  //   pathname: '/app/dashboard',
  //   count: 0,
  // },
  {
    title: 'Conversations',
    iconName: <ChatBubbleOutlineIcon />,
    pathname: '/app/conversations',
    count: 10,
  },
  // {
  //   title: 'Reports',
  //   iconName: <EqualizerIcon />,
  //   pathname: '/app/reports',
  //   count: 0,
  // },
  {
    title: 'Reviews',
    iconName: <ThumbUpIcon />,
    pathname: '/app/reviews',
    count: 0,
  },
  // {
  //   title: 'Surveys',
  //   iconName: <AssessmentIcon />,
  //   pathname: '/app/survey',
  //   count: 0,
  // },
  // {
  //   title: 'Listings',
  //   iconName: <CheckCircleIcon />,
  //   pathname: '/app/list',
  //   count: 0,
  // },
  {
    title: 'Feedback',
    iconName: <SupervisorAccountIcon />,
    pathname: '/app/feedback',
    count: 0,
  },
  {
    title: 'Contacts',
    iconName: <PersonIcon />,
    pathname: '/app/account/contacts',
    count: 0,
  },
];
