import React, { useContext, useRef, useState } from 'react';
import { Box, Checkbox, FormControlLabel, IconButton, makeStyles, Menu } from '@material-ui/core';

import RadioButtonUncheckedIcon from 'mdi-react/RadioButtonUncheckedIcon';
import RadioButtonCheckedIcon from 'mdi-react/RadioButtonCheckedIcon';
import styled, { ThemeProvider } from 'styled-components';
import Proptypes from 'prop-types';
import MoreIcon from '@material-ui/icons/MoreVert';
import usePopover from '../../hooks/usePopover';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import CampaignTemplatePopover from '../Popover/CampaignTemplatePopover/CampaignTemplatePopover';
import { Skeleton } from '@material-ui/lab';
import { CampaignContext } from '../../Stores/CamapignStore';
import { imageURLCheck } from '../../utils/helper/helper';
import { Update } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  root: {
    color: '#707070',
    '&$checked': {
      color: '#707070',
    },
    '&:hover': {
      backgroundColor: '#f9f9f9',
    },
  },
  checked: {},

  selected: {
    backgroundColor: 'red',
  },
  frequencyText: {
    fontFamily: 'Montserrat',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    color: '#979797',
    columnGap: '.2rem',
    height: '20px',
    marginBottom: '-5px',
  },
}));

const StyledLabel = styled.div`
  font-family: Montserrat;
  line-height: 1.1;
  font-size: 14px;
  font-weight: 500;
`;
const StyledContainer = styled.div`
  background-color: white;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  margin-top: 5px;
  padding-right: 0.5rem;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #30feaf30;
    transition: all 0.3s ease-out;
  }
`;
const StyledMoreIcon = styled(MoreIcon)`
  color: ${(props) => props.theme.color}!important;
`;

const StyledTimerIcon = styled(Update)`
  color: #979797;
  width: 14px !important;
`;

const StyledImage = styled.img`
  height: 38px;
  width: 38px;
  border-radius: 4px;
  margin-right: 0.5rem;
  aspect-ratio: 16 / 9;
  object-fit: cover;
`;

const SingleChoice = ({
  options,
  currentOption,
  setCurrentOption,
  withRadioButton,
  optionsOnPress,
  campaignId,
  setCampaignId,
  isLoading,
}) => {
  const classes = useStyles();
  const { state } = useContext(GlobalContext);
  const { campaignStates, campaignToEdit, campaignDispatch } = useContext(CampaignContext);
  const { themeContainer, userRole } = state;
  const canEdit = userRole.id <= 2;
  const optionsPopover = usePopover();

  const handleMoreClick = (campaignId, event) => {
    console.log('handleMoreClick', campaignId);
    //setCampaignId(campaignId);
    campaignDispatch({
      type: 'SET_CAMPAIGN_ID',
      payload: campaignId,
    });
    optionsPopover.handleClick(event);
  };

  const handleChange = (e) => {
    setCurrentOption(e);
  };

  const isAlreadyChecked = (value) => {
    if (Object.entries(currentOption).length) {
      return currentOption.id === value;
    }
    return false;
  };

  return (
    <>
      {options.map((choice) => (
        <StyledContainer
          key={choice.id}
          style={{ backgroundColor: choice.id === currentOption.id ? '#30feaf30' : 'white' }}
        >
          {isLoading ? (
            <Skeleton height={'100%'} width={'100%'} style={{ transform: 'none' }} />
          ) : (
            <>
              <Box flex="1" padding={'0 1rem'} onClick={() => handleChange(choice)}>
                <FormControlLabel
                  key={choice.id}
                  control={
                    <Checkbox
                      color="default"
                      icon={
                        withRadioButton ? <RadioButtonUncheckedIcon fontSize="small" /> : undefined
                      }
                      checkedIcon={
                        withRadioButton ? <RadioButtonCheckedIcon fontSize={'small'} /> : undefined
                      }
                      onChange={() => handleChange(choice)}
                      className={classes.root}
                      value={choice.value}
                      checked={isAlreadyChecked(choice.id)}
                    />
                  }
                  label={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {choice?.uploadedFile ? (
                        <StyledImage src={imageURLCheck(choice.uploadedFile)} />
                      ) : null}
                      <div>
                        <StyledLabel>{choice.label}</StyledLabel>
                        {choice.settings?.canResendAfter &&
                        choice.settings?.canResendAfter !== 0 ? (
                          <div className={classes.frequencyText}>
                            <StyledTimerIcon /> {choice.settings?.canResendAfter} days
                          </div>
                        ) : null}
                      </div>
                    </div>
                  }
                />
              </Box>
              <Box>
                {canEdit && !choice.isDefault && (
                  <IconButton onClick={(event) => handleMoreClick(choice.id, event)}>
                    <ThemeProvider
                      theme={{ color: themeContainer.conversationChatHeaderIconColor }}
                    >
                      <StyledMoreIcon />
                    </ThemeProvider>
                  </IconButton>
                )}
              </Box>
              {choice.id === campaignId && (
                <CampaignTemplatePopover
                  campaignId={campaignId}
                  optionsOnPress={optionsOnPress}
                  anchorEl={optionsPopover.anchorEl}
                  open={Boolean(optionsPopover.anchorEl)}
                  handleClose={optionsPopover.handleClose}
                />
              )}
            </>
          )}
        </StyledContainer>
      ))}
    </>
  );
};

SingleChoice.defaultProps = {
  withRadioButton: false,
  currentOption: {},
};
SingleChoice.propTypes = {
  setCurrentOption: Proptypes.func.isRequired,
  currentOption: Proptypes.shape({
    id: Proptypes.number,
    value: Proptypes.number,
    label: Proptypes.string,
  }),
  options: Proptypes.arrayOf(
    Proptypes.shape({
      id: Proptypes.number,
      value: Proptypes.number,
      label: Proptypes.string,
    }).isRequired,
  ).isRequired,
  withRadioButton: Proptypes.bool,
};
export default SingleChoice;
