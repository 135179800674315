import React from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
const ContainerLoader = (props) => {
  const { hideContent = false, children } = props;
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: hideContent ? '#f5f5f5' : '#f5f5f5b5',
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 4,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ rowGap: '1rem', flexDirection: 'column', fontSize: '14px' }}
      >
        <CircularProgress />
        {children ? children : null}
      </Grid>
    </div>
  );
};
export default ContainerLoader;
