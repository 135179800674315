import React from 'react';
import Proptypes from 'prop-types';
import NumberFormat from 'react-number-format';
import StyledTextField from '../GeneralInputField/GeneralInputField';

function NumberField(props) {
  const {
    name,
    format,
    cusotmInputField,
    label,
    onChange,
    error,
    helperText,
    defaultValue,
    value,
    mask,
    onBlur,
    disabled,
    InputProps,
  } = props;

  const handleChange = (values) => {
    let { value: inputValue } = values;
    inputValue = inputValue.replace(/^\+?1/, '');
    onChange({
      ...values,
      value: inputValue,
    });
  };

  const handlePaste = (event) => {
    const pastedValue = event.clipboardData.getData('text/plain');
    const modifiedValue = pastedValue.replace(/^\+?1/, '');
    handleChange({
      value: modifiedValue,
    });
  };
  return (
    <NumberFormat
      name={name}
      format={format}
      customInput={cusotmInputField}
      label={label}
      onValueChange={handleChange}
      onPaste={handlePaste}
      error={error}
      helperText={helperText}
      defaultValue={defaultValue}
      value={value}
      mask={mask}
      onBlur={onBlur}
      disabled={disabled}
      InputProps={InputProps}
    />
  );
}

NumberField.propTypes = {
  name: Proptypes.string.isRequired,
  format: Proptypes.string,
  cusotmInputField: Proptypes.any,
  label: Proptypes.string.isRequired,
  onChange: Proptypes.func.isRequired,
  error: Proptypes.bool,
  helperText: Proptypes.string,
  defaultValue: Proptypes.string,
  value: Proptypes.string.isRequired,
  mask: Proptypes.string,
  // eslint-disable-next-line react/require-default-props
  onBlur: Proptypes.func,
  disabled: Proptypes.bool,
  // eslint-disable-next-line react/require-default-props
  InputProps: Proptypes.node,
};

NumberField.defaultProps = {
  format: '+1 ### ### ####',
  cusotmInputField: StyledTextField,
  error: false,
  helperText: '',
  defaultValue: '',
  mask: ' ',
  disabled: false,
};

export default NumberField;
