import React from 'react';
import Proptypes from 'prop-types';
import { Select, MenuItem, InputLabel, Box } from '@material-ui/core';
import styled from 'styled-components';
import { withStyles } from '@material-ui/styles';
import useDropDownTheme from '../../hooks/useDropDownTheme';

const MuiStyledSelect = withStyles((theme) => ({
  root: {
    color: theme.inputTextColor,
  },
  icon: {
    color: theme.inputTextColor,
  },
}))(Select);

const StyledInputLabel = styled(InputLabel)`
  top: 20px !important;
  font-family: 'Montserrat' !important;
  font-size: 14px !important;
  white-space: nowrap;

  &.Mui-focused {
    color: #0000008a !important;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-family: 'Montserrat' !important;
  font-weight: 500 !important;
  font-size: 14px !important;
`;

const StyledDropDown = styled(MuiStyledSelect)`
  max-width: ${(props) => (props.theme.maxWidth ? props.theme.maxWidth : '140px')};
  min-width: ${(props) => (props.theme.minWidth ? props.theme.minWidth : '140px')};
  width: 100%;
  font-size: 14px !important;
  margin: ${(props) => props.theme.margin};
  margin-top: ${(props) => (props.theme.marginTop ? props.theme.marginTop : '-2px')} !important;
  border-radius: 2px !important;
  border: ${(props) => props.theme.border} !important;
  background: ${(props) => props.theme.background} !important;
  color: ${(props) => props.theme.color} !important;
  padding: 0 !important;
  font-family: 'Montserrat' !important;

  & .Mui-disabled {
    cursor: not-allowed !important;
  }

  &:before {
    border-bottom: ${(props) => props.theme.borderBottom} !important;
  }

  & div {
    padding: 6px 0 7px;
    margin-top: ${(props) => (props.theme.marginTop ? props.theme.marginTop : '36px')};
    font-weight: 500;
  }

  & > svg {
    color: ${(props) => props.theme.color} !important;
  }

  // @media screen and (min-width: 1920px) {
  //   & {
  //     font-size: ${(props) => props.theme.fontSizeLgScreen} !important;
  //   }
  // }
`;

function Dropdown(props) {
  const { listOptions, options, label, name, handleChange, disabled, title } = props;

  const menuItem = listOptions.map((item) => (
    <StyledMenuItem key={item.label} value={item.value}>
      {item.label}
    </StyledMenuItem>
  ));
  const { dropDownTheme } = useDropDownTheme();

  const MenuProps = {
    PaperProps: {
      style: {
        backgroundColor: dropDownTheme.dropDownBackGroundColor,
        color: dropDownTheme.dropdownColor,
      },
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  };
  return (
    <Box display="flex" flexDirection="column">
      {title && <StyledInputLabel shrink>{label}</StyledInputLabel>}
      <StyledDropDown
        displayEmpty
        disabled={disabled}
        name={name}
        inputProps={{ 'aria-label': 'Without label' }}
        onChange={handleChange}
        {...props}
        MenuProps={MenuProps}
      >
        <StyledMenuItem disabled value="">
          {label}
        </StyledMenuItem>
        {menuItem}
      </StyledDropDown>
    </Box>
  );
}

Dropdown.propTypes = {
  // value: Proptypes.all,
  handleChange: Proptypes.func,
  listOptions: Proptypes.arrayOf(Proptypes.object),
  label: Proptypes.string,
  name: Proptypes.string,
  disabled: Proptypes.bool,
  // title: Proptypes.bool,
};

Dropdown.defaultProps = {
  value: '',
  label: '',
  disabled: false,
  listOptions: [],
  title: true,
};

export default React.memo(Dropdown);
