import React from 'react';
import TextAreaCampaignSettigs from '../../../../CampaignSettings/TextAreaCampaignSetting';
import GalleryPopup from '../../../../../../../Popup/Gallarypopup';
import EditCampaign from '../../../../EditCampaign';
import { Box } from '@material-ui/core';
import SMSImageUpload from '../../../../../../../SMSImageUpload/SMSImageUpload';

const TabMessage = ({
  imgSMS,
  gallaryModal,
  gallaryModalEmail,
  campaign,
  setcampaign,
  userLink,
  setShowEditCampaign,
  showEditCampaign,
  handleEditCampaingSubmit,
}) => {
  return (
    <>
      <Box display="flex" style={{ columnGap: '1.5rem', marginTop: '1.5rem' }}>
        <SMSImageUpload
          title="SMS Image"
          url={imgSMS ? `${process.env.REACT_APP_IMAGE_ENDPOINT}${imgSMS} ` : ''}
          handleClick={gallaryModal.handleShowModal}
          type="mms"
          width="158px"
          height="280px"
          imageCustomStyle={{ borderRadius: '15px' }}
        />
        <Box display="flex" flexDirection="column" flex="1">
          <TextAreaCampaignSettigs
            title="Main message"
            value={campaign?.message}
            setValue={(e) =>
              setcampaign({
                ...campaign,
                message: e,
              })
            }
            multiline
            withLink
            userLink={userLink}
            handleAttachFileIcon={() => setShowEditCampaign(true)}
          />
          <TextAreaCampaignSettigs
            title="Thank you message"
            value={campaign?.settings?.thankyouText}
            setValue={(e) =>
              setcampaign({
                ...campaign,
                settings: {
                  ...campaign.settings,
                  thankyouText: e,
                },
              })
            }
            multiline
          />
        </Box>
      </Box>

      <GalleryPopup
        show={gallaryModal.showModal}
        handleClose={gallaryModal.handleHideShowModal}
        key="gallary-popup-modal-sms"
        handleImagesContainer={(payload) => {
          payload.id = payload.fileId;
          const index = campaign?.uploadedFile.findIndex((item) => item.meta === 'sms');
          if (index !== -1) {
            const updatedUploadedFile = [...campaign.uploadedFile];
            updatedUploadedFile[index] = payload;
            setcampaign({
              ...campaign,
              uploadedFile: updatedUploadedFile,
            });
          } else {
            const updatedUploadedFile = [...campaign.uploadedFile, payload];
            setcampaign({
              ...campaign,
              uploadedFile: updatedUploadedFile,
            });
          }
        }}
        type="sms"
      />
      <GalleryPopup
        show={gallaryModalEmail.showModal}
        handleClose={gallaryModalEmail.handleHideShowModal}
        key="gallary-popup-modal-email"
        handleImagesContainer={(payload) => {
          const index = campaign?.uploadedFile.findIndex((item) => item.meta === 'email');
          payload.id = payload.fileId;

          if (index !== -1) {
            const updatedUploadedFile = [...campaign.uploadedFile];
            updatedUploadedFile[index] = payload;
            setcampaign({
              ...campaign,
              uploadedFile: updatedUploadedFile,
            });
          } else {
            const updatedUploadedFile = [...campaign.uploadedFile, payload];
            setcampaign({
              ...campaign,
              uploadedFile: updatedUploadedFile,
            });
          }
        }}
        type="email"
      />

      <EditCampaign
        handleClose={() => {
          setShowEditCampaign(false);
        }}
        open={showEditCampaign}
        handleSubmit={handleEditCampaingSubmit}
        isAddLink
      />
    </>
  );
};

export default TabMessage;
