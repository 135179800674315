import React from 'react';
import Proptypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import { ThemeProvider } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import useInputFieldTheme from '../../hooks/useInputFieldTheme';
import StyledTextField from '../GeneralInputField/GeneralInputField';
import StyledCheckbox from '../StyledCheckbox/StyledCheckbox';
import useCheckBoxTheme from '../../hooks/useCheckBoxTheme';
import StyledMenuItem from '../Common/StyledMenuItem';

const StyledAutoComplete = styled(Autocomplete)`
  & > div {
    margin: 0.95em 0em !important;
  }
`;

const StyledSelectedContainer = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 70%;
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 500;
`;

const labelStyle = {
  color: '#333',
  fontSize: '14px',
};

const MenuItem = styled(StyledMenuItem)`
  margin: 0;
`;

function AutoCompleteWidget(props) {
  const { options, label, name, control, optionKey, defaultValue, handleEvent, disabled } = props;
  const { inputTheme } = useInputFieldTheme();
  const { checkBoxTheme } = useCheckBoxTheme();
  return (
    <Controller
      as={
        <StyledAutoComplete
          multiple
          disableCloseOnSelect
          getOptionSelected={(option, value) => {
            return option.value === value.value;
          }}
          size="small"
          defaultChecked
          limitTags={1}
          options={options}
          disabled={disabled}
          getOptionLabel={(option) => option[optionKey]}
          renderTags={(value) =>
            value.map((option) => (
              <StyledSelectedContainer key={option.label}>
                {option.label}
                <span>, </span>
              </StyledSelectedContainer>
            ))
          }
          renderOption={(option, { selected }) => (
            <>
              <ThemeProvider theme={checkBoxTheme}>
                <StyledCheckbox
                  style={{ marginRight: 8, fontFamily: 'Montserrat !important', fontSize: '14px' }}
                  checked={selected}
                />
                <StyledMenuItem key={option.label}>{option.label}</StyledMenuItem>
              </ThemeProvider>
            </>
          )}
          renderInput={(params) => (
            <ThemeProvider theme={inputTheme}>
              <StyledTextField {...params} label={label} InputLabelProps={{ style: labelStyle }} />
            </ThemeProvider>
          )}
        />
      }
      name={name}
      control={control}
      defaultValue={defaultValue}
      onChange={([event, data]) => {
        handleEvent(event);
        return data;
      }}
    />
  );
}

AutoCompleteWidget.propTypes = {
  options: Proptypes.arrayOf(Proptypes.object).isRequired,
  label: Proptypes.string.isRequired,
  name: Proptypes.string.isRequired,
  control: Proptypes.any,
  optionKey: Proptypes.string,
  defaultValue: Proptypes.arrayOf(Proptypes.object),
  selectedValues: Proptypes.arrayOf(Proptypes.object),
  handleEvent: Proptypes.func.isRequired,
};

AutoCompleteWidget.defaultProps = {
  optionKey: 'value',
  defaultValue: [],
};

export default AutoCompleteWidget;
