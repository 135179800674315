import React, { useState } from 'react';
import Proptypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';

const StyledImgContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const StyledImage = styled.img`
  width: ${(props) => (props.theme.width ? props.theme.width : '100%')} !important;
  height: ${(props) => (props.theme.height ? props.theme.height : 'auto')} !important;
  border-radius: ${(props) => (props.theme.borderRadius ? props.theme.borderRadius : '5px')};
  object-fit: ${(props) => props.theme.objectFit} !important;
  display: ${(props) => props.theme.display} !important;
  min-height: ${(props) => props.theme.minHeight} !important;
`;

const StyledTxtMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  height: 100% !important;
`;

function UploaderPreview(props) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const { value, type, uploaderPreviewTheme, customStyle } = props;

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const preview =
    type === 'image' ? (
      <ThemeProvider theme={uploaderPreviewTheme}>
        <StyledImage src={`${process.env.REACT_APP_IMAGE_ENDPOINT}${value}`} />
      </ThemeProvider>
    ) : (
      <StyledTxtMessage>{value}</StyledTxtMessage>
    );
  return <StyledImgContainer style={{ ...customStyle }}>{preview}</StyledImgContainer>;
}

UploaderPreview.propTypes = {
  value: Proptypes.string,
  type: Proptypes.string,
  uploaderPreviewTheme: Proptypes.objectOf(Proptypes.string),
};

UploaderPreview.defaultProps = {
  type: 'image',
  uploaderPreviewTheme: {},
};

export default UploaderPreview;
