import React, { useEffect, useMemo, useState, useContext } from 'react';
import { FormHelperText, Box } from '@material-ui/core';
import TextAreaCampaignSettigs from '../../../../CampaignSettings/TextAreaCampaignSetting';
import useModal from '../../../../../../../../hooks/useModal';
import GalleryPopup from '../../../../../../../Popup/Gallarypopup';
import EditCampaign from '../../../../EditCampaign';
import StyledFormControl from '../../../../../../../StyledFormControl';
import SMSImageUpload from '../../../../../../../SMSImageUpload/SMSImageUpload';
import useFetchPostSites from '../../../../../../../../hooks/useFetchPostsites';
import { GlobalContext } from '../../../../../../../../Stores/GlobalStateStore';
import MobilePreview from '../../../../../../../MobilePreview/MobilePreview';
import CampaignPreview from '../CampaignPreview/CampaignPreview';

const TabMessage = ({ bodyRequest, setBodyRequest, messageErrors }) => {
  const [uploadedFile, setUploadedFile] = useState([]);
  const [showEditUserlink, setShowEditUserlink] = useState(false);

  const gallaryModal = useModal();
  const gallaryModalEmail = useModal();

  const { state } = useContext(GlobalContext);
  const { businesses, businessInfo, locationInfo } = state;

  const imgSMS = useMemo(() => {
    return (
      uploadedFile.find((item) => item?.meta === 'sms' || item?.templateType === 'sms')?.url || null
    );
  }, [uploadedFile]);

  const { fetchPostSites, postSitesSelected, allPostsites } = useFetchPostSites();

  const handleEditUserlinkSubmit = (value) => {
    setBodyRequest({
      ...bodyRequest,
      userLink: {
        titleLink: value.title,
        link: value.link,
      },
      message: `${bodyRequest.message} <a href='${value.link}'>${value.title}</a>`,
    });
  };

  useEffect(() => {
    if (businesses.length) {
      fetchPostSites();
    }
  }, []);

  useEffect(() => {
    setBodyRequest({
      ...bodyRequest,
      postSites: postSitesSelected,
    });
  }, [postSitesSelected]);

  useEffect(() => {
    setUploadedFile(bodyRequest.uploadedFile);
  }, [bodyRequest.uploadedFile]);

  useEffect(() => {
    if (bodyRequest?.message.length) {
      const regex = /<a href="([^"]+)">([^<]+)<\/a>/;
      const matches = bodyRequest?.message.match(regex);
      if (matches) {
        const url = matches[1];
        const linkText = matches[2];
        const updatedMessage = bodyRequest.message.replace(regex, '');
        setBodyRequest({
          ...bodyRequest,
          message: updatedMessage,
          userLink: {
            titleLink: linkText,
            link: url,
          },
        });
      }
    }
  }, [bodyRequest?.message]);

  return (
    <>
      <Box display="flex" style={{ columnGap: '2rem', overflowY: 'auto' }}>
        <div>
          <MobilePreview width={'183px'} height={'375px'}>
            <CampaignPreview
              isMessage
              imgSMS={imgSMS}
              message={bodyRequest?.message}
              locationInfo={locationInfo}
              businessInfo={businessInfo}
            />
          </MobilePreview>
        </div>
        <Box display="flex" flexDirection="column" flex="1">
          <Box display="flex" style={{ columnGap: '1rem' }}>
            <div style={{ marginBottom: '1.5rem' }}>
              <SMSImageUpload
                title="SMS Image"
                url={imgSMS ? `${process.env.REACT_APP_IMAGE_ENDPOINT}${imgSMS} ` : null}
                handleClick={gallaryModal.handleShowModal}
                type="mms"
                width="72px"
                height="128px"
                showDeleteOption
                imageCustomStyle={{ borderRadius: '10px' }}
                handleDelete={(e) =>
                  setBodyRequest({
                    ...bodyRequest,
                    uploadedFile: [],
                  })
                }
              />
            </div>
            <TextAreaCampaignSettigs
              value={bodyRequest.message}
              placeholder="Main message *"
              setValue={(e) =>
                setBodyRequest({
                  ...bodyRequest,
                  message: e,
                })
              }
              multiline
              withLink
              userLink={bodyRequest.userLink}
              setUserLink={({ titleLink, link }) => {
                setBodyRequest({
                  ...bodyRequest,
                  userLink: {
                    titleLink,
                    link,
                  },
                });
              }}
              handleAttachFileIcon={() => setShowEditUserlink(true)}
            />
            {messageErrors?.message && (
              <StyledFormControl error={!!messageErrors?.message}>
                <FormHelperText>{messageErrors.message}</FormHelperText>
              </StyledFormControl>
            )}
          </Box>
          <TextAreaCampaignSettigs
            title="The thank you message included at the end of the request flow"
            placeholder="Thank message *"
            value={bodyRequest.settings.thankyouText}
            setValue={(e) =>
              setBodyRequest({
                ...bodyRequest,
                settings: {
                  ...bodyRequest.settings,
                  thankyouText: e,
                },
              })
            }
            multiline
          />
          {messageErrors?.thankyouText && (
            <StyledFormControl error={!!messageErrors?.thankyouText}>
              <FormHelperText>{messageErrors.thankyouText}</FormHelperText>
            </StyledFormControl>
          )}
        </Box>
      </Box>

      <GalleryPopup
        show={gallaryModal.showModal}
        handleClose={gallaryModal.handleHideShowModal}
        key="gallary-popup-modal-sms"
        handleImagesContainer={(payload) => {
          payload.id = payload.fileId;
          const index = uploadedFile.findIndex((item) => item.meta === 'sms');
          if (index !== -1) {
            const updatedUploadedFile = [...uploadedFile];
            updatedUploadedFile[index] = payload;
            setBodyRequest({
              ...bodyRequest,
              uploadedFile: updatedUploadedFile,
            });
          } else {
            const updatedUploadedFile = [...uploadedFile, payload];
            setBodyRequest({
              ...bodyRequest,
              uploadedFile: updatedUploadedFile,
            });
          }
        }}
        type="sms"
      />
      <GalleryPopup
        show={gallaryModalEmail.showModal}
        handleClose={gallaryModalEmail.handleHideShowModal}
        key="gallary-popup-modal-email"
        handleImagesContainer={(payload) => {
          const index = uploadedFile.findIndex((item) => item.meta === 'email');
          payload.id = payload.fileId;

          if (index !== -1) {
            const updatedUploadedFile = [...uploadedFile];
            updatedUploadedFile[index] = payload;
            setBodyRequest({
              ...bodyRequest,
              uploadedFile: updatedUploadedFile,
            });
          } else {
            const updatedUploadedFile = [...uploadedFile, payload];
            setBodyRequest({
              ...bodyRequest,
              uploadedFile: updatedUploadedFile,
            });
          }
        }}
        type="email"
      />

      <EditCampaign
        handleClose={() => {
          setShowEditUserlink(false);
        }}
        open={showEditUserlink}
        handleSubmit={handleEditUserlinkSubmit}
        isAddLink
      />
    </>
  );
};

export default TabMessage;
