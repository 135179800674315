import styled from 'styled-components';
import DatabaseOffOutline from 'mdi-react/DatabaseOffOutlineIcon';
import React from 'react';

const NoDataFoundWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: ${(props) => props.theme?.position || 'relative'};
  height: ${(props) => props.theme?.height || '100%'};
  width: ${(props) => props.theme?.width || '100%'};
  color: ${(props) => props.theme?.color || '#b1b1b1'};
  background: transparent;
  font-size: ${(props) => props.theme?.fontSize || '20px'};
  font-weight: ${(props) => props.theme?.fontWeight || '400'};
  font-family: ${(props) => props.theme?.fontFamily || 'Montserrat'};
  text-align: center;
  flex-direction: column;
  row-gap: 0.5rem;
`;

const NoDataFound = () => (
  <NoDataFoundWrapper>
    <DatabaseOffOutline style={{ width: 40, height: 40 }} />
    No Data Found
  </NoDataFoundWrapper>
);

export default NoDataFound;
