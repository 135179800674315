import React, { useCallback, useState } from 'react';
import Proptypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import UploaderInput from '../UploaderInput/UploaderInput';
import UploaderPreview from '../UploaderPreview/UploaderPreview';
import baseUrl from '../../utils/services/config';
import CusotmAlert from '../CustomAlert/CustomAlert';

const StyledUploader = styled.div`
  position: relative;
  width: ${(props) => props.theme.width};
  height: ${(props) => props.theme.height};
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer !important;
  text-align: ${(props) => props.theme.textAlign}!important;
  background-color: #e2e2e2;
  overflow: hidden;
  transition: opacity 0.3s ease-in-out;
  &:hover .change-image-wrapper {
    opacity: 1;
  }
`;

const StyledLoaderContainer = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ChangeImageButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100% - 4px);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  background-color: #0000008a;
  border-radius: 5px;
`;

const ChangeImageButton = styled.button`
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: transparent;
  border: none;
  font-family: inherit;
  row-gap: 0.5rem;
  font-weight: 500;
`;

const StyledEditIcon = styled(Edit)`
  color: #fff;
`;

function Uploader(props) {
  const [loader, setloader] = useState(false);
  const [showAlert, setAlert] = useState(false);
  const {
    name,
    setValue,
    value,
    title,
    handleImagesContainer,
    imgWidth,
    imgHeight,
    apiUrl,
    urlEndPoint,
    imageType,
    uploaderPreviewTheme,
    disabled,
    exact,
    accept,
    customStyle,
    description,
    allImageSize,
  } = props;

  const url = urlEndPoint || `${apiUrl}?meta=${name}&type=${imageType}`;
  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          // Do whatever you want with the file contents
          const binaryStr = reader.result;
          // Initiate the JavaScript Image object.
          const image = new Image();
          // Set the Base64 string return from FileReader as source.
          image.src = binaryStr;
          // Validate the File Height and Width.
          image.onload = (e) => {
            const { height, width } = e.target;
            // eslint-disable-next-line no-nested-ternary
            const condition = allImageSize
              ? true
              : exact
              ? height === imgHeight && width === imgWidth
              : height >= imgHeight || width >= imgWidth;
            if (condition) {
              setloader(true);
              const picture = new FormData();
              picture.append('picture', acceptedFiles[0]);
              baseUrl
                .post(url, picture)
                .then((res) => {
                  if (res.status === 200) {
                    setValue(name, res.data[0].url);
                    setloader(false);
                    handleImagesContainer(name, res.data[0].id, res.data[0]);
                  }
                })
                .catch(() => setloader(false));
              return true;
            }
            setAlert(true);
            return false;
          };
        };
        reader.readAsDataURL(file);
      });
    },
    [name, setValue, handleImagesContainer, imgHeight, imgWidth, url],
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept });

  const handleClose = () => {
    setAlert(false);
  };

  const uploaderView = value ? (
    <UploaderPreview value={value} height={imgHeight} uploaderPreviewTheme={uploaderPreviewTheme} />
  ) : (
    <UploaderInput title={title} width={imgWidth} height={imgHeight} />
  );

  const uploderContainer = loader ? (
    <StyledLoaderContainer>
      <CircularProgress />
    </StyledLoaderContainer>
  ) : (
    uploaderView
  );

  return (
    <>
      <CusotmAlert
        message={`Image should be of ${imgWidth}px X ${imgHeight}px`}
        messageType="error"
        open={showAlert}
        handleClose={handleClose}
      />
      {value ? (
        <>
          <StyledUploader {...getRootProps()}>
            <UploaderPreview
              value={value}
              height={imgHeight}
              uploaderPreviewTheme={uploaderPreviewTheme}
              description={description}
            />
            <ChangeImageButtonWrapper className="change-image-wrapper">
              <ChangeImageButton {...getRootProps()} onClick={(event) => event.preventDefault()}>
                <StyledEditIcon />
                <input {...getInputProps()} />
                {imgWidth > 40 ? 'Change image' : null}
              </ChangeImageButton>
            </ChangeImageButtonWrapper>
          </StyledUploader>
          {/* {description && <ImageDescription>{description}</ImageDescription>} */}
        </>
      ) : (
        <>
          <StyledUploader {...getRootProps()} style={customStyle}>
            <input {...getInputProps()} name={name} disabled={disabled} />
            {uploderContainer}
          </StyledUploader>
          {/* {description && <ImageDescription>{description}</ImageDescription>} */}
        </>
      )}
    </>
  );
}

Uploader.propTypes = {
  name: Proptypes.string.isRequired,
  setValue: Proptypes.func.isRequired,
  value: Proptypes.string.isRequired,
  uploaderText: Proptypes.string,
  filesLimit: Proptypes.number,
  title: Proptypes.string,
  handleImagesContainer: Proptypes.func.isRequired,
  imgWidth: Proptypes.number,
  imgHeight: Proptypes.number,
  apiUrl: Proptypes.string.isRequired,
  urlEndPoint: Proptypes.string,
  imageType: Proptypes.string,
  uploaderPreviewTheme: Proptypes.objectOf(Proptypes.string),
  disabled: Proptypes.bool,
  exact: Proptypes.bool,
  accept: Proptypes.string,
};

Uploader.defaultProps = {
  uploaderText: 'Upload or Click',
  filesLimit: 1,
  title: 'Upload Image 1080 x 1920',
  imgWidth: 240,
  imgHeight: 200,
  urlEndPoint: null,
  imageType: 'uploads',
  uploaderPreviewTheme: {},
  disabled: false,
  exact: false,
  accept: '.png,.jpg,.jpeg',
};

export default React.memo(Uploader);
