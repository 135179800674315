import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Avatar, Tooltip } from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/History';
import CustomButton from '../components/CustomButton/CustomButton';
import ButtonLoader from '../components/ButtonLoader/ButtonLoader';
import TransperentButtonTheme from '../themes/Button/TransperentButtonTheme';
import StyledTrashIcon from '../components/Icons/TrashIcon';
import StyledTableCell from '../components/TableRowCell';
import StyledEditIcon from '../components/Icons/EditIcon';

const actionPannelContainer = (
  iconsTheme,
  handleEdit,
  handleDelete,
  roles,
  dataIndex,
  rowIndex,
  accountsContainer,
  handleReactive,
) => (
  <div style={{ display: 'flex' }}>
    {!accountsContainer[dataIndex].isDeleted ? (
      <div style={{ display: 'flex' }}>
        <ThemeProvider theme={{ ...TransperentButtonTheme, color: iconsTheme.editIcon }}>
          <CustomButton
            onClick={() => handleEdit(dataIndex, rowIndex)}
            title="Edit"
            className="edit_button"
            style={{ opacity: 0, transition: '.2s' }}
          >
            <StyledEditIcon />
          </CustomButton>
        </ThemeProvider>
        {roles.isEmployee ? null : (
          <ThemeProvider theme={{ ...TransperentButtonTheme, color: iconsTheme.trashIconTheme }}>
            <CustomButton
              onClick={() => handleDelete(dataIndex, rowIndex)}
              title="Delete"
              className="delete_button"
              style={{ opacity: 0, transition: '.2s' }}
            >
              <StyledTrashIcon />
            </CustomButton>
          </ThemeProvider>
        )}
      </div>
    ) : (
      <div style={{ display: 'flex' }}>
        <ThemeProvider theme={{ ...TransperentButtonTheme, color: iconsTheme.editIcon }}>
          <CustomButton onClick={() => handleEdit(dataIndex, rowIndex)} title="Edit">
            <StyledEditIcon />
          </CustomButton>
        </ThemeProvider>
        {roles.isEmployee ? null : (
          <ThemeProvider theme={{ ...TransperentButtonTheme, color: iconsTheme.trashIconTheme }}>
            <CustomButton onClick={() => handleReactive(dataIndex, rowIndex)} title="Re-Active">
              <HistoryIcon />
            </CustomButton>
          </ThemeProvider>
        )}
      </div>
    )}
  </div>
);

const getEmployeeColumns = (
  handleEdit,
  handleDelete,
  accountsContainer,
  iconsTheme,
  roles,
  handleReactive,
) => [
  {
    name: 'name',
    label: 'NAME',
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value) => {
        return (
          <StyledTableCell>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                src={`${process.env.REACT_APP_IMAGE_ENDPOINT}${value.url}`}
                variant="circular"
                style={{ margin: '5px 0px', width: '20px', height: '20px' }}
              />
              <span
                style={{
                  padding: '5px 5px',
                  margin: 'auto 0',
                  fontSize: '14px',
                  fontWeight: 700,
                }}
              >
                {value.name}
              </span>
            </div>
          </StyledTableCell>
        );
      },
    },
  },
  {
    name: 'phone',
    label: 'PHONE',
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value) => {
        return <StyledTableCell>{value}</StyledTableCell>;
      },
    },
  },
  {
    name: 'email',
    label: 'EMAIL',
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value) => {
        return <StyledTableCell>{value}</StyledTableCell>;
      },
    },
  },
  {
    name: 'location_names',
    label: 'LOCATIONS',
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value) => {
        const valuesTooltip = value.map((location, index) => (
          <div key={index}>{location}</div> // Adjust based on the object's structure
        ));
        return (
          <StyledTableCell>
            {(
              <Tooltip title={valuesTooltip ?? 'locations'} arrow>
                <div
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '13ch',
                  }}
                >
                  {value[0]}
                </div>
              </Tooltip>
            ) || <ButtonLoader />}
          </StyledTableCell>
        );
      },
    },
  },

  // {
  //   name: 'linkSent',
  //   label: 'LINK SENT',
  //   options: {
  //     filter: true,
  //     sort: false,
  //     customBodyRender: (value) => {
  //       return <StyledTableCell>{value}</StyledTableCell>;
  //     },
  //   },
  // },
  // {
  //   name: 'reviews',
  //   label: 'REVIEWS',
  //   options: {
  //     filter: true,
  //     sort: false,
  //     customBodyRender: (value) => {
  //       return <StyledTableCell>{value}</StyledTableCell>;
  //     },
  //   },
  // },
  {
    name: 'roles',
    label: 'ROLES',
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value) => {
        return <StyledTableCell>{value !== '' ? value : <ButtonLoader />}</StyledTableCell>;
      },
    },
  },
  // {
  //   name: 'accountType',
  //   label: 'Account Type',
  //   options: {
  //     filter: true,
  //     sort: false,
  //     customBodyRender: (value) => {
  //       return <StyledTableCell>{value}</StyledTableCell>;
  //     },
  //   },
  // },
  {
    name: '',
    label: 'Action',
    options: {
      filter: false,
      sort: false,
      searchable: false,
      customBodyRenderLite: (dataIndex, rowIndex) => {
        if (accountsContainer[dataIndex]) {
          if (!accountsContainer[dataIndex].isDeleted) {
            return roles.isEmployee &&
              accountsContainer[dataIndex].id !== parseInt(localStorage.getItem('USER_ID'), 10)
              ? null
              : actionPannelContainer(
                  iconsTheme,
                  handleEdit,
                  handleDelete,
                  roles,
                  dataIndex,
                  rowIndex,
                  accountsContainer,
                  handleReactive,
                );
            // eslint-disable-next-line no-else-return
          } else {
            return actionPannelContainer(
              iconsTheme,
              handleEdit,
              handleDelete,
              roles,
              dataIndex,
              rowIndex,
              accountsContainer,
              handleReactive,
            );
          }
        }
        return '';
      },
    },
  },
];

export default getEmployeeColumns;
