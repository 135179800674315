import React, { useCallback, useState } from 'react';
import Proptypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import styled, { ThemeProvider } from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import UploaderInput from '../UploaderInput/UploaderInput';
import UploaderPreview from '../UploaderPreview/UploaderPreview';
import baseUrl from '../../utils/services/config';

const StyledUploader = styled.div`
  //   width: 90%;
  //   height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px dashed #bfbfbf;
  margin: 0.5em 0;
  text-align: ${(props) => props.theme.textAlign}!important;
  cursor: pointer !important;
  /* width: 15vw;
  height: 15vw; */
  border-radius: 50%;
  min-height: 210px;
  // @media screen and (max-width: 600px) {
  //   & {
  //     width: 80% !important;
  //     height: 30vh !important;
  //   }
  // }
  // @media only screen and (device-width: 768px) {
  //   & {
  //     width: 22vw !important;
  //     height: 22vw !important;
  //   }
  // }
`;

const StyledLoaderContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const imgAvatar = {
  display: 'flex',
  borderRadius: '50%',
  width: '100%',
  height: 'unset',
  objectFit: 'cover',
  minHeight: '23vh',
};

function AvatarUploader(props) {
  const [loader, setloader] = useState(false);
  const { name, setValue, value, title, handleImagesContainer, accept } = props;

  const onDrop = useCallback(
    (acceptedFiles) => {
      setloader(true);
      const picture = new FormData();
      picture.append('picture', acceptedFiles[0]);
      baseUrl
        .post(`/uploads/images?type=avatar`, picture)
        .then((res) => {
          if (res.status === 200) {
            setValue(name, res.data[0].url);
            setloader(false);
            handleImagesContainer(name, res.data[0]);
          }
        })
        .catch(() => setloader(false));
    },
    [name, setValue, handleImagesContainer],
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept });

  const uploaderView = value ? (
    <ThemeProvider theme={imgAvatar}>
      <UploaderPreview value={value} />
    </ThemeProvider>
  ) : (
    <UploaderInput title={title} />
  );

  const uploderContainer = loader ? (
    <StyledLoaderContainer>
      <CircularProgress />
    </StyledLoaderContainer>
  ) : (
    uploaderView
  );

  return (
    <StyledUploader {...getRootProps()}>
      <input {...getInputProps()} name={name} />
      {uploderContainer}
    </StyledUploader>
  );
}

AvatarUploader.propTypes = {
  uploaderText: Proptypes.string,
  filesLimit: Proptypes.number,
  title: Proptypes.string,
  handleImagesContainer: Proptypes.func.isRequired,
  name: Proptypes.string.isRequired,
  setValue: Proptypes.func.isRequired,
  value: Proptypes.string.isRequired,
  accept: Proptypes.string,
};

AvatarUploader.defaultProps = {
  uploaderText: 'Upload or Click',
  filesLimit: 1,
  title: 'Upload or select',
  accept: '.png,.jpg,.jpeg',
};

export default React.memo(AvatarUploader);
