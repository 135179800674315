import React, { useState, useEffect, useContext, useCallback } from 'react';
// import Proptypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import {
  Grid,
  FormHelperText,
  ThemeProvider as MUIThemeProvider,
  InputAdornment,
  IconButton,
  Box,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import CustomButton from '../../components/CustomButton/CustomButton';
import StyledTextField from '../../components/GeneralInputField/GeneralInputField';
import Dropdown from '../../components/Dropdown/Dropdown';
import baseUrl from '../../utils/services/config';
import {
  ConvertInDropDownFormat,
  handleLocationViewErrors,
  handlePhoneError,
  handleEmailError,
  ConvertIntoUserDropDown,
  getMultiSelectIds,
  errorChecker,
  validateURL,
  getURLWithoutPrefix,
} from '../../utils/helper/helper';
import baseUrlLogin from '../../utils/services/auth.config';
import CusotmAlert from '../../components/CustomAlert/CustomAlert';
import Loader from '../../components/Loader/Loader';
import NumberField from '../../components/NumberField/NumberField';
import { emailRegEx } from '../../utils/helper/regEx';
import ButtonLoader from '../../components/ButtonLoader/ButtonLoader';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import defaultDropDownTheme from '../../themes/Dropdown/DefaultDropdownTheme';
import Timezone from '../../utils/api/Timezone';
import useInputFieldTheme from '../../hooks/useInputFieldTheme';
import StyledFormControl from '../../components/StyledFormControl';
import useButtonTheme from '../../hooks/useButtonTheme';
import useFetchBusiness from '../../hooks/useFetchBusiness';
import { FormHeader } from '../../components/Forms/FormHeader';
import TransperentButtonTheme from '../../themes/Button/TransperentButtonTheme';
import CloseIcon from 'mdi-react/CloseIcon';
import useIconTheme from '../../hooks/useIconTheme';

const StyledDashboard = styled(Grid)`
  padding: 1em 2em;
  min-height: 100%;
`;

const StyledBox = styled(Box)`
  background-color: #f5f5f5;
`;

const StyledGridContainer = styled(Grid)`
  padding: 0 0em 0em 1em;

  // @media screen and (min-width: 1920px) {
  //   & {
  //     padding: 0 1em 0 2em;
  //   }
  // }
`;

const trial_MMS_number = '3852472073';
const defaultValues = {
  //mmsNumber: trial_MMS_number,
  mmsNumber: trial_MMS_number,
  users: [parseInt(localStorage.getItem('USER_ID'), 10), 1],
};
function BusinessForm(props) {
  const { businessId, handleClose } = props;
  const { state, dispatch } = useContext(GlobalContext);
  const { selectedBusiness, userInfo, roles } = state;
  const { isSuperAdmin } = roles;
  const { business, accountType } = userInfo;
  const [seoCategories, setSeoCategory] = useState([]);
  const [industries, setIndustry] = useState([]);
  const [showAlert, setAlert] = useState(false);
  const [renderView, setRenderView] = useState(false);
  const [loader, setLoader] = useState(false);
  const [usersContainer, setUserContainer] = useState([]);
  const [disabedMMSNo, setDisabledMMSNo] = useState(false);
  const { register, control, errors, handleSubmit, setValue, watch, setError, clearError } =
    useForm({
      defaultValues,
    });
  const { inputTheme } = useInputFieldTheme();
  const { fetchBusiness } = useFetchBusiness();
  const [emailError, setEmailError] = useState(false);
  const [disabledBtn, setDisableBtn] = useState(false);
  const [emailLoader, setEmailLoader] = useState(false);
  const { iconsTheme } = useIconTheme();

  const fetchBusinessFormValues = useCallback(() => {
    if (businessId && businessId !== 'new') {
      baseUrl
        .get(`/business/${businessId}`)
        .then((res) => {
          if (res.status === 200) {
            setRenderView(true);
            let contactDetails = { ...res.data.contact };
            contactDetails = {
              ...contactDetails,
              phoneNumber: contactDetails.phoneNumber
                ? contactDetails.phoneNumber.substring(2)
                : '',
              businessContact: contactDetails.businessContact
                ? contactDetails.businessContact.substring(2)
                : '',
              webSite: getURLWithoutPrefix(contactDetails.webSite),
            };
            setValue('name', res.data.name);
            setValue('contact', contactDetails);
            setValue('nickName', res.data.nickName);
            setValue('seoCategory', parseInt(res.data.seoCategory[0].id, 10));
            setValue('industry', parseInt(res.data.industry[0].id, 10));
            setValue('noEmployees', parseInt(res.data.noEmployees, 10));
            setValue('description', res.data.description);
            setValue('noLocations', res.data.noLocations);
            setValue(
              'mmsNumber',
              res.data.mmsNumber
                ? res.data.mmsNumber.toString().substring(2)
                : trial_MMS_number.toString(),
            );
            setValue('email', res.data.email);
            // setValue('googleEmail', res.data.googleEmail);
            // setValue('facebookEmail', res.data.facebookEmail);
            // setValue('twitterEmail', res.data.twitterEmail);
            setValue('users', getMultiSelectIds(res.data.user));
          }
        })
        .catch((err) => {
          dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        });
    } else {
      setRenderView(true);
      setValue('mmsNumber', trial_MMS_number.toString());
    }
  }, [businessId, setValue, dispatch]);

  const fetchSeoCategory = useCallback(() => {
    baseUrl
      .get('/seoCategory?isDeleted=false')
      .then((res) => {
        if (res.status === 200) {
          setSeoCategory(Array.from(ConvertInDropDownFormat(res.data)));
        }
      })
      .catch((err) => {
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      });
  }, [dispatch]);

  const fetchIndustries = useCallback(() => {
    baseUrlLogin
      .get('/industry?isDeleted=false')
      .then((res) => {
        if (res.status === 200) {
          setIndustry(Array.from(ConvertInDropDownFormat(res.data)));
        }
      })
      .catch((err) => {
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      });
  }, [dispatch]);

  const fetchUsers = useCallback(() => {
    baseUrl
      .get(`/business/${selectedBusiness}/user?isDeleted=false`)
      .then((res) => {
        if (res.status === 200) {
          setUserContainer(Array.from(ConvertIntoUserDropDown(res.data, true)));
        }
      })
      .catch((err) => {
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      });
  }, [selectedBusiness, dispatch, setValue]);

  useEffect(() => {
    setValue('users', ['']);
    localStorage.setItem('Tab_Id', 0);
    fetchBusinessFormValues();
    fetchSeoCategory();
    fetchIndustries();
    if (selectedBusiness) {
      fetchUsers();
    }
    if (accountType === 'trial' && !isSuperAdmin) {
      setDisabledMMSNo(true);
    }
  }, [
    setValue,
    fetchBusinessFormValues,
    fetchSeoCategory,
    fetchIndustries,
    fetchUsers,
    accountType,
    isSuperAdmin,
    selectedBusiness,
  ]);

  const onSubmit = (data) => {
    if (!emailError) {
      if ((data.user && data.users.length > 0) || data.user === undefined) {
        setLoader(true);
        let contactDetails = { ...data.contact };
        contactDetails = {
          ...contactDetails,
          phoneNumber: `+1${contactDetails.phoneNumber}`,
          businessContact:
            contactDetails.businessContact && contactDetails.businessContact !== undefined
              ? `+1${contactDetails.businessContact}`
              : '',
          webSite: validateURL(contactDetails.webSite),
        };
        const postBody = {
          contact: { ...contactDetails },
          name: data.name,
          createdBy: parseInt(localStorage.getItem('USER_ID'), 10),
          seoCategory: [data.seoCategory],
          industry: [data.industry],
          nickName: data.nickName,
          noEmployees: parseInt(data.noEmployees, 10),
          noLocations: parseInt(data.noLocations, 10),
          mmsNumber: data.mmsNumber ? `+1${data.mmsNumber}` : '',
          email: data.email,
          googleEmail: data.googleEmail,
          facebookEmail: data.facebookEmail,
          twitterEmail: data.twitterEmail,
          user: data.users
            ? [...new Set([parseInt(localStorage.getItem('USER_ID'), 10), 1].concat(data.users))]
            : [parseInt(localStorage.getItem('USER_ID'), 10), 1],
        };
        if (businessId && businessId !== 'new') {
          baseUrl
            .patch(`/business/${businessId}`, { ...postBody })
            .then((res) => {
              if (res.status === 200) {
                setAlert(true);
                handleClose();
                setTimeout(() => {
                  //history.push('/app/setup/business');
                  setLoader(false);
                }, 1000);
              }
            })
            .catch((err) => {
              setLoader(false);
              dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
            });
        } else {
          baseUrl
            .post('/business', { ...postBody })
            .then((res) => {
              if (res.status === 200) {
                setAlert(true);
                baseUrl.get(`/user/${localStorage.getItem('USER_ID')}/business`).then(() => {
                  fetchBusiness();
                  handleClose();
                  setTimeout(() => {
                    //history.push('/app/setup/business');
                    setLoader(false);
                  }, 1000);
                });
              }
            })
            .catch((err) => {
              setLoader(false);
              dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
            });
        }
      } else {
        setError('users', {
          type: 'required',
          message: 'users is a required field',
        });
      }
    } else if (emailError) {
      setError('email', {
        type: 'exists',
        message: 'Email address already taken',
      });
    }
  };

  const handleCloseAlert = () => {
    setAlert(false);
  };

  useEffect(() => {
    register({ name: 'contact.businessContact' }, { minLength: 10 });
    register({ name: 'contact.zip' }, { required: true, minLength: 5 });
    register({ name: 'contact.phoneNumber' }, { required: true, minLength: 10 });
    register({ name: 'mmsNumber' }, { required: true, minLength: 10 });
  }, [register]);

  const watchContactField = watch('contact');
  const watchUsersField = watch('users');
  const watchMMSField = watch('mmsNumber');
  const watchBusinessName = watch('name');
  const watchEmailAddress = watch('email');

  const emailLoaderContent = emailLoader ? (
    <InputAdornment position="start">
      <ButtonLoader />
    </InputAdornment>
  ) : null;

  const validateEmail = (e) => {
    setValue('email', e.target.value.trim());
    if (emailRegEx.test(watchEmailAddress)) {
      setDisableBtn(true);
      setEmailLoader(true);
      baseUrl(`/business/checkDuplicateEmail?emailAddress=${watchEmailAddress}`)
        .then((res) => {
          if (res.status === 200) {
            // if (res.data.length > 0 && res.data[0].id !== parseInt(id, 10)) {
            setDisableBtn(false);
            setEmailLoader(false);
            setEmailError(true);
            setError('email', {
              type: 'exists',
              message: 'Email address already taken',
            });
            // }
          } else {
            setEmailError(false);
            setEmailLoader(false);
            clearError('email');
            setDisableBtn(false);
          }
        })
        .catch((err) => {
          setDisableBtn(false);
          setEmailLoader(false);
          dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        });
    } else {
      clearError('email');
      setDisableBtn(false);
    }
  };
  const userSelection =
    business.length > 0 ? (
      <Grid item md={6} lg={6} xs={12} sm={12}>
        <ThemeProvider theme={defaultDropDownTheme}>
          <StyledFormControl error={!!errors.users}>
            <Controller
              as={<MultiSelect listOptions={Array.from(usersContainer)} label="Select Users *" />}
              value={watchUsersField || []}
              name="users"
              control={control}
              rules={{ required: true }}
              defaultValue={[]}
            />
            {errors.users ? <FormHelperText>Users is a required field</FormHelperText> : null}
          </StyledFormControl>
        </ThemeProvider>
      </Grid>
    ) : null;

  const showLoader = loader ? <ButtonLoader /> : null;

  return renderView ? (
    <Box padding={'0 3rem 2rem 2rem'}>
      <CusotmAlert
        open={showAlert}
        message={
          businessId !== 'new' ? 'Business updated successfully' : 'Business added successfully'
        }
        handleClose={handleCloseAlert}
      />
      <MUIThemeProvider theme={inputTheme}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledBox
            position={'sticky'}
            top={'0'}
            display="flex"
            justifyContent={'space-between'}
            alignItems={'center'}
            zIndex={1}
            padding="3rem 1rem 1rem 2rem"
            margin={'0 -1rem'}
          >
            <FormHeader>{businessId === 'new' ? 'Add Business' : 'Edit Business'}</FormHeader>
            <Box marginTop={'-7rem'} marginRight="-3rem" display="flex" height="3rem">
              <Box width="200px" marginTop="1.5rem" marginRight="-3rem">
                <CustomButton
                  type="submit"
                  width={'300px'}
                  disabled={loader || emailLoader || disabledBtn}
                >
                  Save
                  {showLoader}
                </CustomButton>
              </Box>
              <ThemeProvider theme={TransperentButtonTheme}>
                <IconButton onClick={() => props.handleClose()}>
                  <ThemeProvider theme={{ color: iconsTheme.clearIcon }}>
                    <CloseIcon />
                  </ThemeProvider>
                </IconButton>
              </ThemeProvider>
            </Box>
          </StyledBox>
          <Grid container>
            <StyledGridContainer item sm={12} xs={12} md={12} lg={12}>
              <Grid container spacing={2}>
                <Grid item md={6} lg={6} xs={12} sm={12}>
                  <StyledTextField
                    name="name"
                    label="Business Name *"
                    onBlur={(e) => setValue('name', e.target.value.trim())}
                    inputRef={register({
                      required: true,
                      validate: (value) => value.trim() !== '',
                    })}
                    error={!!errors.name}
                    helperText={errors.name ? 'Business name is a required field' : ''}
                  />
                </Grid>
                <Grid item md={6} lg={6} xs={12} sm={12}>
                  <StyledTextField
                    name="nickName"
                    label="Display Name *"
                    onBlur={(e) => setValue('nickName', e.target.value.trim())}
                    inputRef={register({
                      required: true,
                      validate: (value) => value.trim() !== '',
                    })}
                    error={!!errors.nickName}
                    helperText={errors.nickName ? 'Nickname is a required field' : ''}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg={12} sm={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={6} sm={12}>
                    <StyledTextField
                      name="contact.addressLine1"
                      label="Address 1 *"
                      onBlur={(e) => setValue('contact.addressLine1', e.target.value.trim())}
                      inputRef={register({
                        required: true,
                        validate: (value) => value.trim() !== '',
                      })}
                      error={
                        handleLocationViewErrors(errors, 'contact')
                          ? errors.contact.addressLine1
                          : false
                      }
                      helperText={
                        handleLocationViewErrors(errors, 'contact') && errors.contact.addressLine1
                          ? 'Address is a required field'
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} sm={12}>
                    <StyledTextField
                      name="email"
                      label="Contact Email *"
                      onBlur={validateEmail}
                      inputRef={register({
                        required: true,
                        pattern: emailRegEx,
                        validate: (value) => value.trim() !== '',
                      })}
                      error={!!errors.email}
                      helperText={errors.email ? handleEmailError(errors.email) : ''}
                      InputProps={{
                        endAdornment: emailLoaderContent,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid item xs={12} md={12} lg={12} sm={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6} lg={6} sm={12}>
                        <StyledTextField
                          name="googleEmail"
                          label="Google Email *"
                          onBlur={(e) => setValue('googleEmail', e.target.value.trim())}
                          inputRef={register({ required: true, pattern: emailRegEx })}
                          error={errors.googleEmail}
                          helperText={
                            errors.googleEmail ? handleEmailError(errors.googleEmail) : ''
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={6} sm={12}>
                        <StyledTextField
                          name="facebookEmail"
                          label="Facebook Email *"
                          onBlur={(e) => setValue('facebookEmail', e.target.value.trim())}
                          inputRef={register({ required: true, pattern: emailRegEx })}
                          error={errors.facebookEmail}
                          helperText={
                            errors.facebookEmail ? handleEmailError(errors.facebookEmail) : ''
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid> */}
              {/* <Grid item xs={12} md={12} lg={12} sm={12}>
                    <StyledTextField
                      name="twitterEmail"
                      label="Twitter Email *"
                      onBlur={(e) => setValue('twitterEmail', e.target.value.trim())}
                      inputRef={register({ required: true, pattern: emailRegEx })}
                      error={errors.twitterEmail}
                      helperText={errors.twitterEmail ? handleEmailError(errors.twitterEmail) : ''}
                    />
                  </Grid> */}

              <Grid item xs={12} md={12} lg={12} sm={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={6} sm={12}>
                    <StyledTextField
                      name="contact.addressLine2"
                      label="Address 2 "
                      onBlur={(e) => setValue('contact.addressLine2', e.target.value.trim())}
                      inputRef={register}
                      error={
                        handleLocationViewErrors(errors, 'contact')
                          ? errors.contact.addressLine2
                          : false
                      }
                      helperText={
                        handleLocationViewErrors(errors, 'contact') && errors.contact.addressLine2
                          ? 'Address is a required field'
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} sm={12}>
                    <StyledTextField
                      name="contact.webSite"
                      label="Web Address *"
                      onBlur={(e) => setValue('contact.webSite', e.target.value.trim())}
                      inputRef={register({
                        required: true,
                        validate: (value) => value.trim() !== '',
                      })}
                      error={
                        handleLocationViewErrors(errors, 'contact') ? errors.contact.webSite : false
                      }
                      helperText={
                        handleLocationViewErrors(errors, 'contact') && errors.contact.webSite
                          ? 'Web Address is a required field'
                          : ''
                      }
                      InputProps={{
                        startAdornment: <InputAdornment position="start">https://</InputAdornment>,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg={12} sm={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={8} lg={8} sm={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={4} lg={4} sm={12}>
                        <StyledTextField
                          name="contact.city"
                          label="City *"
                          onBlur={(e) => setValue('contact.city', e.target.value.trim())}
                          inputProps={{ 'aria-label': 'description' }}
                          inputRef={register({
                            required: true,
                            validate: (value) => value.trim() !== '',
                          })}
                          error={
                            handleLocationViewErrors(errors, 'contact')
                              ? errors.contact.city
                              : false
                          }
                          helperText={
                            handleLocationViewErrors(errors, 'contact') && errors.contact.city
                              ? 'City is a required field'
                              : ''
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={4} lg={4} sm={12}>
                        <StyledTextField
                          name="contact.state"
                          label="State *"
                          onBlur={(e) => setValue('contact.state', e.target.value.trim())}
                          inputProps={{ 'aria-label': 'description' }}
                          inputRef={register({
                            required: true,
                            validate: (value) => value.trim() !== '',
                          })}
                          error={
                            handleLocationViewErrors(errors, 'contact')
                              ? errors.contact.state
                              : false
                          }
                          helperText={
                            handleLocationViewErrors(errors, 'contact') && errors.contact.state
                              ? 'State is a required field'
                              : ''
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={4} lg={4} sm={12}>
                        <NumberField
                          name="contact.zip"
                          format="#####"
                          label="Zip *"
                          onChange={(values) => setValue('contact.zip', values.value)}
                          error={
                            handleLocationViewErrors(errors, 'contact') ? errors.contact.zip : false
                          }
                          helperText={
                            handleLocationViewErrors(errors, 'contact') && errors.contact.zip
                              ? handlePhoneError(errors.contact.zip, 'Zip', 5)
                              : ''
                          }
                          defaultValue={
                            watchContactField && watchContactField.zip ? watchContactField.zip : ''
                          }
                          value={
                            watchContactField && watchContactField.zip ? watchContactField.zip : ''
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={4} lg={4} sm={12}>
                    <ThemeProvider theme={defaultDropDownTheme}>
                      <StyledFormControl
                        error={
                          handleLocationViewErrors(errors, 'contact')
                            ? errors.contact.timeZone
                            : false
                        }
                      >
                        <Controller
                          as={<Dropdown listOptions={Array.from(Timezone)} label="Time Zone *" />}
                          name="contact.timeZone"
                          control={control}
                          defaultValue=""
                          rules={{ required: true }}
                        />
                        {handleLocationViewErrors(errors, 'contact') && errors.contact.timeZone ? (
                          <FormHelperText>Time Zone is a required field</FormHelperText>
                        ) : null}
                      </StyledFormControl>
                    </ThemeProvider>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item md={6} lg={6} xs={12} sm={12}>
                    <NumberField
                      name="contact.phoneNumber"
                      format="+1 ### ### ####"
                      label="Contact Mobile *"
                      disabled={false}
                      onChange={(values) => setValue('contact.phoneNumber', values.value)}
                      error={
                        handleLocationViewErrors(errors, 'contact')
                          ? errors.contact.phoneNumber
                          : false
                      }
                      helperText={
                        handleLocationViewErrors(errors, 'contact') && errors.contact.phoneNumber
                          ? handlePhoneError(errors.contact.phoneNumber, 'Contact Mobile')
                          : ''
                      }
                      defaultValue={
                        watchContactField && watchContactField.phoneNumber
                          ? watchContactField.phoneNumber
                          : ''
                      }
                      value={
                        watchContactField && watchContactField.phoneNumber
                          ? watchContactField.phoneNumber
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item md={6} lg={6} xs={12} sm={12}>
                    <NumberField
                      name="contact.businessContact"
                      format="+1 ### ### ####"
                      label="Business Contact Number"
                      onChange={(val) => setValue('contact.businessContact', val.value)}
                      error={
                        handleLocationViewErrors(errors, 'contact')
                          ? errors.contact.businessContact
                          : false
                      }
                      helperText={
                        handleLocationViewErrors(errors, 'contact') &&
                        errors.contact.businessContact
                          ? handlePhoneError(errors.contact.businessContact, 'Business Contact')
                          : ''
                      }
                      defaultValue={
                        watchContactField && watchContactField.businessContact
                          ? watchContactField.businessContact
                          : ''
                      }
                      value={
                        watchContactField && watchContactField.businessContact
                          ? watchContactField.businessContact
                          : ''
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item md={6} lg={6} xs={12} sm={12}>
                    <StyledTextField
                      name="noEmployees"
                      type="number"
                      label="Number of Employees "
                      inputProps={{ 'aria-label': 'description' }}
                      inputRef={register({ min: 0 })}
                      error={!!errors.noEmployees}
                      helperText={
                        errors.noEmployees
                          ? handlePhoneError(errors.noEmployees, 'Number of Employees', '0')
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item md={6} lg={6} xs={12} sm={12}>
                    <StyledTextField
                      name="noLocations"
                      type="number"
                      label="Number of Locations "
                      inputProps={{ 'aria-label': 'description' }}
                      inputRef={register({ min: 0 })}
                      error={!!errors.noLocations}
                      helperText={
                        errors.noLocations
                          ? handlePhoneError(errors.noLocations, 'Number of Locations', '0')
                          : ''
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item md={6} lg={6} xs={12} sm={12}>
                    <ThemeProvider theme={defaultDropDownTheme}>
                      <StyledFormControl error={!!errors.seoCategory}>
                        <Controller
                          as={
                            <Dropdown
                              listOptions={Array.from(seoCategories)}
                              label="Select SEO Category *"
                            />
                          }
                          name="seoCategory"
                          control={control}
                          defaultValue=""
                          rules={{ required: true }}
                        />
                        {errors.seoCategory ? (
                          <FormHelperText>SEO Category is a required field</FormHelperText>
                        ) : null}
                      </StyledFormControl>
                    </ThemeProvider>
                  </Grid>
                  <Grid item md={6} lg={6} xs={12} sm={12}>
                    <ThemeProvider theme={defaultDropDownTheme}>
                      <StyledFormControl error={!!errors.industry}>
                        <Controller
                          as={
                            <Dropdown
                              listOptions={Array.from(industries)}
                              label="Google Business Category *"
                            />
                          }
                          name="industry"
                          control={control}
                          defaultValue=""
                          rules={{ required: true }}
                        />
                        {errors.industry ? (
                          <FormHelperText>
                            Google Business Category is a required field
                          </FormHelperText>
                        ) : null}
                      </StyledFormControl>
                    </ThemeProvider>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item md={6} lg={6} xs={12} sm={12}>
                      <NumberField
                        name="mmsNumber"
                        format="+1 ### ### ####"
                        label="MMS Number *"
                        disabled={disabedMMSNo}
                        onChange={(values) => setValue('mmsNumber', values.value)}
                        error={errors.mmsNumber}
                        helperText={
                          errors.mmsNumber ? handlePhoneError(errors.mmsNumber, 'MMS Number') : ''
                        }
                        defaultValue={watchMMSField || ''}
                        value={watchMMSField || ''}
                      />
                    </Grid>
                    {userSelection}
                  </Grid>
                </Grid>
              </Grid>
            </StyledGridContainer>
          </Grid>
          <Grid container justify="center" />
        </form>
      </MUIThemeProvider>
    </Box>
  ) : (
    <Loader />
  );
}

BusinessForm.propTypes = {};

export default BusinessForm;
