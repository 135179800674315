import React from 'react';
import Proptypes from 'prop-types';
import { ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import styled, { ThemeProvider } from 'styled-components';
import CusotmAvatar from '../../CustomAvatar/CustomAvatar';
import DefaultAvatar from '../../../themes/Avatar/DefaultAvatar';
import theme from '../../../setup';
import StyledChip from '../../Chip/BasicChip';
import useTextColors from '../../../hooks/useTextColors';

const StyledListItem = styled(ListItem)`
  cursor: pointer !important;
  background: ${(props) => props.theme.background} !important;
  border-bottom: 1px solid #ccc !important;
  font-family: 'Montserrat' !important;
  font-size: 14px !important;
`;

const StyledName = styled(ListItemText)`
  & > span {
    font-weight: 600 !important;
    color: ${(props) => props.theme.color} !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Montserrat';
    font-size: 14px;
    text-transform: capitalize;
  }

  & > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${(props) => props.theme.color} !important;
    font-style: ${(props) => props.theme.secondaryFontStyle};
  }
`;

const StyledPhone = styled(ListItemSecondaryAction)`
  color: ${(props) => props.theme.color} !important;
  cursor: pointer !important;
`;

const StyledLocation = styled.span`
  color: ${(props) => props.theme.color} !important;
  text-decoration: underline;
  margin: 0.25em;
`;

const StyledText = styled.span`
  color: ${(props) => props.theme.color} !important;
  font-size: 14px !important;
  font-family: Montserrat;
`;

function ContactInfoList(props) {
  const {
    src,
    name,
    phone,
    suggestedBy,
    index,
    handleSelection,
    selectedUser,
    showPhone,
    phoneShowAsSecondary,
    locationShowAsSecondary,
    locations,
    unsubscribeEmail,
    unsubscribeMobile,
    MemberCountShowAsSecondary,
    membersCount,
    showSuggestedBy,
  } = props;

  const { textColors } = useTextColors();

  const phoneContainer =
    showPhone && !phoneShowAsSecondary ? (
      <ThemeProvider theme={{ color: textColors.fontColor5 }}>
        <StyledPhone>{phone}</StyledPhone>
      </ThemeProvider>
    ) : null;

  const secondaryContentContainer =
    unsubscribeEmail || unsubscribeMobile ? (
      <StyledPhone>
        <StyledChip label="Unsubscribed" />
      </StyledPhone>
    ) : (
      phoneContainer
    );

  const locationsContent = locations
    ? locations.map((location) => {
        return (
          <ThemeProvider theme={{ color: textColors.fontColor6 }}>
            <StyledLocation>{location.nickName}</StyledLocation>
          </ThemeProvider>
        );
      })
    : null;

  const secondaryPhoneContent = phoneShowAsSecondary ? phone : null;

  const secondaryLocationContent = locationShowAsSecondary ? locationsContent : null;

  const secondaryMemberCountContent = MemberCountShowAsSecondary ? (
    <ThemeProvider theme={{ color: textColors.fontColor5 }}>
      <StyledText>{`${membersCount} Members`}</StyledText>
    </ThemeProvider>
  ) : (
    secondaryContentContainer
  );

  const suggestedByContent = showSuggestedBy && suggestedBy ? <>{suggestedBy}</> : null;

  return (
    <ThemeProvider
      theme={{
        background: selectedUser === index ? theme.btn_background_color : 'transparent',
      }}
    >
      <StyledListItem
        onClick={() => (unsubscribeEmail || unsubscribeMobile ? {} : handleSelection(index))}
      >
        <ListItemAvatar>
          <ThemeProvider theme={DefaultAvatar}>
            <CusotmAvatar alt={name} src={`${process.env.REACT_APP_IMAGE_ENDPOINT}${src}`} />
          </ThemeProvider>
        </ListItemAvatar>
        <ThemeProvider
          theme={{
            color: textColors.fontColor3,
            secondaryFontStyle: suggestedByContent ? 'italic' : 'normal',
          }}
        >
          <StyledName
            primary={name}
            secondary={secondaryPhoneContent || secondaryLocationContent || suggestedByContent}
          />
        </ThemeProvider>
        {secondaryMemberCountContent}
      </StyledListItem>
    </ThemeProvider>
  );
}

ContactInfoList.propTypes = {
  src: Proptypes.string,
  name: Proptypes.string.isRequired,
  phone: Proptypes.string,
  index: Proptypes.number.isRequired,
  handleSelection: Proptypes.func.isRequired,
  selectedUser: Proptypes.number,
  showPhone: Proptypes.bool,
  phoneShowAsSecondary: Proptypes.bool,
  locationShowAsSecondary: Proptypes.bool,
  locations: Proptypes.arrayOf(Proptypes.object),
  unsubscribeEmail: Proptypes.bool,
  unsubscribeMobile: Proptypes.bool,
  MemberCountShowAsSecondary: Proptypes.bool,
  membersCount: Proptypes.number,
};

ContactInfoList.defaultProps = {
  src: '',
  phone: '',
  showPhone: true,
  phoneShowAsSecondary: false,
  locationShowAsSecondary: false,
  locations: [],
  unsubscribeEmail: false,
  unsubscribeMobile: false,
  MemberCountShowAsSecondary: false,
  membersCount: 0,
  suggestedBy: '',
  showSuggestedBy: false,
};

export default ContactInfoList;
