/* eslint-disable no-nested-ternary */
import {
  Box,
  ThemeProvider as MUIThemeProvider,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import RenameIcon from '@material-ui/icons/RateReview';
import EditIcon from '@material-ui/icons/Edit';
import Visibility from '@material-ui/icons/Visibility';
import ClearIcon from 'mdi-react/CloseCircleIcon';

import PageTitle from '../../../../PageTitle/PageTitle';
import SingleChoice from '../../../../SingleChoice/SingleChoice';
import baseUrl from '../../../../../utils/services/config';
import {
  errorChecker,
  getFeedbackPathFromType,
  createCampaignName,
} from '../../../../../utils/helper/helper';
import Loader from '../../../../Loader/Loader';
import useCampaigns from '../../../../../hooks/useCampaigns';
import useModal from '../../../../../hooks/useModal';
import EditCampaign from '../EditCampaign';
import usePopupTheme from '../../../../../hooks/usePopupTheme';
import useAlert from '../../../../../hooks/useAlert';
import CancellationPopup from '../../../../Popup/CancellationPopup/CancellationPopup';
import StyledTrashIcon from '../../../../Icons/TrashIcon';
import StyledTextField from '../../../../Common/StyledTextField';
import StyledSelect from '../../../../Common/StyledSelect';
import StyledMenuItem from '../../../../Common/StyledMenuItem';
import PrimaryButton from '../../../../Common/PrimaryButton';
import useInfiniteScroll from '../../../../../hooks/useInfiniteScroll';
import useUpdateInviteData from '../../../../../hooks/useUpdateInviteData';
import ButtonLoader from '../../../../ButtonLoader/ButtonLoader';
import { useDropzone } from 'react-dropzone';
import AlertCircleOutlineIcon from 'mdi-react/AlertCircleOutlineIcon';
import { NoData } from '../../../../NoData/NoData';
import CustomMessage from './MessageTabs/Tabs/TabCustom/CustomMessage';
import useCreateMessage from '../../../../../hooks/useCreateMessage';
import TemplateTitleEdit from '../../TemplateTitleEdit';
import Asynchronous from '../../../../Common/Asynchronous';
import { SurveyContext } from '../../../../../Stores/SurveyStore';
import { GlobalContext } from '../../../../../Stores/GlobalStateStore';

const useStyles = makeStyles(() => ({
  root: {
    color: '#707070',
    '&$checked': {
      color: '#36eda7',
    },
    '&:hover': {
      backgroundColor: '#f9f9f9',
    },
  },
  checked: {
    color: '#36eda7',
  },
}));

const DragOverlay = styled.div`
  position: absolute;
  inset: 0;
  background-color: #0000005c;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  justify-content: center;
  color: white;
  border-radius: 10px;
  z-index: 5;
  &::after {
    content: '';
    border: 4px dashed #fff;
    border-radius: 10px;
    position: absolute;
    inset: 2rem;
  }
`;

const StyledClearIcon = styled(ClearIcon)`
  color: #c9c9c9 !important;
  width: 18px;
  height: 18px;
`;

const StyledViewIcon = styled(Visibility)`
  color: #c9c9c9 !important;
  width: 18px;
  height: 18px;
`;

const StyledInputLabel = styled(InputLabel)`
  font-family: 'Montserrat' !important;
  color: rgba(0, 0, 0, 0.54) !important;
  font-size: '24px' !important;
`;

const StyledFormControl = styled(FormControl)`
  font-family: 'Montserrat' !important;
  font-size: 42px !important;
`;

const wrapperStyles = { position: 'relative', padding: '1rem' };
const containerStyles = { margin: '0 .5rem 1rem .5rem', height: 'auto' };
const footerStyles = { display: 'flex', justifyContent: 'space-between' };

const GenericCampaignStart = (props) => {
  const {
    handlePrevious,
    handleClose,
    handleNext,
    campaignType,
    changeStep,
    setCampaignId,
    campaignId,
    isCSV,
    setIsCSV,
  } = props;

  const { state, dispatch } = useContext(GlobalContext);
  const {
    locations,
    selectedLoaction,
    selectedBusiness,
    userRole,
    inviteData,
    groupContainer,
    roles,
    campaignLocation,
  } = state;
  const campaignsAlert = useAlert();

  const { popUpTheme } = usePopupTheme();
  const campaignDeletionPopup = useModal();
  const classes = useStyles();
  const { surveyData, surveyDispatch } = useContext(SurveyContext);

  //console.log(surveyData);

  const [showEditCampaign, setShowEditCampaign] = useState(false);
  const [campaignIdSeleted, setCampaignIdSeleted] = useState(null);

  // const [selectedLoactionState, setSelectedLoactionState] = useState(
  //   campaignLocation ? campaignLocation : selectedLoaction,
  // );

  const [customMessage, setCustomMessage] = useState('');
  const [campaignLoader, setCampaignLoader] = useState(false);
  const [surveySelected, setsurveySelected] = useState({});
  const [disabledButton, setdisabledButton] = useState(true);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [contactList, setContactList] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isCustomMessage, setIsCustomMessage] = useState(false);
  const [uploadFileValue, setUploadFileValue] = useState(null);
  const [bringingMoreResults, setBringingMoreResults] = useState(false);
  const [shouldSearch, setShouldSearch] = useState(true);
  const [searchTerms, setSearchTerms] = useState({
    page: 0,
    count: 0,
    perPage: 30,
    text: '',
  });
  const [timer, setTimer] = useState(null);
  const [isSuggestionsVisible, setIsSuggestionsVisible] = useState(false);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [uploadStats, setUploadStats] = useState(
    surveyData.selectedContacts.length > 0
      ? `${surveyData.selectedContacts.length} uploaded contacts`
      : null,
  );

  const [shouldSaveCampaign, setShouldSaveCampaign] = useState(false);
  const [selectedValue, setSelectedValue] = useState();

  // const MINIMUN_RANGE_OF_STRING_TO_SEARCH = 2;

  // const searchFieldRef = useRef(null);
  const csvUpload = useRef(null);

  const { campaigns, fetchCampaigns, isLoadingCampaigns } = useCampaigns();

  const { createMessage, isCreateMessageLoading } = useCreateMessage();

  const [customCampaign, setCustomCampaign] = useState({ name: '', uploadedFile: [] });

  const isSurveySelected = Object.entries(surveySelected).length;
  const isContactSelected = Object.entries(surveyData.selectedContacts).length;

  // const handleLocationChange = (event) => {
  //   const newLocation = Number(event.target.value);
  //   setSelectedLoactionState(newLocation);
  //   dispatch({ type: 'SET_CAMPAIGN_LOCATION', payload: newLocation });
  //   getCampaigns(newLocation); // Pass the new location directly
  // };

  const getCampaigns = (theLocation) => {
    if (campaignType === 'survey') {
      fetchCampaigns('survey', theLocation);
    }
    if (campaignType === 'message') {
      fetchCampaigns('message', theLocation);
    }
    if (campaignType === 'review') {
      fetchCampaigns('review request', theLocation);
    }
  };

  const handleScrollDown = () => {
    setBringingMoreResults(true);
  };

  const { scrollRef } = useInfiniteScroll(handleScrollDown);

  const canEdit = userRole.id < 3;

  //THIS USEEFFECT USES THE NEWLY CREATED CONTACT
  useEffect(() => {
    if (inviteData.length === 0) return;
    setSelectedValue(inviteData);
    dispatch({ type: 'SET_INVITE_DATA', payload: [] });
  }, [inviteData]);

  const clearSearchParameters = () => {
    setSearchTerms({
      page: 0,
      count: 0,
      text: '',
    });
    surveyDispatch({
      type: 'SET_SELECTED_CONTACTS',
      payload: [],
    });
  };

  const handleFileImport = async (fileContainer) => {
    try {
      const clients = new FormData();
      clients.append('clients', fileContainer);
      const result = await baseUrl.post(
        `group/client/preview?location=${selectedLoaction}&business=${selectedBusiness}`,
        clients,
      );
      if (result.status === 200) {
        setIsCSV(true);

        surveyDispatch({
          type: 'SET_SELECTED_CONTACTS',
          payload: result.data,
        });
        csvUpload.current.value = `${result.data.length} uploaded contacts`;
        result.data && setUploadStats(`${result.data.length} uploaded contacts`);

        setShouldSearch(false);
      }
    } catch (err) {
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  const handleGetFile = (file) => {
    setUploadFileValue(file[0].name);
    handleFileImport(file[0]);
    setShouldSearch(false);
  };

  const updateInviteData = useUpdateInviteData();

  const handleCancel = () => {
    surveyDispatch({
      type: 'SET_SELECTED_CONTACTS',
      payload: [],
    });
    clearSearchParameters();
    handleClose();
  };

  const handleClearUploads = () => {
    console.log('handleClearUploads');
    surveyDispatch({
      type: 'SET_SELECTED_CONTACTS',
      payload: [],
    });
    setIsCSV(false);
    setUploadStats([]);
  };

  const reviewsrequest = async (postBody) => {
    const result = await baseUrl.post(`/reviewsrequest`, { ...postBody });
    if (result.status === 200) {
      surveyDispatch({
        type: 'SET_SELECTED_CONTACTS',
        payload: [],
      });
      // const totalSent = surveyData.selectedContacts.length;

      // const getMessageTitle = () => {
      //   switch (campaignType) {
      //     case 'message':
      //       return `${totalSent} ${totalSent > 1 ? 'Messages' : 'Message'} Sent`;
      //     case 'survey':
      //       return `${totalSent} ${totalSent > 1 ? 'Surveys' : 'Survey'} Sent`;
      //     case 'review':
      //       return `${totalSent} Review ${totalSent > 1 ? 'Requests' : 'Request'} Sent`;
      //   }
      // };
      setIsCSV(false);

      //Delete the custom message if the user did not save it
      if (!shouldSaveCampaign && customMessage?.length) {
        setShouldSaveCampaign(false);
        const campaignToDelete = result.data.campaign[0].id;
        const postBody = {
          isDeleted: true,
          isActive: false,
        };

        await baseUrl.patch(`/campaign/${campaignToDelete}`, { ...postBody });
      }

      //setCampaignLoader(false);

      handleClose();
      // surveyDispatch({
      //   type: 'SET_STATUS_MESSAGE',
      //   payload: {
      //     redirectTo: getFeedbackPathFromType(campaignType),
      //     statusMessage: getMessageTitle(),
      //   },
      // });
    }
  };

  const uploadPreview = (clients, campaignId) => {
    baseUrl
      .post(
        `group/client/upload-preview?business=${selectedBusiness}&location=${selectedLoaction}`,
        clients,
      )
      .then(({ data }) => {
        const postBody = {
          client: data.map((client) => client.id),
          user: parseInt(localStorage.getItem('USER_ID'), 10),
          campaign: campaignId,
          location: selectedLoaction,
          preview: false,
        };
        reviewsrequest(postBody);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSendCampaign = async (event) => {
    try {
      setCampaignLoader(true);
      let postBody;
      if (isCSV) {
        uploadPreview(surveyData.selectedContacts, surveySelected.id);
      } else {
        postBody = {
          client: surveyData.selectedContacts[0].id,
          location: selectedLoaction,
          user: parseInt(localStorage.getItem('USER_ID'), 10),
          campaign: surveySelected.id,
          preview: false,
        };
        reviewsrequest(postBody);
      }
      setIsCSV(false);
      changeStep(0);
      surveyDispatch({
        type: 'SET_SELECTED_CONTACTS',
        payload: [],
      });
      handleClose();
    } catch (err) {
      setCampaignLoader(false);
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  const handleSendCustomMessage = async () => {
    const messageCampaign = {
      name: customCampaign.name ? customCampaign.name : createCampaignName(),
      message: customMessage,
      uploadedFile: customCampaign.uploadedFile,
    };

    const newCampaign = await createMessage(messageCampaign, shouldSaveCampaign, handleClose);

    try {
      setCampaignLoader(true);
      let postBody;
      setsurveySelected({ id: newCampaign.id });

      if (isCSV) {
        uploadPreview(surveyData.selectedContacts, newCampaign.id);
      } else {
        postBody = {
          client: surveyData.selectedContacts.map((contact) => contact.id),
          location: selectedLoaction,
          user: parseInt(localStorage.getItem('USER_ID'), 10),
          campaign: newCampaign.id,
        };
        reviewsrequest(postBody);
      }

      setIsCSV(false);
      changeStep(0);
      handleClose();
    } catch (err) {
      setCampaignLoader(false);
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  const handleCopy = (e) => {
    setCampaignId(surveySelected.id);
    setCampaignIdSeleted(surveySelected.id);
    changeStep(3);
  };
  const handleCreateaNewTemplate = () => {
    setCampaignId(null);
    setCampaignIdSeleted(null);
    changeStep(3);
  };
  const handleRename = () => {
    setCampaignIdSeleted(surveySelected.id);
    setShowEditCampaign(true);
  };
  const handleDelete = () => {
    setCampaignIdSeleted(surveySelected.id);
    campaignDeletionPopup.handleShowModal();
  };

  const handleCampaignRenamePopup = async (name) => {
    const { handleSetMessage, handleSetMessageType } = campaignsAlert;
    try {
      setDeleteLoader(true);
      const postBody = {
        name,
      };
      const result = await baseUrl.patch(`/campaign/${campaignIdSeleted}`, { ...postBody });
      if (result.status === 200) {
        handleSetMessageType('success');
        handleSetMessage('Updated campaign');
        setDeleteLoader(false);
        getCampaigns();
      }
    } catch (err) {
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      setDeleteLoader(false);
    }
  };

  const handleCreateNewContact = () => {
    updateInviteData({});

    updateInviteData(searchTerms.text);
    //console.log(searchTerms);
    changeStep(2);
  };

  const handleEditCampaingSubmit = (value) => {
    handleCampaignRenamePopup(value);
  };

  const handleCampaignDeletionPopup = async () => {
    const { handleHideShowModal } = campaignDeletionPopup;
    const { handleSetMessage, handleSetMessageType, handleShow } = campaignsAlert;
    try {
      setDeleteLoader(true);
      const postBody = {
        isDeleted: true,
      };
      const result = await baseUrl.patch(`/campaign/${campaignIdSeleted}`, { ...postBody });
      if (result.status === 200) {
        handleSetMessageType('success');
        handleSetMessage('Campaign deleted successfully');
        handleHideShowModal();
        handleShow(true);
        setDeleteLoader(false);
        getCampaigns();
      }
    } catch (err) {
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      setDeleteLoader(false);
    }
  };

  const validateRender = (textSearch) => {
    return textSearch.length > 0;
  };

  useEffect(() => {
    if (isSurveySelected && isContactSelected) {
      setdisabledButton(false);
    } else {
      setdisabledButton(true);
    }
  }, [surveySelected, surveyData]);

  useEffect(() => {
    getCampaigns();
  }, []);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setIsCSV(true);
      handleGetFile(acceptedFiles);
      setOverlayVisible(false);
    },
    [handleGetFile, setOverlayVisible],
  );

  const onDragEnter = useCallback(() => {
    setOverlayVisible(true);
  }, []);

  const onDragLeave = useCallback(() => {
    setOverlayVisible(false);
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    onDragEnter,
    onDragLeave,
    noClick: true,
  });

  const endAdornment = () => {
    let content;
    if (searchTerms.text.length > 0 && !searchTerms.text.includes('uploaded')) {
      content = (
        <>
          {isLoading ? <ButtonLoader /> : null}
          <IconButton onClick={() => clearSearchParameters()} title="Clear">
            <StyledClearIcon />
          </IconButton>
        </>
      );
    } else if (uploadStats && uploadStats.length) {
      content = (
        <>
          <IconButton onClick={handleNext}>
            <StyledViewIcon />
          </IconButton>
          <IconButton onClick={() => handleClearUploads()} title="Clear">
            <StyledClearIcon />
          </IconButton>
        </>
      );
    } else {
      content = <></>;
    }
    return <InputAdornment position="end">{content}</InputAdornment>;
  };

  const renderCampaigns = () => {
    if (!campaigns.length > 0) {
      return;
    }
    const sortedCampaigns = [...campaigns].sort((a, b) => a.name.localeCompare(b.name));

    return sortedCampaigns.length > 0 ? (
      <SingleChoice
        options={sortedCampaigns.map((campaign) => ({
          id: campaign.id,
          value: campaign.id,
          label: campaign.name,
          isDefault: campaign.isDefault,
          uploadedFile: campaign?.uploadedFile[0]?.url,
          settings: campaign.settings,
        }))}
        optionsOnPress={[
          {
            text: 'Edit',
            onPress: handleCopy,
            Icon: EditIcon,
          },
          {
            text: 'Rename',
            onPress: handleRename,
            Icon: RenameIcon,
          },
          {
            text: 'Delete',
            onPress: handleDelete,
            Icon: StyledTrashIcon,
          },
        ]}
        currentOption={surveySelected}
        setCurrentOption={setsurveySelected}
        setCampaignId={setCampaignId}
        campaignId={campaignId}
        withRadioButton
      />
    ) : (
      <NoData
        style={{ height: 'calc(100% - 100px)' }}
        icon={<AlertCircleOutlineIcon />}
        title={`No ${campaignType} templates found`}
        handleClick={handleCreateaNewTemplate}
      ></NoData>
    );
  };

  const handleShouldSaveCampaign = () => {
    setShouldSaveCampaign(true);
  };

  const handleMessageTitleChange = (value) => {
    setCustomCampaign((prevState) => ({
      ...prevState,
      name: value,
    }));
  };

  return (
    <Box>
      {campaignLoader ? (
        <Loader />
      ) : (
        <Box style={wrapperStyles} {...getRootProps()}>
          <input {...getInputProps()} />
          <Box style={containerStyles}>
            {shouldSaveCampaign ? (
              <TemplateTitleEdit
                campaign={customCampaign}
                handleChange={handleMessageTitleChange}
                type="Message"
              />
            ) : (
              <Box margin=".5rem 0 1.5rem 0">
                <PageTitle title={`${campaignType}`} />
              </Box>
            )}

            <Box style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
              <StyledFormControl fullWidth>
                {!isCSV ? (
                  <Asynchronous
                    createNewContact={handleCreateNewContact}
                    searchTerms={searchTerms}
                    setSearchTerms={setSearchTerms}
                    updateInviteData={updateInviteData}
                    selectedValue={selectedValue}
                    setSelectedValue={setSelectedValue}
                  />
                ) : (
                  <StyledTextField
                    label={'Uploaded Recipients'}
                    ref={csvUpload}
                    value={uploadStats}
                    InputProps={{ endAdornment: endAdornment() }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              </StyledFormControl>
              {/* <FormControl fullWidth>
                <StyledInputLabel>Location</StyledInputLabel>
                <StyledSelect value={String(selectedLoaction)} onChange={handleLocationChange}>
                  {locations.map((location) => (
                    <StyledMenuItem key={location.id} value={location.id}>
                      {location.nickName}
                    </StyledMenuItem>
                  ))}
                </StyledSelect>
              </FormControl> */}
            </Box>
            <Box
              style={{
                overflowY: 'auto',
                height: campaignType === 'message' ? 'auto' : '21rem',
                marginTop: '1rem',
              }}
            >
              <MUIThemeProvider theme={popUpTheme}>
                <CancellationPopup
                  show={campaignDeletionPopup.showModal}
                  showDeletionPopUp={handleCampaignDeletionPopup}
                  handleClose={() => campaignDeletionPopup.handleHideShowModal()}
                  deleteLoader={deleteLoader}
                />
              </MUIThemeProvider>

              <EditCampaign
                handleClose={() => {
                  setCampaignId(null);
                  setShowEditCampaign(false);
                }}
                open={showEditCampaign}
                handleSubmit={handleEditCampaingSubmit}
                isEditName
              />
              {isLoadingCampaigns && campaignType !== 'message' ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '100%',
                    alignItems: 'center',
                  }}
                >
                  <CircularProgress />
                </div>
              ) : campaignType === 'message' ? (
                <CustomMessage
                  showEditCampaign={showEditCampaign}
                  setShowEditCampaign={setShowEditCampaign}
                  surveyData={surveyData}
                  setSurveySelected={setsurveySelected}
                  customMessage={customMessage}
                  setCustomMessage={setCustomMessage}
                  isSuggestionsVisible={isSuggestionsVisible}
                  shouldSaveCampaign={shouldSaveCampaign}
                  setShouldSaveCampaign={setShouldSaveCampaign}
                  customCampaign={customCampaign}
                  setCustomCampaign={setCustomCampaign}
                  campaigns={campaigns}
                />
              ) : (
                renderCampaigns()
              )}
            </Box>
          </Box>

          <Box style={footerStyles}>
            <div style={{ display: 'flex', gap: '.5rem' }}>
              <PrimaryButton outlined onClick={open}>
                Upload
              </PrimaryButton>
              {customMessage?.length && Object.keys(surveySelected).length === 0 ? (
                <PrimaryButton
                  outlined
                  onClick={() => handleShouldSaveCampaign()}
                  disabled={shouldSaveCampaign}
                >
                  Save
                </PrimaryButton>
              ) : null}
            </div>
            <div style={{ display: 'flex', gap: '.5rem' }}>
              <PrimaryButton text onClick={handleCancel}>
                Cancel
              </PrimaryButton>
              {customMessage?.length ? (
                <PrimaryButton
                  disabled={!isContactSelected || !customMessage}
                  onClick={handleSendCustomMessage}
                >
                  {`Send Custom Message`}
                </PrimaryButton>
              ) : (
                <PrimaryButton
                  disabled={disabledButton}
                  onClick={(event) => handleSendCampaign(event)}
                >
                  {`Send ${campaignType}`}
                </PrimaryButton>
              )}
            </div>
          </Box>
          {overlayVisible ? <DragOverlay>Drop to upload</DragOverlay> : null}
        </Box>
      )}
    </Box>
  );
};

GenericCampaignStart.propTypes = {
  handlePrevious: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
};
export default GenericCampaignStart;
