import React from 'react';
import Proptypes from 'prop-types';
import ChatGpt from '../Icons/ChatGpt';
import { Button, CircularProgress, IconButton } from '@material-ui/core';
import AutoFix from 'mdi-react/AutoFixIcon';
import styled from 'styled-components';

const StyledCircularProgress = styled(CircularProgress)`
  color: ${(props) => (props.theme.color ? props.theme.color : '#000')} !important;
`;

function ButtonOpenAi(props) {
  const { handleOnClick, title, width, height, size, isLoading, disabled } = props;

  return (
    <IconButton onClick={handleOnClick} size={size} title={title} disabled={disabled}>
      {!isLoading ? <AutoFix fontSize={'small'} /> : <StyledCircularProgress size={width} />}
    </IconButton>
  );
}

ButtonOpenAi.propTypes = {
  handleOnClick: Proptypes.func,
  title: Proptypes.string,
  width: Proptypes.string,
  height: Proptypes.string,
  color: Proptypes.string,
  isLoading: Proptypes.bool,
};

export default ButtonOpenAi;
