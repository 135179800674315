import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Grid, FormControlLabel, ThemeProvider } from '@material-ui/core';
import StyledCheckbox from '../../../../../../../StyledCheckbox/StyledCheckbox';
import useCheckBoxTheme from '../../../../../../../../hooks/useCheckBoxTheme';

const TabNotifications = ({ campaign, setCampaign, users }) => {
  const { checkBoxTheme } = useCheckBoxTheme();
  const [selectedNotifications, setSelectedNotifications] = useState(
    campaign.settings?.notifications || [],
  );

  const StyledDescriptionText = styled.div`
    font-size: 14px;
    margin: 1.5rem 0 0 0;
    font-weight: 600;
    color: #6c6c6c;
  `;

  const StyledEmail = styled.span`
    color: grey;
  `;

  useEffect(() => {
    setCampaign({
      ...campaign,
      settings: {
        ...campaign.settings,
        notifications: selectedNotifications,
      },
    });
  }, [selectedNotifications]);

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedNotifications([...selectedNotifications, Number(value)]);
    } else {
      setSelectedNotifications(selectedNotifications.filter((item) => item !== Number(value)));
    }
  };

  return (
    <Grid container style={{ overflowY: 'auto' }} spacing={2}>
      <Grid item xs={12}>
        <StyledDescriptionText>
          Select the users to notify when a survey trigger is activated
        </StyledDescriptionText>
      </Grid>
      <Grid item xs={12} style={{ marginLeft: '.5rem' }}>
        <Grid container>
          {users.map((user) => (
            <Grid item xs={6} key={user.id}>
              <FormControlLabel
                control={
                  <ThemeProvider theme={checkBoxTheme}>
                    <StyledCheckbox
                      value={user.id}
                      checked={selectedNotifications.includes(user.id)}
                      onChange={handleCheckboxChange}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </ThemeProvider>
                }
                label={
                  <>
                    {user.contact.fullName} <StyledEmail>({user.emailAddress})</StyledEmail>
                  </>
                }
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TabNotifications;
