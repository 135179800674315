/* eslint-disable */
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import HistoryIcon from '@material-ui/icons/History';
// import CodeIcon from '@material-ui/icons/Code';
import CustomButton from '../components/CustomButton/CustomButton';
import ButtonLoader from '../components/ButtonLoader/ButtonLoader';
import TransperentButtonTheme from '../themes/Button/TransperentButtonTheme';
import StyledTrashIcon from '../components/Icons/TrashIcon';
import StyledTableCell from '../components/TableRowCell';
import StyledEditIcon from '../components/Icons/EditIcon';

const StyledHyperLink = styled.a`
  color: #0dc47e;
  font-family: 'Montserrat';
`;

const getLocationColumns = (
  locationsContainer,
  handleEdit,
  handleDelete,
  iconsTheme,
  handleReactive,
) => [
  {
    name: 'nickname',
    label: 'Display Name',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => {
        return <StyledTableCell>{value}</StyledTableCell>;
      },
    },
  },
  {
    name: 'address',
    label: 'Address',
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value) => {
        return <StyledTableCell>{value}</StyledTableCell>;
      },
    },
  },
  {
    name: 'phone',
    label: 'Phone',
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value) => {
        return <StyledTableCell>{value}</StyledTableCell>;
      },
    },
  },
  // {
  //   name: 'email',
  //   label: 'Email',
  //   options: {
  //     filter: true,
  //     sort: false,
  //     customBodyRender: (value) => {
  //       return <StyledTableCell>{value}</StyledTableCell>;
  //     },
  //   },
  // },
  // {
  //   name: 'type',
  //   label: 'Type',
  //   options: {
  //     filter: true,
  //     sort: false,
  //     customBodyRender: (value) => {
  //       return <StyledTableCell>{value || <ButtonLoader />}</StyledTableCell>;
  //     },
  //   },
  // },
  // {
  //   name: 'status',
  //   label: 'Status',
  //   options: {
  //     filter: true,
  //     sort: false,
  //     customBodyRender: (value) => {
  //       return <StyledTableCell>{value}</StyledTableCell>;
  //     },
  //   },
  // },
  {
    name: 'microSiteUrl',
    label: 'MicroSite',
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value) => {
        return (
          <div style={{ display: 'flex', textAlign: 'center' }}>
            <StyledHyperLink href={value} target="__blank" style={{ color: '#49d6a0' }}>
              View
            </StyledHyperLink>
          </div>
        );
      },
    },
  },
  // {
  //   name: 'uiId',
  //   label: 'Code',
  //   options: {
  //     filter: true,
  //     sort: false,
  //     customBodyRenderLite: (dataIndex) => {
  //       return (
  //         <ThemeProvider theme={TransperentButtonTheme}>
  //           <CustomButton onClick={() => handleCodeCopy(dataIndex)} title="Copy">
  //             <CodeIcon
  //               style={{
  //                 width: '16px',
  //                 color: 'rgb(191, 191, 191)',
  //                 float: 'right',
  //                 margin: ' 0',
  //                 height: '17px',
  //                 cursor: 'pointer',
  //               }}
  //             />
  //           </CustomButton>
  //         </ThemeProvider>
  //       );
  //     },
  //   },
  // },
  {
    name: '',
    label: 'Action',
    options: {
      filter: false,
      sort: false,
      searchable: false,
      customBodyRenderLite: (dataIndex, rowIndex) => {
        return (
          <div style={{ display: 'flex' }}>
            {locationsContainer.length ? (
              <div style={{ display: 'flex' }}>
                <ThemeProvider theme={{ ...TransperentButtonTheme, color: iconsTheme.editIcon }}>
                  <CustomButton onClick={() => handleEdit(dataIndex, rowIndex)} title="Edit">
                    <StyledEditIcon />
                  </CustomButton>
                </ThemeProvider>
                <ThemeProvider
                  theme={{ ...TransperentButtonTheme, color: iconsTheme.trashIconTheme }}
                >
                  <CustomButton onClick={() => handleDelete(dataIndex, rowIndex)} title="Delete">
                    <StyledTrashIcon />
                  </CustomButton>
                </ThemeProvider>
              </div>
            ) : (
              <div style={{ display: 'flex' }}>
                <ThemeProvider theme={{ ...TransperentButtonTheme, color: iconsTheme.editIcon }}>
                  <CustomButton onClick={() => handleEdit(dataIndex, rowIndex)} title="Edit">
                    <StyledEditIcon />
                  </CustomButton>
                </ThemeProvider>
                <ThemeProvider
                  theme={{ ...TransperentButtonTheme, color: iconsTheme.trashIconTheme }}
                >
                  <CustomButton
                    onClick={() => handleReactive(dataIndex, rowIndex)}
                    title="Re-Active"
                  >
                    <HistoryIcon />
                  </CustomButton>
                </ThemeProvider>
              </div>
            )}
          </div>
        );
      },
    },
  },
];

export default getLocationColumns;
