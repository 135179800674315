import React from 'react';
import { makeStyles } from '@material-ui/styles';
import styled from 'styled-components';

const { TextField } = require('@material-ui/core');

const useStyles = makeStyles((theme) => ({
  root: {
    '& label': {
      color: theme.inputLabelColor,
      fontFamily: 'Montserrat !important',
      fontSize: '16px !important',
    },
    '& .Mui-focused.Mui-error label': {
      color: '#f44336',
    },
    '&> div:before': {
      borderBottomColor: theme.inputBorderBottomBefore,
    },
    '& >div:after': {
      borderBottomColor: theme.inputBorderBottomAfter,
    },
    '& >div:hover:not(.Mui-disabled):before': {
      borderBottomColor: theme.inputOnFocusBefore,
    },
    '& > div > input': {
      color: `${theme.inputTextColor} !important`,
      fontFamily: 'Montserrat !important',
      fontWeight: '500 !important',
      fontSize: '14px !important',
    },
    '  & > div >  textarea': {
      color: `${theme.inputTextColor} !important`,
      fontFamily: 'Montserrat !important',
      fontWeight: '500 !important',
      fontSize: '14px !important',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&:hover fieldset': {
        borderColor: 'yellow',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'green',
      },
    },
  },
}));

const StyledTextFieldContainer = styled(TextField)`
  width: -webkit-fill-available !important;
  margin: ${(props) => props.theme.inputMargin || '1.25em 0 0.5em'} !important;
  font-size: ${(props) => props.theme.fontSize || '16px'} !important;
  input[type='number'] {
    -moz-appearance: textfield !important;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;

    margin: 0;
  }
  & > div {
    &:before {
      border: ${(props) => props.theme.border} !important;
      border-bottom: ${(props) => props.theme.borderBottom} !important;
    }
  }
  & > div > textarea {
    margin: 0 !important;
    padding: ${(props) => props.theme.padding} !important;
    text-align: ${(props) => props.theme.textAlign} !important;
    font-size: 14px !important;
    border-bottom: none !important;
  }
`;

const StyledTextField = (props) => {
  const classes = useStyles();
  return <StyledTextFieldContainer className={classes.root} {...props} />;
};

export default StyledTextField;
