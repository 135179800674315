import { Editor } from 'react-draft-wysiwyg';
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyleContainerEmpty = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100%;
`;
const StyleContainerEmptyCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 70%;
  row-gap: 0.5rem;
`;
const StyleEmptyCenterTitle = styled.div`
  font-size: 24px;
  text-align: center;
`;
const StyleEmptyCenterDescription = styled.div`
  font-size: 14px;
  text-align: center;
  color: #808080;
`;
const StyleContainer = styled.div`
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;
const StyleContainerPadding = styled.div`
  padding: 1rem;
`;

const Preview = ({ previewEmailBody, previewEmailFooter, uploadedFile, noEmptyTitle = false }) => {
  const isEditorStateEmpty = (editorState) => {
    const contentState = editorState.getCurrentContent();
    return !contentState.hasText();
  };

  return (
    <>
      {isEditorStateEmpty(previewEmailBody) &&
      isEditorStateEmpty(previewEmailFooter) &&
      !uploadedFile ? (
        <StyleContainerEmpty>
          {!noEmptyTitle && (
            <StyleContainerEmptyCenter>
              <StyleEmptyCenterTitle>Welcome to the email template builder</StyleEmptyCenterTitle>
              <StyleEmptyCenterDescription>
                Get started by uploading an image or logo, set a subject, enter an email body and an
                optional footer
              </StyleEmptyCenterDescription>
            </StyleContainerEmptyCenter>
          )}
        </StyleContainerEmpty>
      ) : (
        <StyleContainer>
          <StyleContainerPadding>
            {uploadedFile && (
              <img
                src={
                  uploadedFile ? `${process.env.REACT_APP_IMAGE_ENDPOINT}${uploadedFile.url} ` : ''
                }
                alt="logo"
                style={{ width: '100%', height: 'auto', margin: '0 auto', display: 'flex' }}
              />
            )}

            <Editor
              editorStyle={{
                fontSize: '.875rem',
              }}
              toolbarHidden
              editorState={previewEmailBody}
              readOnly
            />
          </StyleContainerPadding>
          <StyleContainerPadding style={{ height: '56px', marginTop: 'auto' }}>
            <Editor
              editorStyle={{
                minHeight: '56px',
                fontSize: '.875rem',
              }}
              toolbarHidden
              editorState={previewEmailFooter}
              readOnly
            />
          </StyleContainerPadding>
        </StyleContainer>
      )}
    </>
  );
};

Preview.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  previewEmailBody: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  previewEmailFooter: PropTypes.any,

  // eslint-disable-next-line react/forbid-prop-types
  uploadedFile: PropTypes.object,
};

Preview.defaultProps = {
  previewEmailBody: null,
  previewEmailFooter: null,
  uploadedFile: null,
};

export default React.memo(Preview);
