/* eslint-disable */
import React, { Suspense, useEffect, useContext, useCallback, useState } from 'react';
import {
  ThemeProvider as MUIThemeProvider,
  Box,
  Avatar,
  Divider,
  Snackbar,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';
import { Switch, Route, Redirect, useHistory, NavLink } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import AppContainer from './layout/AppContainer';
import MainView from './layout/MainView';
import TopNavBar from './layout/TopNavBar';
import CustomHeaderNavBar from './components/CustomHeaderNavBar/CustomHeaderNavBar';
import Loader from './components/Loader/Loader';
import baseUrl from './utils/services/config';
import { GlobalContext } from './Stores/GlobalStateStore';
import logo from './assets/images/reveo_icon.svg';
import {
  setAuthToken,
  roleChecker,
  businessAdminRoleChecker,
  errorChecker,
  roleHandling,
  getLocalStorageItem,
  ConvertIntoUserDropDown,
} from './utils/helper/helper';
import CusotmAlert from './components/CustomAlert/CustomAlert';
import { OnBoardingContext } from './Stores/OnBoarding';
import OnBoardingContainer from './pages/OnBorad/OnBoardingContainer';
import CampaignStore from './Stores/CamapignStore';
import SocketStore, { SocketContext } from './Stores/SocketStore';
import PermissionStore from './Stores/PermissionStore';
import DashboardStore from './Stores/DashboardStore';
import PrivacyLinkPopup from './components/Popup/PrivacyLinkPopup/PrivacyLinkPopup';
import TermsOfUsePopup from './components/Popup/TermsOfUsePopup/TermsOfUsePopup';
import useModal from './hooks/useModal';
import TransperentButtonTheme from './themes/Button/TransperentButtonTheme';
import SurveyStore from './Stores/SurveyStore';
import GenricPopup from './components/Popup/GenricPopup';
import SendInviteContainer from './pages/OnBorad/SendInviteContainer';
import StyledBadge from './components/Badge/StyledBadge';
import usePopover from './hooks/usePopover';
import NotificationPopover from './components/Popover/NotificationPopover';
import ButtonLoader from './components/ButtonLoader/ButtonLoader';
import GroupContainer from './pages/Group/GroupContainer';
import useFetch from './hooks/useFetch';
import { useFetchConversations } from './hooks/useFetchConversations';
import globalState from './state/GlobalState';
// import StyledWbSunnyIcon from './components/Icons/WbSunny';
// import StyledNightsStayIcon from './components/Icons/NightStayIcon';
// import { lightTheme, darkTheme } from './setup';
import usePopOverTheme from './hooks/usePopOverTheme';
import usePopupTheme from './hooks/usePopupTheme';
import ReportsStore from './Stores/ReportsStore';
// import useIconTheme from './hooks/useIconTheme';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import Settings from 'mdi-react/SettingsOutlineIcon';
import LogoutIcon from 'mdi-react/LogoutIcon';
import NotificationIcon from 'mdi-react/NotificationsIcon';
import SendInviteIcon from 'mdi-react/SendIcon';
import UserPopover from './components/Popover/UserPopover/UserPopover';
import EditUserPopup from './components/Popup/EditUserPopup/EditUserPopup';
import NotificationPreferencesPopover from './components/Popover/NotificationPreferencesPopover/NotificationPreferencesPopover';
import SendInvitePopover from './components/Popover/SendInvitePopover/SendInvitePopover';
import StatusMessage from './components/StatusMessage';
import StyledCheckCircle from './components/Icons/CheckedCircle';
import useIconTheme from './hooks/useIconTheme';
import PrimaryButton from './components/Common/PrimaryButton';
import { SurveyContext } from './Stores/SurveyStore';

const StyledIconButton = styled.div`
  margin: 0 0.25rem;
  cursor: pointer;
  padding: 6px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #30feaf;
    svg {
      fill: #000;
    }
  }
`;

const StyledSendButton = styled.div`
  background-color: #30feaf;
  border-radius: 5px;
  padding: 5px 0 5px 10px;
  word-wrap: none;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  white-space: nowrap;
  & svg {
    margin-right: 5px;
  }
`;

const buttonLoaderTheme = {
  width: '10px',
  height: '10px',
  color: '#fff',
  margin: '0',
};

const StyledAvatar = styled(Avatar)`
  cursor: pointer !important;
  width: 32px !important;
  height: 32px !important;
  margin-left: 10px;
`;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function App() {
  const history = useHistory();
  const [disableSendInvite, setDisableSendInvite] = useState(true);
  // Group view decider state
  const [isNewGroup, setIsNewGroup] = useState(false);
  const [isEditGroup, setIsEditGroup] = useState(false);
  const { firstName, lastName } = JSON.parse(localStorage.getItem('USER_INFO'));
  const userName = `${firstName} ${lastName}`;
  const { state, dispatch } = useContext(GlobalContext);
  const { onBoardingData, onBoardingDispatch } = useContext(OnBoardingContext);
  const { isWizardMode, showInvitePopup } = onBoardingData;
  const privacyPopup = useModal();
  const termsOfUsePopup = useModal();
  const newConversationPopup = useModal();
  const notificationPopover = usePopover();
  const { socketState, socketDispatch } = useContext(SocketContext);
  const { fetchSelectedGroupClient } = useFetch();
  const { documentTitle, faviconHref, campaignSendUpdate, forceLogout } = socketState;
  const userPopover = usePopover();
  const sendInvitePopover = usePopover();
  const { iconsTheme } = useIconTheme();
  const { surveyData, surveyDispatch } = useContext(SurveyContext);
  const [showSendStatus, setShowSendStatus] = useState(false);

  const editEmployeePopup = useModal();

  const notificationPreferences = useModal();

  const {
    businesses,
    selectedBusiness,
    token,
    locations,
    profilePic,
    showError,
    errorMessage,
    selectedLoaction,
    notificationContainer,
    notificationLoader,
    selectedGroupId,
    groupContainer,
    routes,
    themeContainer,
    alertMessageType,
    roles,
    userInfo,
  } = state;

  const { fetchConversations } = useFetchConversations('open', 'all');

  const { popOverTheme } = usePopOverTheme();
  const { popUpTheme } = usePopupTheme();
  // const { iconsTheme } = useIconTheme();

  useEffect(() => {
    const preferredBusiness = parseInt(localStorage.getItem('SELECTED_BUSINESS'));
    const preferredLocation = parseInt(localStorage.getItem('SELECTED_LOCATION'));

    if (preferredBusiness && userInfo?.business.some((biz) => biz.id === preferredBusiness)) {
      dispatch({ type: 'SET_BUSINESS', payload: preferredBusiness });
    } else if (userInfo?.business.length > 0) {
      dispatch({ type: 'SET_BUSINESS', payload: userInfo.business[0].id });
      localStorage.setItem('SELECTED_BUSINESS', userInfo.business[0].id);
    }

    if (preferredLocation && userInfo?.location.some((loc) => loc.id === preferredLocation)) {
      dispatch({ type: 'SET_LOCATION', payload: preferredLocation });
    } else if (userInfo?.location.length > 0) {
      dispatch({ type: 'SET_LOCATION', payload: userInfo.location[0].id });
      localStorage.setItem('SELECTED_LOCATION', userInfo.location[0].id);
    }
  }, [userInfo]);

  if (token) {
    setAuthToken(token);
  }

  document.title = documentTitle;
  const favicon = document.getElementById('favicon');
  favicon.href = faviconHref;

  ///////////////// START CONVERSATIONS /////////////////

  useEffect(() => {
    if (selectedLoaction) {
      fetchConversations();
      socketDispatch({ type: 'SET_SELECTED_CONVERSATION', payload: 0 });
      socketDispatch({ type: 'UPDATE_DOCUMENT_TITLE', payload: 0 });
    }
  }, [selectedLoaction, fetchConversations, socketDispatch]);

  ///////////////// END CONVERSATIONS /////////////////
  useEffect(() => {
    if (campaignSendUpdate && selectedLoaction === campaignSendUpdate.location) {
      let snackbarTimeout;

      if (Object.keys(campaignSendUpdate).length > 0) {
        setShowSendStatus(true);
      }
      return () => {
        clearTimeout(snackbarTimeout);
      };
    }
  }, [selectedLoaction, campaignSendUpdate]);

  useEffect(() => {
    if (forceLogout) {
      handleLogout();
    }
  }, [forceLogout]);

  const { routesContainer, routeRedirectContainer, navListContainer } = routes;

  const fetchBusinessDetails = useCallback(async () => {
    if (selectedBusiness) {
      try {
        const results = await baseUrl(`/business/${selectedBusiness}`);
        if (results.status === 200) {
          dispatch({
            type: 'SET_BUSINESS_INFO',
            payload: results.data,
          });
          if (results.data.googleAccount.length) {
            dispatch({
              type: 'SET_GOOGLE_ACCOUNT_ID',
              payload: results.data.googleAccount[0].accountId,
            });
          } else {
            dispatch({ type: 'SET_GOOGLE_ACCOUNT_ID', payload: null });
          }
          if (results.data.facebookAccount.length) {
            dispatch({
              type: 'SET_FACEBOOK_ACCOUNT_ID',
              payload: results.data.facebookAccount[0].userId,
            });
          } else {
            dispatch({ type: 'SET_FACEBOOK_ACCOUNT_ID', payload: null });
          }
          // await baseUrl
          //   .post(`dashboard/chat-connection`, { businessUuid: results.data.uuid })
          //   .then((res) => {
          //     console.log(res.data)
          //   })
        }
      } catch (err) {
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      }
    }
  }, [selectedBusiness, dispatch]);

  const fetchLocationDetails = useCallback(async () => {
    if (selectedLoaction) {
      try {
        const result = await baseUrl(`/location/${selectedLoaction}`);
        if (result.status === 200) {
          dispatch({ type: 'SET_LOCATION_DETAILS', payload: result.data });
        }
      } catch (err) {
        //dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        //dispatch({ type: 'SHOW_ERROR', payload: 'could not get location details' });
      }
    }
  }, [selectedLoaction, dispatch]);

  function clearLocalStorageExcept(exceptions) {
    const tempStorage = {};
    exceptions.forEach((key) => {
      const value = localStorage.getItem(key);
      if (value !== null) {
        tempStorage[key] = value;
      }
    });

    localStorage.clear();

    Object.keys(tempStorage).forEach((key) => {
      localStorage.setItem(key, tempStorage[key]);
    });
  }

  const handleLogout = () => {
    dispatch({
      type: 'INIT_GLOBAL_STATE',
      payload: {
        ...globalState,
        selectedBusiness: '',
        selectedLoaction: '',
      },
    });
    clearLocalStorageExcept(['SELECTED_BUSINESS', 'SELECTED_LOCATION']);
    history.push('/');
  };

  const removeConversationsRouteByBusiness = (business, routes) => {
    const businessWithNoConversations = []; /*['Aldon Management']*/
    const removeConversations = business.some((el) =>
      businessWithNoConversations.includes(el.name),
    );
    const filteredRoutes = {
      ...routes,
    };
    if (removeConversations) {
      filteredRoutes.navListContainer = routes.navListContainer.filter(
        (navItem) => navItem.title !== 'Conversations',
      );
    }
    return filteredRoutes;
  };

  const fetchUserData = useCallback(() => {
    const time = new Date().getTime();
    baseUrl
      .get(`/user/${localStorage.getItem('USER_ID')}`)
      .then((res) => {
        dispatch({
          type: 'SET_PROFILE_PIC',
          payload: res.data.uploadedFile.length > 0 ? res.data.uploadedFile[0].url : null,
        });
        onBoardingDispatch({ type: 'SET_USER_NAME', payload: res.data.firstName });
        const isSuperAdmin = roleChecker(res.data.role);
        dispatch({ type: 'IS_PLAN_CHANGE_ACCESS', payload: isSuperAdmin });
        const isBusinessAdmin = businessAdminRoleChecker(res.data.role);
        dispatch({ type: 'IS_BUSINESS_ADMIN', payload: isBusinessAdmin });
        const updatedState = roleHandling(res.data.role, state);
        //Removing Conversations for some business until we are done fixing it
        const filteredRoutes = removeConversationsRouteByBusiness(
          res.data.business,
          updatedState.routes,
        );
        dispatch({ type: 'SET_ROLES', payload: updatedState.roles });
        dispatch({ type: 'SET_ROUTES', payload: filteredRoutes });
        if (res.data.accountType === 'trial' && res.data.trialExpiresAt < time && !isSuperAdmin) {
          dispatch({ type: 'IS_EXPIERED', payload: true });
        }
        localStorage.setItem('User', JSON.stringify(res.data));
      })
      .catch((err) => {
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        handleLogout();
      });

    baseUrl
      .get(`/business/list?id=${localStorage.getItem('USER_ID')}&deleted=false&page=0&offset=1000`)
      .then((res) => {
        if (res.data.list.length) {
          dispatch({ type: 'SET_BUSINESS_CONTAINER', payload: Array.from(res.data.list) });
        } else if (roles.isSuperAdmin) {
          onBoardingDispatch({ type: 'SHOW_POPUP' });
        }
      })
      .catch((err) => {
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      });
  }, [token, dispatch, onBoardingDispatch]);

  const fetchNotifications = useCallback(async () => {
    try {
      if (selectedLoaction) {
        dispatch({ type: 'SET_NOTIFICATION_LOADER', payload: true });
        const result = await baseUrl(
          `/getnotifications/${localStorage.getItem('USER_ID')}/${selectedLoaction}`,
        );
        if (result.status === 200) {
          dispatch({ type: 'SET_NOTIFICATION_CONTAINER', payload: result.data });
          dispatch({ type: 'SET_NOTIFICATION_LOADER', payload: false });
        }
      }
    } catch (err) {
      dispatch({ type: 'SET_NOTIFICATION_LOADER', payload: false });
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  }, [selectedLoaction, dispatch]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  useEffect(() => {
    setDisableSendInvite(true);
  }, [selectedBusiness]);

  useEffect(() => {
    if (selectedBusiness) {
      baseUrl
        .get(`/getuserlocation/${localStorage.getItem('USER_ID')}/${selectedBusiness}`)
        .then((res) => {
          if (!res.data.length) {
            if (roles.isSuperAdmin) {
              onBoardingDispatch({ type: 'SHOW_POPUP' });
            }
            setDisableSendInvite(false);
          }
          dispatch({ type: 'SET_LOCATIONS_CONTAINER', payload: Array.from(res.data) });
        });
      fetchBusinessDetails();
    }
  }, [
    selectedBusiness,
    dispatch,
    businesses.length,
    fetchBusinessDetails,
    onBoardingDispatch,
    userInfo,
  ]);

  //////////////////// END SET BUSINESS AND LOCATION ////////////////////

  useEffect(() => {
    fetchLocationDetails();
  }, [fetchLocationDetails]);

  const handleClose = () => {
    dispatch({
      type: 'HIDE_ERROR',
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!showError) {
        dispatch({ type: 'SET_ALERT_MESSAGE_TYPE', payload: 'error' });
      }
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [showError]);

  const handleSendInvite = () => {
    if (!selectedBusiness || !selectedLoaction) {
      if (roles.isSuperAdmin) {
        onBoardingDispatch({ type: 'SHOW_POPUP' });
      }
    } else {
      dispatch({
        type: 'SET_SELECTED_CONTACT_ID',
        payload: [],
      });
      dispatch({
        type: 'SET_SELECTED_GROUP_INFO',
        payload: {},
      });
      dispatch({ type: 'SET_BORDER_RADIUS_THANK_YOU_POPUP', payload: '0px' });
      newConversationPopup.handleShowModal();
    }
  };

  // Group view popup toggle method to hide and show the group view popup
  const handleGroupViewToggle = () => {
    setIsNewGroup(true);
    dispatch({ type: 'SET_GROUP_LOCATION', payload: selectedLoaction });
    newConversationPopup.handleHideShowModal();
  };

  const initIsNewGroup = useCallback(() => {
    setIsNewGroup(false);
    setIsEditGroup(false);
  }, []);

  useEffect(() => {
    if (selectedGroupId !== null && selectedGroupId >= 0) {
      fetchSelectedGroupClient(selectedGroupId);
      dispatch({ type: 'SET_GROUP_LOCATION', payload: selectedLoaction });
      newConversationPopup.handleHideShowModal();
    }
  }, [selectedGroupId]);

  useEffect(() => {
    if (groupContainer.listViewData.length && !isNewGroup) {
      setIsEditGroup(true);
    }
  }, [groupContainer.listViewData]);

  // Send invite Popup close handler
  const handleSendInviteClose = () => {
    dispatch({ type: 'SET_SELECTED_GROUP_ID', payload: null });
    onBoardingDispatch({ type: 'HIDE_INVITE_POPUP' });
    newConversationPopup.handleHideShowModal();
  };

  const handleSendCampaignFromGroup = () => {
    newConversationPopup.handleShowModal();
  };

  const onViewClickHandler = async () => {
    if (notificationContainer.length) {
      try {
        const thisNotification = notificationContainer[0];
        const result = await baseUrl.get(`/set-notification-seen/${thisNotification.id}`);
        if (result.status === 200) {
          fetchNotifications();
          history.push(thisNotification.notifications.url);
        }
      } catch (err) {
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      }
    }
  };

  const fetchUser = useCallback(() => {
    if (selectedBusiness) {
      baseUrl
        .get(`/business/${selectedBusiness}/user?isDeleted=false`)
        .then((res) => {
          onBoardingDispatch({
            type: 'SET_USER_LIST',
            payload: Array.from(ConvertIntoUserDropDown(res.data)),
          });
        })
        .catch((err) => {
          dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        });
    }
  }, [selectedBusiness, dispatch, onBoardingDispatch]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  useEffect(() => {
    if (showInvitePopup && isWizardMode) {
      handleSendInvite();
    }
  }, [showInvitePopup, isWizardMode]);

  // const handleThemeToggle = () => {
  //   dispatch({
  //     type: 'SET_THEME',
  //     payload:
  //       themeContainer.themeType === 'light'
  //         ? { ...darkTheme, themeType: 'dark' }
  //         : { ...lightTheme, themeType: 'light' },
  //   });
  // };
  const dummyStats = {
    message: 'send complete',
    attemptedTotal: 5,
    messagesSent: 2,
    messagesBlocked: 2,
    campaignName: 'NEW TEMPLATE FOR NORTH OREM',
    campaignType: 'review request',
    location: 1,
    results: [
      {
        id: 2271,
        campaign: 574,
        location: 1,
        name: 'Eric Bowen',
        phone: '+18019188065',
        lastSent: '2024-06-05T22:16:41.745Z',
        result: 'blocked',
      },
      {
        id: 136,
        campaign: 574,
        location: 1,
        name: 'Ryan Knowlton (official)',
        phone: '+18016696114',
        lastSent: '2024-06-05T21:09:20.598Z',
        result: 'blocked',
      },
      {
        id: 2530,
        campaign: 574,
        location: {
          id: 1,
          isDeleted: false,
          name: 'North Orem Location (1)',
          uuid: 'fff883b1-e6b6-409a-aa7a-86f865f43007',
          nickName: 'North Orem Location (Nickname)',
          acceptReviews: true,
          noEmployees: 3,
          contact: {
            addressLine1: '124 Westview Drive',
            addressLine2: '',
            suite: '',
            city: 'Orem',
            state: 'Utah',
            zip: '84058',
            timeZone: '1',
            webSite: 'https://rhinogfx.com',
            phoneNo: '+18016696114',
            mmsNumber: '3853803743',
            emailAddress: 'knowltster@gmail.com',
            facebook: 'https://www.facebook.com/ryanlknowlton/',
            twitter: 'https://twitter.com',
            instagram: 'https://instagram.com',
            linkedIn: 'https://www.linkedin.com/in/rknowlton/',
            youTube: 'https://youtube.com',
          },
          businessHours: [],
          description: '',
          services: '',
          keywords: '',
          mmsNumber: 0,
          aboutUs:
            "The purpose of lorem ipsum is to create a natural looking block of text (sentence, paragraph, page, etc.) that doesn't distract from the layout. A practice not without controversy, laying out pages with meaningless filler text can be very useful.",
          createdBy: 3,
          businessCard: {
            headerColor: '#d21d20',
            backgroundColor: '#212121',
            iconColor: '#ffffff',
            textColor: '#ffffff',
            isUserImageAvatar: true,
            isHeaderImage: true,
          },
          microsite: {
            allowWebchat: true,
            linkColor: '#1976d2',
          },
        },
        name: 'Charlie Chaplin',
        phone: '+18019930039',
        lastSent: null,
        result: 'sent',
      },
      {
        id: 2530,
        campaign: 574,
        location: {
          id: 1,
          isDeleted: false,
          name: 'North Orem Location (1)',
          uuid: 'fff883b1-e6b6-409a-aa7a-86f865f43007',
          nickName: 'North Orem Location (Nickname)',
          acceptReviews: true,
          noEmployees: 3,
          contact: {
            addressLine1: '124 Westview Drive',
            addressLine2: '',
            suite: '',
            city: 'Orem',
            state: 'Utah',
            zip: '84058',
            timeZone: '1',
            webSite: 'https://rhinogfx.com',
            phoneNo: '+18016696114',
            mmsNumber: '3853803743',
            emailAddress: 'knowltster@gmail.com',
            facebook: 'https://www.facebook.com/ryanlknowlton/',
            twitter: 'https://twitter.com',
            instagram: 'https://instagram.com',
            linkedIn: 'https://www.linkedin.com/in/rknowlton/',
            youTube: 'https://youtube.com',
          },
          businessHours: [],
          description: '',
          services: '',
          keywords: '',
          mmsNumber: 0,
          aboutUs:
            "The purpose of lorem ipsum is to create a natural looking block of text (sentence, paragraph, page, etc.) that doesn't distract from the layout. A practice not without controversy, laying out pages with meaningless filler text can be very useful.",
          createdBy: 3,
          businessCard: {
            headerColor: '#d21d20',
            backgroundColor: '#212121',
            iconColor: '#ffffff',
            textColor: '#ffffff',
            isUserImageAvatar: true,
            isHeaderImage: true,
          },
          microsite: {
            allowWebchat: true,
            linkColor: '#1976d2',
          },
        },
        name: 'Charlie Chaplin',
        phone: '+18019930039',
        lastSent: null,
        result: 'sent',
      },
    ],
  };

  const badgeContainer = notificationContainer.length ? (
    <ThemeProvider
      theme={{ color: themeContainer.badgeColor, bgColor: themeContainer.badgebgColor }}
    >
      <StyledBadge
        badgeContent={
          notificationLoader ? (
            <ThemeProvider theme={buttonLoaderTheme}>
              <ButtonLoader />
            </ThemeProvider>
          ) : (
            ``
          )
        }
        color="error"
        overlap="rectangular"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ThemeProvider theme={{ color: themeContainer.notificationIconColor }}>
          <NotificationIcon size={'20px'} color={'white'} />
        </ThemeProvider>
      </StyledBadge>
    </ThemeProvider>
  ) : (
    <ThemeProvider theme={{ color: themeContainer.notificationIconColor }}>
      <NotificationIcon size={'20px'} color={'white'} />
    </ThemeProvider>
  );

  // const themeToggleContent =
  //   themeContainer.themeType === 'light' ? (
  //     <ThemeProvider theme={{ color: iconsTheme.wbSunnyIcon }}>
  //       <StyledWbSunnyIcon />
  //     </ThemeProvider>
  //   ) : (
  //     <ThemeProvider theme={{ color: iconsTheme.nightsStayIcon }}>
  //       <StyledNightsStayIcon />
  //     </ThemeProvider>
  //   );

  const showFallbackLoader = groupContainer.loader ? <Loader /> : null;

  return (
    <AppContainer>
      {showFallbackLoader}
      <CampaignStore>
        <SurveyStore>
          <PermissionStore>
            <DashboardStore>
              <ReportsStore>
                <ThemeProvider theme={{ bgColor: themeContainer.pageBackgroundColor }}>
                  <TopNavBar>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      zIndex="6"
                      alignItems="center"
                      flex="1"
                    >
                      <NavLink to={'/app/reports' /*'/app/dashboard'*/}>
                        <img
                          src={logo}
                          style={{ marginRight: '10px' }}
                          width={'24px'}
                          height={'24px'}
                        />
                      </NavLink>
                      <CustomHeaderNavBar navList={navListContainer} />

                      <StyledSendButton
                        onClick={sendInvitePopover.handleClick} //{handleSendInvite}
                        disabled={disableSendInvite}
                      >
                        <Box style={{ marginRight: 5 }} display="flex" alignItems="center">
                          <SendInviteIcon color={'#000'} size={24} />
                        </Box>
                        <Divider
                          orientation="vertical"
                          flexItem
                          thickness={10}
                          style={{ marginRight: '2px' }}
                        />
                        <ChevronDownIcon color={'#000'} size={'20px'} />
                      </StyledSendButton>

                      {roles.isEmployee ? (
                        ''
                      ) : (
                        <NavLink to={'/app/setup'}>
                          <StyledIconButton
                            color="primary"
                            style={{
                              backgroundColor: window.location.href.includes('setup')
                                ? '#30feaf'
                                : '',
                              marginLeft: '1rem',
                            }}
                          >
                            <Settings
                              color={window.location.href.includes('setup') ? '#000' : '#fff'}
                              size={'20px'}
                            />
                          </StyledIconButton>
                        </NavLink>
                      )}
                      <ThemeProvider
                        theme={{
                          border: themeContainer.avatarBorder,
                          bgColor: themeContainer.avatarBgColor,
                        }}
                      >
                        <StyledAvatar
                          alt={userName}
                          src={`${process.env.REACT_APP_IMAGE_ENDPOINT}${profilePic}`}
                          onClick={userPopover.handleClick}
                        />
                      </ThemeProvider>
                    </Box>
                  </TopNavBar>

                  <MainView>
                    <CusotmAlert
                      messageType={alertMessageType}
                      message={errorMessage}
                      handleClose={handleClose}
                      open={showError}
                    />
                    <OnBoardingContainer />
                    {routeRedirectContainer.map((item) => (
                      <Route
                        key={item.path}
                        exact={item.exact}
                        path={item.path}
                        render={() => <Redirect to={item.redirectTo} />}
                      />
                    ))}
                    <Suspense fallback={<Loader />}>
                      <Switch>
                        {routesContainer.map((item, index) => (
                          <Route key={index} exact path={item.path} component={item.component} />
                        ))}
                      </Switch>
                    </Suspense>
                  </MainView>
                </ThemeProvider>
              </ReportsStore>
            </DashboardStore>
          </PermissionStore>
        </SurveyStore>
        <MUIThemeProvider theme={popUpTheme}>
          <PrivacyLinkPopup
            handleClose={privacyPopup.handleHideShowModal}
            show={privacyPopup.showModal}
          />
          <TermsOfUsePopup
            show={termsOfUsePopup.showModal}
            handleClose={termsOfUsePopup.handleHideShowModal}
          />
        </MUIThemeProvider>
        <MUIThemeProvider theme={popUpTheme}>
          <GenricPopup
            open={editEmployeePopup.showModal}
            handleClose={editEmployeePopup.handleHideShowModal}
          >
            <EditUserPopup
              handleClose={editEmployeePopup.handleHideShowModal}
              show={editEmployeePopup.showModal}
            />
          </GenricPopup>
        </MUIThemeProvider>

        <MUIThemeProvider theme={popUpTheme}>
          <NotificationPreferencesPopover
            open={notificationPreferences.showModal}
            handleClose={notificationPreferences.handleHideShowModal}
            anchorEl={notificationPopover.anchorEl}
          ></NotificationPreferencesPopover>
        </MUIThemeProvider>

        <MUIThemeProvider theme={popUpTheme}>
          <GenricPopup open={newConversationPopup.showModal} handleClose={handleSendInviteClose}>
            <SendInviteContainer
              newConversationPopup={newConversationPopup}
              handleGroupViewToggle={handleGroupViewToggle}
            />
          </GenricPopup>
        </MUIThemeProvider>

        <Snackbar
          open={
            (notificationContainer.length > 0 &&
              notificationContainer[0]?.notifications?.linkTitle) ||
            (false && notificationContainer[0]?.notifications.description) ||
            false
          }
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            severity="error"
            sx={{ width: '100%' }}
            action={
              <Button onClick={onViewClickHandler} style={{ color: 'white' }}>
                {(notificationContainer.length &&
                  notificationContainer[0]?.notifications?.linkTitle) ||
                  ''}
              </Button>
            }
          >
            {(notificationContainer.length &&
              notificationContainer[0]?.notifications?.description) ||
              ''}
          </Alert>
        </Snackbar>

        <GenricPopup
          open={showSendStatus}
          //open={true}
          handleClose={() => setShowSendStatus(false)}
          fullWidth
          maxWidth="sm"
        >
          <StatusMessage
            Icon={
              <ThemeProvider theme={{ color: iconsTheme.checkCircleIcon }}>
                <StyledCheckCircle />
              </ThemeProvider>
            }
            message={campaignSendUpdate}
            actionBtnText="Close"
            redirectLinkText="Go to Feedback"
            handleClose={() => setShowSendStatus(false)}
          />
        </GenricPopup>

        <MUIThemeProvider theme={popOverTheme}>
          <UserPopover
            handleClose={userPopover.handleClose}
            open={userPopover.open}
            id={userPopover.id}
            handleLogout={handleLogout}
            handleEditUser={() => {}}
            profilePicture={profilePic}
            handleShowEditUserModal={editEmployeePopup.handleShowModal}
            handleShowNotificationPreferencesModal={notificationPreferences.handleShowModal}
          />
        </MUIThemeProvider>
        <MUIThemeProvider theme={popOverTheme}>
          <SendInvitePopover
            handleSendInvite={handleSendInvite}
            anchorEl={sendInvitePopover.anchorEl}
            handleClose={sendInvitePopover.handleClose}
            open={sendInvitePopover.open}
            id={sendInvitePopover.id}
            notificationPopover={notificationPopover}
          />
        </MUIThemeProvider>
        <GroupContainer
          isNewGroup={isNewGroup}
          initIsNewGroup={initIsNewGroup}
          isEditGroup={isEditGroup}
          handleSendCampaignFromGroup={handleSendCampaignFromGroup}
        />
      </CampaignStore>
    </AppContainer>
  );
}

export default React.memo(App);
